import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ItemEntity } from '@generated/gql';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ethers } from 'ethers';
import { finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-transfer-dialog',
  standalone: true,
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [DialogTitleComponent, ReactiveFormsModule, ButtonClickDirective, TranslateModule],
})
export class TransferDialogComponent {
  // common
  chainId: number;
  account: string;
  loaded = false;
  processing = false;

  addressControl: FormControl<string | null> = new FormControl<string>('');

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      item: ItemEntity;
    },
    private dialogRef: DialogRef<null, TransferDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private tokenService: TokenService,
  ) {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
      },
      chainId => {
        this.chainId = chainId;
      },
    );
  }

  close(): void {
    this.dialogRef.close(null);
  }

  isValidAddress(address: string) {
    return ethers.isAddress(address);
  }

  onTransfer() {
    this.processing = true;
    this.changeDetectorRef.detectChanges();

    const recipient = this.addressControl.value ?? '';
    if (recipient !== '') {
      this.tokenService
        .transferNft$(this.account, this.data.item.meta.id, this.data.item.itemId, recipient)
        .pipe(
          finalize(() => {
            this.processing = false;
            this.changeDetectorRef.detectChanges();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(() => {
          this.close();
        });
    }
  }
}
