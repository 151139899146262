export const HERO_ADR = 'heroAdr';
export const HERO_ID = 'heroId';
export const GUILD_ID = 'guildId';
export const DUNGEON_ADR = 'dungeonAdr';
export const CHAMBER_ADR = 'chamberAdr';
export const STAGE = 'stage';
export const ITERATION = 'iteration';
export const SHELTER_ID = 'shelterId';

export const MAIN_ROUTES = {
  EMPTY: '',
  MAIN: '/',
  AUTH: 'auth',
  WALLET: 'wallet',
  DELEGATE: 'delegate',
  WEB3AUTH_SETTINGS: 'web3auth-settings',
  HERO: 'hero',
  HERO_ID: `hero/:${HERO_ADR}/:${HERO_ID}`,
  STAT: 'stats',
  REINFORCEMENT: 'reinforcement',
  HEROES_LIST: 'heroes-list',
  F2P_END: 'f2pend',
  DUNGEON: 'dungeon',
  DUNGEON_TOKEN_ID: `dungeon/:${HERO_ADR}/:${HERO_ID}`,
  DUNGEON_DETAILS: 'details',
  DUNGEON_DETAILS_ADR: `details/:${DUNGEON_ADR}`,
  DUNGEON_FIGHT: 'fight',
  DUNGEON_FIGHT_ADR: `fight/:${DUNGEON_ADR}/:${CHAMBER_ADR}/:${STAGE}/:${ITERATION}`,
  DUNGEON_EVENT: 'event',
  DUNGEON_EVENT_ADR: `event/:${DUNGEON_ADR}/:${CHAMBER_ADR}/:${STAGE}/:${ITERATION}`,
  DUNGEON_STORY: 'story',
  DUNGEON_STORY_ADR: `story/:${DUNGEON_ADR}/:${CHAMBER_ADR}/:${STAGE}/:${ITERATION}`,
  DUNGEON_END: 'dungeon-end',
  DUNGEON_END_ADR: `dungeon-end/:${DUNGEON_ADR}`,
  FAQ: 'faq',
  INVENTORY: 'inventory',
  MAP: 'map',
  MAP_TOKEN_ID: `map/:${HERO_ADR}/:${HERO_ID}`,
  CREATE: 'create',
  CREATE_SUCCESS: 'create-success',
  CREATE_SUCCESS_TOKEN_ID: `create-success/:${HERO_ADR}/:${HERO_ID}`,
  DEV: 'dev',
  PAWNSHOP: 'pawnshop',
  FORGE: 'forge',
  TOP_LIST: 'top-list',
  REF_TOP_LIST: 'ref-top-list',
  REF_TOP_LIST_ACCOUNT: 'ref-top-list-account',
  ITEM: 'item',
  MAINTENANCE: 'maintenance',
  HERO_DEAD: 'hero-dead',
  DAILY_QUESTS: 'daily-quests',
  TRANSFORM: 'transform',
  TRANSFORM_ID: `transform/:${HERO_ADR}/:${HERO_ID}`,
  GUILDS: 'guilds',
  GUILDS_ID: `:${GUILD_ID}`,
  GUILDS_ID_BANK: `:${GUILD_ID}/bank`,
  SHELTER: 'shelter',
  SHELTER_VIEW: 'shelter-view',
  SHELTER_ID: `:${SHELTER_ID}`,
  SHELTER_AUCTION: 'auction',
  SHELTER_PVP_SHOP: 'pvp-shop',
};
