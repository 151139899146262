import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { DestroyService } from '@services/destroy.service';
import { UserControllerService } from '@services/onchain/user-controller.service';
import { ADDRESS_ZERO } from '@shared/constants/addresses/addresses.constant';
import { map, Observable, of, takeUntil } from 'rxjs';

import { HERO_NAME_INVALID_ERROR_NAME } from './account-name-validator.constants';

@Injectable()
export class AccountNameValidatorService implements AsyncValidator {
  chainId: number;

  constructor(
    private userControllerService: UserControllerService,
    private destroy$: DestroyService,
  ) {}

  validate(control: AbstractControl<string>): Observable<ValidationErrors | null> {
    return (
      control.pristine ? of(ADDRESS_ZERO) : this.userControllerService.nameToUserAccount$(this.chainId, control.value)
    ).pipe(
      map(address => {
        return address !== ADDRESS_ZERO
          ? { [HERO_NAME_INVALID_ERROR_NAME]: { anErrorReason: `Account name exist` } }
          : null;
      }),
      takeUntil(this.destroy$),
    );
  }
}
