import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, ActivationEnd, NavigationEnd, ParamMap, Router, RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment';
import { reloadPage } from '@helpers/common-helper';
import { getMainDomain } from '@helpers/data-helper';
import { AchievementModel } from '@models/achievement.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { HeaderStateModel } from '@services/app-state-service/models/header-state.model';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { STORAGE_CUSTOM_SUBGRAPH_URL, StorageService } from '@services/storage.service';
import { UtmService } from '@services/utm.service';
import { Web3ModalService } from '@services/web3-modal.service';
import { Web3Service } from '@services/web3.service';
import { Web3authService } from '@services/web3auth.service';
import { isMobile } from '@services/web3modal-ts/src/helpers/utils';
import { AchievementsActions } from '@shared/actions/achievement.actions';
import { ErrorActions } from '@shared/actions/error.actions';
import { TutorialActions } from '@shared/actions/tutorial.actions';
import { AchievementComponent } from '@shared/components/achievement/achievement.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ROUTE_WITH_EXCLUDED_HEADER } from '@shared/components/header/constants/header.constant';
import { HeaderComponent } from '@shared/components/header/header.component';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  ModalTutorialComponent,
  STORAGE_SKIP_TUTORIAL,
} from '@shared/components/modal-tutorial/modal-tutorial.component';
import { NetworkDialogComponent } from '@shared/components/network-dialog/network-dialog.component';
import {
  ADDRESS_ZERO,
  checkAllDungeonImages,
  checkAllMonsterImages,
  checkAllShrineImages,
  checkAllStoryImages,
} from '@shared/constants/addresses/addresses.constant';
import { enterAnimation } from '@shared/constants/animation/enter.animation';
import { CHAIN_FIELDS, CHAIN_IDS, getChainByChainId } from '@shared/constants/chain-ids.constant';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { STORAGE_KEY_TUTORIAL } from '@shared/constants/tutorial.constant';
import { checkClickableNode, isAndroid, isFirefox, isMetamask } from '@shared/utils';
import { formatDistance } from 'date-fns';
import { ethers, JsonRpcProvider, JsonRpcSigner } from 'ethers';
import { ClickOutsideModule } from 'ng-click-outside';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { INGXLoggerMetadata, INGXLoggerMonitor, NGXLogger } from 'ngx-logger';
import { filter, forkJoin, from, fromEvent, takeUntil, tap, distinctUntilChanged, map, mergeMap } from 'rxjs';

const AVAILABLE_DIFFERENT_TIME = 120; // in seconds
const DEFAULT_WIDTH = 1080;
const DEFAULT_HEIGHT = 1600;
const STORAGE_KEY_DISCLAIMER = 'TETU_GAME_DISCLAIMER';
const STORAGE_KEY_PLAY_MUSIC = 'TETU_GAME_PLAY_MUSIC';
export const SACRA_REF_CODE = 'SACRA_REF_CODE';

const FORBIDDEN_DOMAIN = 'sacra.cc';
const ALLOW_DOMAIN = 'sacra.game';

class LoggerMonitor implements INGXLoggerMonitor {
  logs: INGXLoggerMetadata[];

  constructor(logs: INGXLoggerMetadata[]) {
    this.logs = logs;
  }

  clearLogs(logs) {
    this.logs = logs;
  }

  onLog(log: INGXLoggerMetadata) {
    if (log.additional?.length) {
      // log.additional = log.additional.map(it => {
      //   if (typeof it === 'object') {
      //     try {
      //       return JSON.stringify(it);
      //     } catch (e) {}
      //   }
      //
      //   return it;
      // });
    }

    this.logs.push(log);
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterAnimation],
  host: {
    class: 'g-flex-column g-flex--align-center',
  },
  imports: [
    CheckboxComponent,
    ReactiveFormsModule,
    ModalTutorialComponent,
    TranslateModule,
    LoadingComponent,
    LoadingSmallComponent,
    RouterOutlet,
    AchievementComponent,
    HeaderComponent,
    ClickOutsideModule,
    ModalComponent,
  ],
})
export class AppComponent implements OnInit {
  account?: string;
  chainId?: number;
  providerInited = false;
  heroToken = '';
  heroTokenId = 0;
  MAIN_ROUTES = MAIN_ROUTES;
  appZoom = 1;
  headerState: HeaderStateModel | undefined;
  menuState = { isOpen: false };
  isMobile = isMobile();
  subgraphDelay = 0;
  subgraphDelayFormatted = '';
  maxSubgraphDelay = 600; // max delay in seconds for showing app error
  indexingError = false;
  timeError = false;
  differentErrorTime = 0;
  isShowDisclaimer = true;
  isLoading = false;
  isSubgraphClosed = true;
  isPlayedMusicClosed = true;
  CHECKBOX_STATE = CHECKBOX_STATE;
  STORAGE_KEY_PLAY_MUSIC = STORAGE_KEY_PLAY_MUSIC;
  playedMusicName = '';
  isShowLogger = false;
  logs: INGXLoggerMetadata[] = [];
  loggerMonitor = new LoggerMonitor(this.logs);
  windowWidth = 0;
  isProductionBuild = environment.STAGING === 'false';
  isFirefox = isFirefox;
  isNoHeader = true;
  isShowTutorial = false;
  isShowHeader = true;
  providerForRead: JsonRpcProvider | JsonRpcSigner = new ethers.JsonRpcProvider(
    environment['RPC_PROVIDER_URL'],
    getChainByChainId(Number(environment['CHAIN_ID']))[CHAIN_FIELDS.ID],
  );

  subgraphUrlValueControl = new FormControl('');
  subgraphUrlControl = new FormControl(CHECKBOX_STATE.NONE);

  isWrongDomain = false;

  achievement: AchievementModel;
  isShowAchievement = false;

  private achievementTimeout = 0;
  private timeToHideAchievement = 5000;

  protected readonly window = window;
  protected readonly ALLOW_DOMAIN = ALLOW_DOMAIN;

  private subgraphTimeout: number = 0;
  private playedMusicTimeout: number = 0;
  private isPlayBgSound = false;
  private isFirstLoad = true;

  @HostListener('window:focus')
  async onFocusApp() {
    setTimeout(async () => {
      if (!document.hidden) {
        await this.soundService.resumeAll();
      }
    }, 1000);
  }

  @HostListener('window:blur')
  async onBlurApp() {
    setTimeout(async () => {
      // we should not stop music on metamask signing
      // let's assume if transaction is started but did not sign music should not stop
      if (!this.providerService.txStartToSign) {
        await this.soundService.stopAll(true);
      }
    }, 1000);
  }

  @HostListener('window:resize')
  onResize() {
    this.updateZoom();
  }

  @HostListener('click')
  async onClick() {
    if (!this.isPlayBgSound) {
      this.isPlayBgSound = true;
      setTimeout(async () => {
        await this.soundService.playMainBgSound();
      }, 1000);
    }
  }

  @HostBinding('class.is-metamask')
  get isMetamask() {
    return (isMetamask || isMobile()) && !isAndroid;
  }

  constructor(
    public storageService: StorageService,
    private appStateService: AppStateService,
    private router: Router,
    private destroy$: DestroyService,
    private providerService: ProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private dialog: Dialog,
    private subgraphService: SubgraphService,
    private mediator: Mediator,
    private translateService: TranslateService,
    private soundService: SoundService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private utmService: UtmService,
    private activatedRoute: ActivatedRoute,
    private web3authService: Web3authService,
    private web3ModalService: Web3ModalService,
    private relayService: RelayService,
    private web3: Web3Service,
  ) {
    this.isMobile = isMobile();

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    this.logger.registerMonitor(this.loggerMonitor);

    fromEvent(document, 'click')
      .pipe(
        filter(
          event => event instanceof MouseEvent && !!event.target && checkClickableNode(event.target as HTMLElement),
        ),
      )
      .subscribe(() => this.soundService.play({ key: 'click' }));

    fromEvent(document, 'keydown')
      .pipe(
        filter(
          event =>
            event instanceof KeyboardEvent && !!event.target && (event.target as HTMLElement).nodeName === 'INPUT',
        ),
      )
      .subscribe(() => {
        this.soundService.play({ key: 'keyboard' });
      });
  }

  ngOnInit() {
    this.isWrongDomain = window.location.href.toLowerCase().indexOf(FORBIDDEN_DOMAIN) > 0;

    if (environment['MAINTENANCE'] === 'true') {
      this.router.navigate([MAIN_ROUTES.MAINTENANCE]);
    }

    this.providerForRead = this.providerService.getProviderForRead();

    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      (_account: string) => {
        this.logger.trace('New account', _account);
        // connecting metamask to website for some reason returns 0, need to reload the page
        if (this.account) {
          this.resetOnAccOrNetChange();
        }
        if (_account === '0') {
          this.onNewMetamaskConnect();
        }
        this.account = _account;
        this.initProviderState();
      },
      (_chainId: number) => {
        this.logger.trace('New chainId', _chainId);
        if (this.chainId) {
          this.resetOnAccOrNetChange();
        }

        this.chainId = _chainId;

        this.initProviderState();

        const expectedChain = getChainByChainId(Number(environment.CHAIN_ID ?? '-1'));
        if (this.chainId !== expectedChain.id) {
          this.dialog.open(NetworkDialogComponent, {
            panelClass: 'app-overlay-pane',
            data: { canClose: false },
          });
        }
      },
    );

    this.activatedRoute.queryParamMap
      .pipe(
        map((paramMap: ParamMap) => {
          return paramMap.get('refCode');
        }),
        distinctUntilChanged(),
      )
      .subscribe(refCode => {
        if (!!refCode && refCode !== '') {
          this.storageService.set(SACRA_REF_CODE, refCode);
        }
      });

    const utm = this.activatedRoute.snapshot.paramMap.get('utm_id') ?? null;
    this.utmService.setUTM(utm);

    // this.windowWidth = window.innerWidth;
    this.windowWidth = window.innerHeight;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.googleAnalyticsService.gtag('config', environment['GOOGLE_ANALYTICS_ID'] || 'G-2QHBHH94PX', {
        page_path: (event as NavigationEnd).urlAfterRedirects,
        utm_id: this.utmService.getUTM(),
      });

      this.tryInitWeb3Providers((event as NavigationEnd).urlAfterRedirects);
      this.checkVersion();

      const url = (event as NavigationEnd).url;

      const cleanUrl = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url;

      this.isShowHeader = !ROUTE_WITH_EXCLUDED_HEADER.some(
        it => cleanUrl.includes(`/${it}/`) || cleanUrl.includes(`/${it}`),
      );

      setTimeout(() => {
        const cleanUrl = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url;

        this.isNoHeader = cleanUrl === '/' || cleanUrl.indexOf('index.html') >= 0;

        this.changeDetectorRef.detectChanges();
      });
    });

    this.mediator.ofAction(AchievementsActions.Show, this.destroy$).subscribe(({ achievement }) => {
      this.achievement = achievement;
      this.isShowAchievement = true;

      clearTimeout(this.achievementTimeout);

      this.achievementTimeout = window.setTimeout(() => {
        this.isShowAchievement = false;

        clearTimeout(this.achievementTimeout);
        this.changeDetectorRef.detectChanges();
      }, this.timeToHideAchievement);

      this.changeDetectorRef.detectChanges();
    });

    this.mediator.ofAction(TutorialActions.ToggleTutorial, this.destroy$).subscribe(({ isShow, isForceShow, key }) => {
      let isSkipTutorial = false;

      if (this.storageService.get(STORAGE_SKIP_TUTORIAL)) {
        isSkipTutorial = JSON.parse(this.storageService.get(STORAGE_SKIP_TUTORIAL)) === CHECKBOX_STATE.CHECKED;
      }

      this.isShowTutorial = isShow && !isSkipTutorial;

      if (this.storageService.get(STORAGE_KEY_TUTORIAL)) {
        const passedTutorials = JSON.parse(this.storageService.get(STORAGE_KEY_TUTORIAL));
        const route = key || '';

        if (passedTutorials[route]) {
          this.isShowTutorial = false;
        }
      }

      if (isForceShow) {
        this.isShowTutorial = true;
      }

      this.changeDetectorRef.detectChanges();
    });

    this.mediator
      .ofAction(ErrorActions.Toggle, this.destroy$)
      .pipe(filter(isShow => !!isShow))
      .subscribe(({ message, header }) => {
        this.dialog.open(InfoDialogComponent, {
          panelClass: 'app-overlay-pane',
          data: {
            infoTitle: `${header ? header : 'Error'} - v${window['sacraGameVersion']}`,
            infoDesc: message,
            descTitle: 'Attention',
            descText:
              'In case of any problems, screenshot your console log, and open a ticket on our Discord server. Don’t trust anyone who tries to help you outside of the ticket!',
          },
        });

        this.soundService.play({ key: 'error' });
      });

    if (this.storageService.get(STORAGE_KEY_DISCLAIMER)) {
      const isShowDisclaimer = JSON.parse(this.storageService.get(STORAGE_KEY_DISCLAIMER));

      this.isShowDisclaimer = isShowDisclaimer !== CHECKBOX_STATE.CHECKED;
    }

    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        tap(event => {
          const { params } = (event as ActivationEnd).snapshot;
          this.heroToken = params['heroAdr'] ?? '';
          this.heroTokenId = parseInt(params['heroId'] ?? '0');
        }),
        // switchMap(() => this.subgraphService.hero$(this.heroToken, this.heroTokenId)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // this.hero = hero as HeroType;
        this.changeDetectorRef.markForCheck();
      });

    this.updateZoom();

    this.dialog.afterOpened.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateOverlayZoom();
    });

    if (this.storageService.get(STORAGE_CUSTOM_SUBGRAPH_URL)) {
      this.subgraphUrlValueControl.setValue(this.storageService.get(STORAGE_CUSTOM_SUBGRAPH_URL));
      this.subgraphUrlControl.setValue(CHECKBOX_STATE.CHECKED);
    } else {
      this.subgraphUrlValueControl.setValue(environment.SUBGRAPH_URI);
      this.subgraphUrlValueControl.disable();
    }

    this.subgraphUrlControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.saveSubgraphUrl());

    this.appStateService
      .getHeaderStateObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.headerState = state;
        this.changeDetectorRef.detectChanges();
      });

    this.updateSubgraphData();
    this.checkTimestamp();

    // -------- CHECKS --------

    checkAllDungeonImages();
    checkAllMonsterImages();
    checkAllStoryImages();
    checkAllShrineImages();
    // checkMonsterSounds();

    // for(const id of Array.from(MONSTER_SYMBOLS.keys()).sort()) {
    //   console.log(MONSTER_SYMBOLS.get(id), id);
    // }
  }

  /////////////////// CRYPTO LOGIC ///////////////////

  tryInitWeb3Providers(urlAfterRedirects: string) {
    const routerUrl = this.getRoutUrl();
    if (this.isFirstLoad && routerUrl !== '' && routerUrl !== '/') {
      this.isFirstLoad = false;

      let url = new URL(window.location.href);
      url.searchParams.delete('modal');
      url.searchParams.delete('inventory-tab');
      history.replaceState(history.state, '', url.href);
      this.initWeb3Providers(urlAfterRedirects);
    }
  }

  initWeb3Providers(urlAfterRedirects: string) {
    this.logger.trace('start init web3 providers', urlAfterRedirects);
    this.web3authService.init(() => {
      if (this.web3authService.getProvider()) {
        this.logger.trace('Connected to already inited OAuth provider');
        this.web3ModalService.provider$.next(this.web3authService.getProvider());
      } else {
        this.connectMetaMask();

        this.web3ModalService.providers.pipe(takeUntil(this.destroy$)).subscribe(p => {
          this.logger.trace('providers inited on app component', p);

          const routerUrl = this.getRoutUrl(urlAfterRedirects);
          console.log('initWeb3Providers => router url', routerUrl);

          // need to check providers with some delay because it can be not inited yet
          setTimeout(async () => {
            if (
              this.web3ModalService.cachedProvider === '' &&
              !this.web3authService.getUserInfo() &&
              routerUrl !== '/' + MAIN_ROUTES.AUTH &&
              routerUrl !== '/' + MAIN_ROUTES.AUTH + '/' + MAIN_ROUTES.WALLET
            ) {
              this.router.navigate([MAIN_ROUTES.MAIN]);
              // this.dialog.open(InfoDialogComponent, {
              //   panelClass: 'app-overlay-pane',
              //   data: {
              //     infoTitle: `Error - v${window['sacraGameVersion']}`,
              //     infoDesc:
              //       'Web3 providers not found! ' +
              //       'We did not find any web3 provider and can not load the game without it. Please try to reload the page.',
              //     descTitle: '',
              //     descText: '',
              //   },
              // });
            }
          }, 2000);
        });
      }
    });
  }

  connectMetaMask() {
    this.web3
      .connect$()
      .pipe(
        mergeMap(web3 => {
          return web3.eth.getAccounts();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([account]) => {
          this.logger.trace('Account connected', account);

          if (!account || account === '' || account === '0') {
            // it happenes when metamask connected website in the first time
            // need to reload the whole page
            this.onNewMetamaskConnect();
          }
        },
        error: error => {
          this.logger.error('Connect metamask error', error);
        },
      });
  }

  initProviderState() {
    if (this.account && this.chainId) {
      this.logger.trace('provider inited on app component!');

      this.providerInited = true;
      // update last fees data
      this.providerService.getActualFees().subscribe();

      const routerUrl = this.getRoutUrl();

      if (this.web3authService.getProvider()) {
        if (
          routerUrl !== '/' &&
          routerUrl !== '/' + MAIN_ROUTES.CREATE &&
          routerUrl !== '/' + MAIN_ROUTES.TOP_LIST &&
          routerUrl !== '/' + MAIN_ROUTES.AUTH &&
          routerUrl !== '/' + MAIN_ROUTES.AUTH + '/' + MAIN_ROUTES.WALLET &&
          routerUrl !== '/' + MAIN_ROUTES.WEB3AUTH_SETTINGS
        ) {
          this.providerService.showSpeedUpDialog();
        }
      } else {
        if (
          !this.providerService.delegateDialogOpened &&
          routerUrl !== '/' &&
          routerUrl !== '/' + MAIN_ROUTES.CREATE &&
          routerUrl !== '/' + MAIN_ROUTES.TOP_LIST &&
          routerUrl !== '/' + MAIN_ROUTES.AUTH &&
          routerUrl !== '/' + MAIN_ROUTES.AUTH + '/' + MAIN_ROUTES.WALLET &&
          routerUrl !== '/' + MAIN_ROUTES.DELEGATE
        ) {
          this.relayService
            .userInfo$(this.chainId ?? -1, this.account ?? '')
            .pipe(takeUntil(this.destroy$))
            .subscribe(userInfo => {
              if (userInfo.delegator !== ADDRESS_ZERO) {
                this.providerService.showDelegateDialog();
              }
            });
        }
      }
    }
  }

  isLowActivityNetwork() {
    // real has low network activity and sometimes blocks produces with huge delays
    return Number(environment['CHAIN_ID']) === CHAIN_IDS.REAL;
  }

  checkTimestamp() {
    if (this.isLowActivityNetwork()) {
      return;
    }

    from(this.providerForRead.provider.getBlock('latest'))
      .pipe(takeUntil(this.destroy$))
      .subscribe(block => {
        this.logger.trace('latest block', block);
        if (block) {
          const timestamp = new Date().getTime();
          const differentTime = Math.abs(Math.floor(timestamp / 1000) - block.timestamp);
          console.log('TIME DIFF', differentTime);
          if (differentTime > AVAILABLE_DIFFERENT_TIME) {
            this.timeError = true;
            this.differentErrorTime = Math.floor(differentTime / 60);
          }
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  updateSubgraphData() {
    forkJoin([this.subgraphService.graphData$(), this.providerForRead.provider.getBlockNumber()]).subscribe(
      ([data, block]) => {
        this.logger.trace('Subgraph data', block, data);
        from(this.providerForRead.provider.getBlock(block)).subscribe(curBlock => {
          this.logger.trace('cur block', curBlock);

          // todo
          // this.subgraphDelay = Math.floor((curBlock?.timestamp ?? Date.now() / 1000) - (data?.block?.timestamp ?? 0));
          this.subgraphDelayFormatted = formatDistance(0, this.subgraphDelay * 1000, { includeSeconds: true });

          this.changeDetectorRef.markForCheck();
        });

        this.indexingError = data?.hasIndexingErrors ?? true;

        this.changeDetectorRef.detectChanges();
      },
    );
  }

  /////////////////// NON CRYPTO LOGIC ///////////////////

  closeMenu() {
    this.menuState.isOpen = false;
  }

  closeErrorModal() {}

  onLearnMore() {
    window.open('https://info.' + getMainDomain(), '_blank');
  }

  onHideDisclaimer() {
    this.isShowDisclaimer = false;

    this.storageService.set(STORAGE_KEY_DISCLAIMER, JSON.stringify(CHECKBOX_STATE.CHECKED));
  }

  onOpenSubgraph() {
    this.isSubgraphClosed = !this.isSubgraphClosed;
    this.isPlayedMusicClosed = true;

    this.changeDetectorRef.detectChanges();

    clearInterval(this.subgraphTimeout);

    this.subgraphTimeout = window.setTimeout(() => {
      this.isSubgraphClosed = true;

      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
    }, 2000);
  }

  onOpenPlayedMusic() {
    if (this.soundService.getPlayed()) {
      this.playedMusicName = this.soundService.getPlayed().name || '';
    }

    this.isPlayedMusicClosed = !this.isPlayedMusicClosed;
    this.isSubgraphClosed = true;

    clearInterval(this.playedMusicTimeout);

    this.playedMusicTimeout = window.setTimeout(() => {
      this.isPlayedMusicClosed = true;

      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
    }, 2000);
  }

  clearLogs() {
    this.logs = [];

    this.loggerMonitor.clearLogs(this.logs);
  }

  private updateOverlayZoom() {
    const overlay = document.querySelector('.cdk-overlay-container') as HTMLElement;

    if (overlay) {
      // @ts-ignore
      overlay.style.zoom = this.appZoom;
      overlay.style['-webkit-text-size-adjust'] = 'auto';
    }
  }

  private updateZoom() {
    let zoom = 1;
    if (window.innerHeight > window.innerWidth) {
      this.windowWidth = window.innerWidth;
      zoom = window.innerWidth / DEFAULT_WIDTH;
    } else {
      this.windowWidth = window.innerHeight;
      zoom = window.innerHeight / DEFAULT_HEIGHT;
    }

    this.appZoom = zoom > 1 ? 1 : zoom;

    this.appStateService.setZoom(this.appZoom);

    this.updateOverlayZoom();
  }

  private async checkVersion() {
    const response = await fetch(`/version.json?v=${Date.now()}`);
    const versionJson = await response.json();
    const { version } = versionJson;

    window['sacraGameVersion'] = version;

    if (this.storageService.get('TETU_GAME_VERSION')) {
      const lsVersion = this.storageService.get('TETU_GAME_VERSION');

      this.storageService.set('TETU_GAME_VERSION', version);

      if (Number(version) !== Number(lsVersion)) {
        reloadPage();
      }
    } else {
      this.storageService.set('TETU_GAME_VERSION', version);
      reloadPage();
    }
  }

  private resetOnAccOrNetChange() {
    reloadPage();
  }

  private onNewMetamaskConnect() {
    // todo need to find a way to reinit mm account without reloading
    reloadPage(`/${MAIN_ROUTES.AUTH}/${MAIN_ROUTES.WALLET}`);
  }

  getRoutUrl(url?: string) {
    const routerUrl = (url ? url : window.location.href).replace(window.location.origin, '');
    return routerUrl.indexOf('?') >= 0 ? routerUrl.substring(0, routerUrl.indexOf('?')) : routerUrl;
  }

  playOnAllowDomain() {
    const subdomain = window.location.host.split('.').length > 2 ? `${window.location.host.split('.')[0]}.` : 'fantom.';

    reloadPage(`https://${subdomain}${ALLOW_DOMAIN}${window.location.pathname}`);
  }

  playOnForbiddenDomain() {
    this.isWrongDomain = false;
  }

  // refresh if url changed
  saveSubgraphUrl(): void {
    if (this.subgraphUrlControl.value === CHECKBOX_STATE.NONE) {
      this.subgraphUrlValueControl.setValue(environment.SUBGRAPH_URI);
      this.storageService.remove(STORAGE_CUSTOM_SUBGRAPH_URL);
      this.subgraphUrlValueControl.disable();
    } else {
      this.storageService.set(STORAGE_CUSTOM_SUBGRAPH_URL, this.subgraphUrlValueControl.value!);
      this.subgraphUrlValueControl.enable();
    }
  }

  saveUrl() {
    const subgraphUrl = this.subgraphUrlValueControl.value;
    if (subgraphUrl && subgraphUrl !== environment.SUBGRAPH_URI) {
      this.storageService.set(STORAGE_CUSTOM_SUBGRAPH_URL, subgraphUrl);
    }
    reloadPage();
  }

  protected readonly FORBIDDEN_DOMAIN = FORBIDDEN_DOMAIN;
}
