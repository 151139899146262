import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DailyActivityCompletedEntity } from '@generated/gql';
import { DailyQuestDayModel, DailyQuestDayState } from '@models/daily-quest-day.model';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { ProviderService } from '@services/provider.service';
import { MenuActions } from '@shared/actions/menu.actions';
import { DayPointComponent } from '@shared/components/day-point/day-point.component';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { getDayOfWeek, getStartEpochOfWeek } from '@shared/time-utils';
import { combineLatest, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-daily-points',
  standalone: true,
  imports: [DayPointComponent],
  providers: [DestroyService],
  templateUrl: './daily-points.component.html',
  styleUrl: './daily-points.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex g-flex--align-center',
  },
})
export class DailyPointsComponent implements OnInit {
  account: string;
  chainId: number;

  days: DailyQuestDayModel[] = [
    { state: 'default', dayNumber: 1 },
    { state: 'default', dayNumber: 2 },
    { state: 'default', dayNumber: 3 },
    { state: 'default', dayNumber: 4 },
    { state: 'default', dayNumber: 5 },
    { state: 'default', dayNumber: 6 },
    { state: 'default', dayNumber: 7 },
  ];

  @HostListener('click')
  onOpenDailyQuests() {
    this.router.navigate([MAIN_ROUTES.DAILY_QUESTS]);

    this.mediator.dispatch(new MenuActions.MenuClose());
  }

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private subgraphService: SubgraphService,
    private destroy$: DestroyService,
    private mediator: Mediator,
    private providerService: ProviderService,
  ) {}

  ngOnInit() {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.init();
      },
    );
  }

  init() {
    if (this.chainId && this.account) {
      combineLatest({
        dailyActivityCompleted: this.subgraphService.dailyActivityCompleted$(this.account),
        graphData: this.subgraphService.graphData$(),
      })
        .pipe(
          filter(({ graphData }) => !!graphData?.block?.timestamp),
          takeUntil(this.destroy$),
        )
        .subscribe(({ dailyActivityCompleted, graphData }) => {
          this.processGraphData(
            graphData!.block.timestamp || 0,
            dailyActivityCompleted as DailyActivityCompletedEntity[],
          );

          this.changeDetectorRef.detectChanges();
        });
    }
  }

  private processGraphData(timestamp: number, dailyActivityCompleted: DailyActivityCompletedEntity[]) {
    const startTimestamp = getStartEpochOfWeek(timestamp);
    const completedTimestamps = dailyActivityCompleted
      .map(item => +item.timestamp)
      .filter(item => item >= startTimestamp);

    const currentDay = getDayOfWeek(timestamp);

    this.days = this.calculateDaysState(currentDay, completedTimestamps);
  }

  private calculateDaysState(currentDay: number, completedTimestamps: number[]): DailyQuestDayModel[] {
    const days: DailyQuestDayModel[] = [];

    for (let i = 1; i <= 7; i++) {
      let state: DailyQuestDayState = 'default';

      if (i === currentDay || (i === 7 && currentDay === 0)) {
        state = 'active';
      } else if (i < currentDay) {
        state = completedTimestamps.some(ts => getDayOfWeek(ts) === i) ? 'pass' : 'miss';
      }

      days.push({ dayNumber: i, state });
    }

    return days;
  }
}
