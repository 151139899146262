import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IStatController } from '@data/abi/IStatController';
import { HeroEntity } from '@generated/gql';
import { FeeTokenDataModel } from '@models/fee-token-data.model';
import { HeroModel } from '@models/hero.model';
import { TokenMetadataModel } from '@models/token-metadata.model';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { HeroControllerService } from '@services/onchain/hero-controller.service';
import { ReinforcementService } from '@services/onchain/reinforcement.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { ButtonsLevelUpComponent } from '@shared/components/buttons-level-up/buttons-level-up.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { HERO_CORE_STATS_META } from '@shared/constants/hero-levels.constant';
import { isPrepaymentHero } from '@shared/constants/heroes.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { getValueByKey } from '@shared/utils';
import { ethers, parseUnits } from 'ethers';
import { finalize, forkJoin, takeUntil } from 'rxjs';

import CoreAttributesStruct = IStatController.CoreAttributesStruct;

@Component({
  selector: 'app-hero-level-up',
  standalone: true,
  templateUrl: './hero-level-up.component.html',
  styleUrls: ['./hero-level-up.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    ButtonsLevelUpComponent,
    LoadingSmallComponent,
    DialogTitleComponent,
    ButtonClickDirective,
    BalanceComponent,
    TranslateModule,
  ],
})
export class HeroLevelUpComponent implements OnInit {
  ZERO_BI = 0n;

  // common
  account: string;
  chainId: number;
  loading = true;
  gameToken: string;

  // hero
  heroAdr: string;
  heroId: number;
  hero: HeroEntity;
  fee: FeeTokenDataModel;
  isFreeLvlUp = false;

  stats = 5;
  waiting = false;
  HERO_CORE_STATS = HERO_CORE_STATS_META;
  coreStatsChange: { [key: string]: number } = {
    strength: 0,
    dexterity: 0,
    vitality: 0,
    energy: 0,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: { heroToken: string; heroId: number },
    private router: Router,
    private destroy$: DestroyService,
    private heroControllerService: HeroControllerService,
    private changeDetectorRef: ChangeDetectorRef,
    private tokenService: TokenService,
    private subgraphService: SubgraphService,
    private providerService: ProviderService,
    private appStateService: AppStateService,
    private reinforcementService: ReinforcementService,
    private soundService: SoundService,
    private mediator: Mediator,
    private dialogRef: DialogRef<boolean, HeroLevelUpComponent>,
  ) {
    this.heroAdr = this.data.heroToken;
    this.heroId = this.data.heroId;

    this.appStateService.setHeaderState({
      isInventoryShown: true,
      backUrl: [MAIN_ROUTES.HERO, this.heroAdr, this.heroId.toString()],
    });
  }

  ngOnInit(): void {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.heroControllerService.adjustFees();
        this.tokenService.adjustFees();
        this.init();
      },
    );
  }

  private init() {
    if (this.account && this.chainId) {
      this.gameToken = GET_CORE_ADDRESSES(this.chainId).gameToken;
      this.loadHeroModel();
    }
  }

  loadHeroModel() {
    this.subgraphService
      .hero$(this.heroAdr, this.heroId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hero => {
        this.hero = hero;
        this.isFreeLvlUp = isPrepaymentHero(hero, this.chainId);
        this.fee = new FeeTokenDataModel(
          new TokenMetadataModel(
            hero.meta.feeToken.token.id,
            hero.meta.feeToken.token.symbol,
            hero.meta.feeToken.token.decimals,
          ),
          parseUnits(hero.meta.feeToken.amount, hero.meta.feeToken.token.decimals),
        );
        this.loading = false;
        this.refreshFeeTokenData();
      });
  }

  refreshFeeTokenData() {
    forkJoin([
      this.tokenService.balanceOf$(this.gameToken, this.account),
      this.tokenService.allowance$(this.gameToken, this.account, GET_CORE_ADDRESSES(this.chainId).controller),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([balance, allowance]) => {
        this.fee.accountBalance = balance;
        this.fee.allowance = allowance;
        this.changeDetectorRef.detectChanges();

        this.mediator.dispatch(new LoadingActions.PageLoaded(MAIN_ROUTES.INVENTORY));
      });
  }

  changeStat(level: string, add: boolean) {
    add ? this.coreStatsChange[level]++ : this.coreStatsChange[level]--;

    if (add) {
      this.stats--;
    } else {
      this.stats++;
    }

    // this.soundService.play({ key: 'counter' });
  }

  reset() {
    this.coreStatsChange = {
      strength: 0,
      dexterity: 0,
      vitality: 0,
      energy: 0,
    };
    this.stats = 5;
  }

  needForLevelUp() {
    return parseUnits('10') * BigInt(this.hero.stats.level + 1);
  }

  needForLevelUpN() {
    return ethers.formatUnits(this.needForLevelUp(), 18);
  }

  getHeroValueByKey(key: string): number {
    return getValueByKey<HeroModel>(this.hero.core, key);
  }

  isEnoughBalance() {
    const core = GET_CORE_ADDRESSES(this.chainId);
    return (this.tokenService.tokenBalanceCache.get(core.gameToken) ?? 0n) >= this.needForLevelUp();
  }

  // --- USERS CALLS

  approve() {
    this.waiting = true;
    this.changeDetectorRef.detectChanges();
    this.tokenService
      .approve$(this.account, this.gameToken, GET_CORE_ADDRESSES(this.chainId).controller, BigInt(NUMBERS.MAX_UINT))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.waiting = false;
        this.loadHeroModel();
        this.changeDetectorRef.detectChanges();
      });
  }

  buyTokens() {
    this.waiting = true;
    this.changeDetectorRef.detectChanges();
    this.tokenService
      .buyTokens$(this.account, '', this.gameToken, this.needForLevelUp(), this.chainId)
      .pipe(
        finalize(() => {
          this.waiting = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.loadHeroModel();
        this.changeDetectorRef.detectChanges();
      });
  }

  levelUp() {
    this.waiting = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'level_up' });

    this.heroControllerService
      .levelUp$(
        this.account,
        this.chainId,
        this.hero.meta.id,
        this.hero.heroId,
        this.coreStatsChange as CoreAttributesStruct,
      )
      .pipe(
        finalize(() => {
          this.waiting = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  // todo replace to .en vars
  isButtonLevelUpDisabled(): { reason: string; status: boolean } {
    if (this.waiting) {
      return {
        reason: 'Wait for transaction result',
        status: true,
      };
    }
    if (!this.isFreeLvlUp) {
      if (this.fee.accountBalance < this.needForLevelUp()) {
        return {
          reason: 'Not enough tokens',
          status: true,
        };
      }
      if (this.fee.allowance < this.needForLevelUp()) {
        return {
          reason: 'Not enough allowance',
          status: true,
        };
      }
    }
    if (this.stats !== 0) {
      return {
        reason: 'Use all available attributes points',
        status: true,
      };
    }
    if (!!this.hero.dungeon) {
      return {
        reason: 'Hero in dungeon can not level up',
        status: true,
      };
    }
    if (this.hero.staked) {
      return {
        reason: 'Staked hero can not level up',
        status: true,
      };
    }
    return {
      reason: '',
      status: false,
    };
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
