import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { STORAGE_CUSTOM_RPC_URL, StorageService } from '@services/storage.service';
import { CHAIN_FIELDS, getChainByChainId } from '@shared/constants/chain-ids.constant';
import { TssSecurityQuestion, TssShareType, WEB3AUTH_NETWORK, Web3AuthMPCCoreKit } from '@web3auth/mpc-core-kit';
import { NGXLogger } from 'ngx-logger';
import { from, map, of, switchMap, mergeMap } from 'rxjs';

const DEFAULT_SECURITY_QUESTION = 'ENTER PASSWORD';

@Injectable({
  providedIn: 'root',
})
export class Web3authService {
  private coreKitInstance: Web3AuthMPCCoreKit;
  private securityQuestions = new TssSecurityQuestion();
  inited = false;

  constructor(
    private logger: NGXLogger,
    private storageService: StorageService,
  ) {}

  private getWeb3AuthInstance() {
    const clientId = environment['WEB_3_AUTH_CLIENT_ID'];
    let rpcUrl = environment['RPC_PROVIDER_URL'];
    if (this.storageService.get(STORAGE_CUSTOM_RPC_URL)) {
      rpcUrl = this.storageService.get(STORAGE_CUSTOM_RPC_URL);
    }
    const chainInfo = getChainByChainId(Number(environment['CHAIN_ID']));

    if (!clientId) {
      throw new Error('WEB_3_AUTH_CLIENT_ID is not set');
    }
    if (!rpcUrl) {
      throw new Error('RPC_PROVIDER_URL is not set');
    }

    return new Web3AuthMPCCoreKit({
      web3AuthClientId: clientId,
      web3AuthNetwork: WEB3AUTH_NETWORK.MAINNET,
      uxMode: 'redirect',
      // baseUrl: `${location.origin}/serviceworker`,
      // enableLogging: environment.STAGING === 'true',
      enableLogging: false,
      chainConfig: {
        chainNamespace: 'eip155',
        chainId: '0x' + chainInfo[CHAIN_FIELDS.ID].toString(16),
        rpcTarget: rpcUrl,
        displayName: chainInfo[CHAIN_FIELDS.NAME],
        blockExplorer: chainInfo[CHAIN_FIELDS.BLOCK_EXPLORER],
        ticker: chainInfo[CHAIN_FIELDS.ICON_NAME].toUpperCase(),
        tickerName: chainInfo[CHAIN_FIELDS.ICON_NAME].toUpperCase(),
        decimals: 18,
      },
    });
  }

  init(callback = (_: boolean) => {}) {
    if (this.coreKitInstance || this.inited) {
      this.logger.warn('Web3Auth already inited');
      callback(false);
      return;
    }

    this.coreKitInstance = this.getWeb3AuthInstance();

    this.logger.trace('Initializing CoreKit synchronously');
    from(this.coreKitInstance.init()).subscribe({
      next: () => {
        this.logger.trace('CoreKit initialized');
        this.inited = true;
        callback(true);
      },
      error: error => {
        this.logger.error('CoreKit initialization failed', error);
        callback(false);
      },
    });
  }

  initAsync() {
    if (this.coreKitInstance || this.inited) {
      this.logger.warn('Web3Auth already inited');
      return of(false);
    }

    this.coreKitInstance = this.getWeb3AuthInstance();

    return from(this.coreKitInstance.init()).pipe(
      mergeMap(() => {
        this.logger.trace('CoreKit initialized');
        this.inited = true;
        return of(true);
      }),
    );
  }

  oauthLogin$() {
    if (this.coreKitInstance.provider !== null) {
      // we already connected
      return of(true);
    }

    const web3AuthVerifier = environment['WEB_3_AUTH_VERIFIER'];
    const auth0ClientId = environment['AUTH0_CLIENT_ID'];
    const auth0Domain = environment['AUTH0_DOMAIN'];

    if (!auth0ClientId || !auth0Domain || !web3AuthVerifier) {
      throw new Error('oauthLogin details not set');
    }

    return from(
      this.coreKitInstance.loginWithOauth({
        subVerifierDetails: {
          typeOfLogin: 'jwt',
          verifier: web3AuthVerifier,
          clientId: auth0ClientId,
          jwtParams: {
            domain: auth0Domain,
            client_id: auth0ClientId,
            redirect_uri: `${location.origin}/web3auth-settings`,
            verifierIdField: 'email',
          },
        },
      }),
    ).pipe(switchMap(() => of(true)));
  }

  getUserInfo() {
    return this.coreKitInstance?.state?.userInfo;
  }

  getProvider() {
    return this.coreKitInstance.provider;
  }

  logout() {
    return this.coreKitInstance.logout();
  }

  exportPrivateKey() {
    this.coreKitInstance.metadataKey;
    return from(this.coreKitInstance._UNSAFE_exportTssKey());
  }

  ////////////////

  isPasswordEnabled(): boolean {
    try {
      const question = this.securityQuestions.getQuestion(this.coreKitInstance);
      return !!question;
    } catch {
      // It errors out if recovery is not setup currently
      return false;
    }
  }

  upsertPassword$(newPassword: string, oldPassword?: string) {
    if (this.isPasswordEnabled()) {
      if (!oldPassword) {
        throw Error('To change the password you need to provide the old password');
      }
      return from(
        this.securityQuestions.changeSecurityQuestion({
          answer: oldPassword,
          mpcCoreKit: this.coreKitInstance,
          newAnswer: newPassword,
          newQuestion: DEFAULT_SECURITY_QUESTION,
        }),
      ).pipe(map(() => ''));
    } else {
      return from(
        this.securityQuestions.setSecurityQuestion({
          question: DEFAULT_SECURITY_QUESTION,
          answer: newPassword,
          mpcCoreKit: this.coreKitInstance,
          shareType: TssShareType.DEVICE,
        }),
      );
    }
  }

  recoverWithPassword$(password: string) {
    return from(this.securityQuestions.recoverFactor(this.coreKitInstance, password));
  }
}
