<app-dialog-title
  [title]="'Quick transactions signing'"
  [canClose]="true"
  (closeDialog)="close()">
</app-dialog-title>

<div class="content g-flex-column g-flex--align-center g-flex--justify-center">
  <div class="controls g-flex-column g-flex--align-center gap-32">
    <input
      type="password"
      placeholder="Password"
      class="app-input app-input--huge app-btn_font-size"
      (keyup.enter)="exportPrivateKey()"
      [formControl]="oldPasswordControl" />

    @if (!isPasswordExist()) {
      <input
        type="password"
        class="app-input app-input--huge app-btn_font-size"
        placeholder="Repeat password"
        (keyup.enter)="!isPasswordExist() ? upsertPassword() : exportPrivateKey()"
        [formControl]="newPasswordControl" />
    }
    @if (!isPasswordExist()) {
      <app-check-password
        [passwordControl]="oldPasswordControl"
        [newPasswordControl]="newPasswordControl">
      </app-check-password>
    }
    @if (!isPasswordExist()) {
      <button
        class="app-btn-blue-3 g-flex g-flex--align-center g-flex--justify-center"
        [disabled]="
          (isPasswordExist() ? !newPasswordControl.value || !oldPasswordControl.value : !newPasswordControl.value) ||
          isWeb3authActionInProgress ||
          (newPasswordControl.value && !newPasswordControl.value.match(strongPasswordRegExp)) ||
          (oldPasswordControl.value && !oldPasswordControl.value.match(strongPasswordRegExp))
        "
        (click)="upsertPassword()">
        @if (isWeb3authActionInProgress) {
          <app-loading-small
            class="password-loader"
            text="">
          </app-loading-small>
        }

        <div>Set password</div>
      </button>
    }
    @if (!isPrivateKeyExtracted()) {
      <button
        class="app-btn-blue-3 g-flex g-flex--align-center g-flex--justify-center"
        [disabled]="!oldPasswordControl.value || !isPasswordExist() || isWeb3authActionInProgress"
        (click)="exportPrivateKey()">
        @if (isDecodePasswordInProgress) {
          <app-loading-small
            class="decode-loader"
            text="">
          </app-loading-small>
        }

        <div>Quick signing</div>
      </button>
    }
    @if (web3authMsg !== '') {
      <div class="app-btn_font-size color-red-fire">{{ web3authMsg }}</div>
    }
  </div>
</div>
