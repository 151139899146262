import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CallWithERC2771Request, GelatoRelay, SignerOrProvider } from '@gelatonetwork/relay-sdk';
import { ERC2771Type } from '@gelatonetwork/relay-sdk/dist/lib/erc2771/types';
import { blockExplorerLink } from '@helpers/web3-helper';
import { TransactionDataModel } from '@models/transaction-data.model';
import { Mediator } from '@services/mediator.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { NGXLogger } from 'ngx-logger';
import { filter, from, Observable, retry, Subject, tap } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

const RELAY_SERVER_URL = environment.RELAY_SERVER;
const GELATO_WS_URL = 'wss://api.gelato.digital/tasks/ws/status';
const GELATO_API_URL = 'https://api.gelato.digital';

export type RateLimitType = {
  limit: number;
  remaining: number;
  globalLimit: number;
  globalRemaining: number;
};

export type RelayResponse = {
  taskId: string;
};

type TransactionStatusResponse = {
  chainId: number;
  taskId: string;
  taskState: TaskState;
  creationDate: string;
  lastCheckDate?: string;
  lastCheckMessage?: string;
  transactionHash?: string;
  blockNumber?: number;
  executionDate?: string;
  gasUsed?: string;
  effectiveGasPrice?: string;
};

enum TaskState {
  CheckPending = 'CheckPending',
  ExecPending = 'ExecPending',
  WaitingForConfirmation = 'WaitingForConfirmation',
  ExecSuccess = 'ExecSuccess',
  ExecReverted = 'ExecReverted',
  Cancelled = 'Cancelled',
}

type WsMessage = {
  event: string;
  payload: TransactionStatusResponse;
};

@Injectable({
  providedIn: 'root',
})
export class GelatoRelayService {
  gelatoRelay: GelatoRelay;

  lastCheckMessages = new Map<string, string>();
  msgCompleted = new Map<string, boolean>();

  constructor(
    private http: HttpClient,
    private mediator: Mediator,
    private logger: NGXLogger,
  ) {
    this.gelatoRelay = new GelatoRelay();
  }

  sponsoredCall$(
    chainId: string,
    from: string,
    to: string,
    data: string,
    userNonce: string,
    userDeadline: string,
    signature: string,
    gasLimit: string,
  ): Observable<RelayResponse> {
    const url = RELAY_SERVER_URL + '/v1/relay';
    const body = {
      chainId,
      from,
      to,
      data,
      userDeadline,
      signature,
      userNonce,
      gasLimit,
    };

    this.logger.trace('sponsoredCall', url, body);

    return this.http.post<RelayResponse>(url, JSON.stringify(body), {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getRelayLimit$(chainId: string, address: string): Observable<RateLimitType> {
    const url = RELAY_SERVER_URL + `/v1/relay/${chainId}/${address}`;

    return this.http.get<RateLimitType>(url, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  subscribeToTaskResults(gelatoWebSocket: WebSocketSubject<any>, taskId: string): void {
    this.logger.trace('Subscribe on WS task results', taskId);
    gelatoWebSocket.next({
      action: 'subscribe',
      taskId,
    });
  }

  unsubscribeToTaskResults(gelatoWebSocket: WebSocketSubject<any>, taskId: string): void {
    this.msgCompleted.set(taskId.toLowerCase(), true);
    this.logger.trace('Unsubscribe on WS task results', taskId);
    gelatoWebSocket.next({
      action: 'unsubscribe',
      taskId,
    });
    gelatoWebSocket.unsubscribe();
  }

  getTaskIdResult$(taskId: string) {
    const url = GELATO_API_URL + `/tasks/status/${taskId}`;
    this.logger.trace('getTskIdResult$', taskId);

    return this.http
      .get<{ task: TransactionStatusResponse }>(url, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(retry({ count: 3, delay: 100 }));
  }

  waitForTaskResult$(
    taskId: string,
    data: TransactionDataModel,
  ): Observable<{ taskId: string; txHash?: string; error?: string }> {
    const waiter = new Subject<{ taskId: string; txHash?: string; error?: string }>();

    const gelatoWebSocket: WebSocketSubject<any> = webSocket(GELATO_WS_URL);

    this.subscribeToTaskResults(gelatoWebSocket, taskId);
    let loadingShowed = false;

    gelatoWebSocket
      .pipe(
        tap((msg: WsMessage) => {
          if (msg.payload.lastCheckMessage) {
            this.lastCheckMessages.set(msg.payload.taskId.toLowerCase(), msg.payload.lastCheckMessage ?? '');
          }
          this.logger.trace('>>> Task result:', msg.payload.taskState, msg.payload.lastCheckMessage);
          if (
            (msg.payload.taskState === TaskState.Cancelled || msg.payload.taskState === TaskState.ExecReverted) &&
            !this.msgCompleted.has(msg.payload.taskId.toLowerCase())
          ) {
            this.unsubscribeToTaskResults(gelatoWebSocket, msg.payload.taskId);
            const error =
              msg.payload.lastCheckMessage ??
              'On-chain transaction failed: ' + taskId + ' ' + this.lastCheckMessages.get(taskId.toLowerCase());
            waiter.next({ taskId, error });
            waiter.complete();
          }
        }),
        tap((msg: WsMessage) => {
          if (data.showLoadingScreen && !loadingShowed) {
            if (msg.payload.transactionHash) {
              data.tx = msg.payload.transactionHash;
              data.txLink = blockExplorerLink(data.chainId ?? 0) + '/tx/' + msg.payload.transactionHash;
            }
            loadingShowed = true;
            this.mediator.dispatch(new LoadingActions.Toggle(true, data));
          }
        }),
        filter((msg: WsMessage) => {
          return (
            msg.payload.taskId.toLowerCase() === taskId.toLowerCase() &&
            !!msg.payload.transactionHash &&
            msg.payload.taskState === TaskState.ExecSuccess
            // || msg.payload.taskState === TaskState.WaitingForConfirmation
            // || msg.payload.taskState === TaskState.ExecPending
            // || msg.payload.taskState === TaskState.CheckPending
          );
        }),
        tap((msg: WsMessage) => {
          this.logger.trace('>>> Final Task result:', this.lastCheckMessages.get(taskId.toLowerCase()), msg);
          this.unsubscribeToTaskResults(gelatoWebSocket, msg.payload.taskId);
          waiter.next({ taskId, txHash: msg.payload.transactionHash ?? 'NO_TX_HASH' });
          waiter.complete();
        }),
      )
      .subscribe();

    return waiter;
  }

  getSignatureDataERC2771$(
    signerOrProvider: SignerOrProvider,
    chainId: number,
    target: string,
    data: string,
    user: string,
  ) {
    const request: CallWithERC2771Request = {
      chainId: BigInt(chainId),
      target,
      data,
      user,
      userDeadline: Math.floor(Date.now() / 1000) + 60 * 10, // 10 minutes
    };
    return from(this.gelatoRelay.getSignatureDataERC2771(request, signerOrProvider, ERC2771Type.SponsoredCall));
  }
}
