import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemEntity } from '@generated/gql';
import { Formatter } from '@helpers/formatter';
import { TranslateModule } from '@ngx-translate/core';
import { AppStateService } from '@services/app-state-service/app-state.service';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { Mediator } from '@services/mediator.service';
import { HeroControllerService } from '@services/onchain/hero-controller.service';
import { ItemControllerService } from '@services/onchain/item-controller.service';
import { TokenService } from '@services/onchain/token.service';
import { ProviderService } from '@services/provider.service';
import { SoundService } from '@services/sound.service';
import { LoadingActions } from '@shared/actions/loading.actions';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { BalanceComponent } from '@shared/components/balance/balance.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { LoadingSmallComponent } from '@shared/components/loading-small/loading-small.component';
import { ScratchComponent } from '@shared/components/scratch/scratch.component';
import { GET_CORE_ADDRESSES } from '@shared/constants/addresses/addresses.constant';
import { ItemActionType } from '@shared/constants/inventory.constants';
import { ITEM_TYPE } from '@shared/constants/items.constant';
import { NUMBERS } from '@shared/constants/numbers.constant';
import { MAIN_ROUTES } from '@shared/constants/routes.constant';
import { formatUnits, parseUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { finalize, forkJoin, takeUntil } from 'rxjs';

import { ForgeSuccessDialogComponent } from './components/forge-success-dialog/forge-success-dialog.component';

const EMPTY_ITEM = {
  itemId: 0,
  equippedSlot: ITEM_TYPE.NO_SLOT,
} as ItemEntity;

@Component({
  standalone: true,
  templateUrl: './forge-dialog.component.html',
  styleUrls: ['./forge-dialog.component.scss'],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-window-responsive-background g-flex-column',
  },
  imports: [
    LoadingSmallComponent,
    DialogTitleComponent,
    ItemSlotComponent,
    ScratchComponent,
    ButtonClickDirective,
    ItemCardComponent,
    BalanceComponent,
    TranslateModule,
  ],
})
export class ForgeDialogComponent implements OnInit {
  // common
  account: string;
  chainId: number;

  loading = true;

  exist = true;

  heroToken: string;
  heroTokenId: number;
  itemAdr: string;
  itemId: number;

  MAIN_ROUTES = MAIN_ROUTES;

  catalystsItems: ItemEntity[] = [];
  targetItem?: ItemEntity;
  selectedCatalyst?: ItemEntity;

  accountBalance = 0n;
  accountBalanceFormatted = '0';
  isEnoughAllowance = false;
  isEnoughBalance = false;

  private successDialogRef:
    | DialogRef<{ item: ItemEntity; isSuccess: boolean }, ForgeSuccessDialogComponent>
    | undefined;
  private dialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined;

  transactionLoading = false;
  itemExist = false;

  constructor(
    @Inject(DIALOG_DATA) public data: { heroToken: string; heroId: number; itemMetaId: string; itemId: number },
    private destroy$: DestroyService,
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger,
    private appStateService: AppStateService,
    private dialog: Dialog,
    private subgraphService: SubgraphService,
    private providerService: ProviderService,
    private heroControllerService: HeroControllerService,
    private itemControllerService: ItemControllerService,
    private tokenService: TokenService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private soundService: SoundService,
    private mediator: Mediator,
    private forgeDialogRef: DialogRef<boolean, ForgeDialogComponent>,
  ) {
    this.heroToken = this.data.heroToken;
    this.heroTokenId = this.data.heroId;
    this.itemAdr = this.data.itemMetaId;
    this.itemId = this.data.itemId;

    this.appStateService.setHeaderState({
      isAccountBalanceShown: true,
      isInventoryShown: true,
      backUrl: [MAIN_ROUTES.MAIN, MAIN_ROUTES.INVENTORY, this.heroToken, this.heroTokenId.toString()],
    });
  }

  ngOnInit(): void {
    this.providerService.subscribeOnAccountAndNetwork(
      this.destroy$,
      this.changeDetectorRef,
      account => {
        this.account = account;
        this.init();
      },
      chainId => {
        this.chainId = chainId;
        this.heroControllerService.adjustFees();
        this.itemControllerService.adjustFees();
        this.tokenService.adjustFees();
        this.init();
      },
    );
  }

  private init() {
    if (this.account && this.chainId) {
      this.loadItems();
    }
  }

  private loadItems() {
    this.subgraphService
      .usersItems$(this.account)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(items => {
        this.targetItem = items.filter(
          item => item.itemId === this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase(),
        )[0] as ItemEntity;

        this.catalystsItems = items
          .filter(item => item.itemId !== this.itemId && item.meta.id.toLowerCase() === this.itemAdr.toLowerCase())
          .map(i => i as ItemEntity);

        this.updateFeeBalance();

        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  updateFeeBalance() {
    if (!this.targetItem || !this.targetItem.meta.feeToken) {
      return;
    }
    forkJoin([
      this.tokenService.allowance$(
        this.targetItem.meta.feeToken.token.id,
        this.account,
        GET_CORE_ADDRESSES(this.chainId).controller,
      ),
      this.tokenService.balanceOf$(this.targetItem.meta.feeToken.token.id, this.account),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([allowance, balance]) => {
        if (!this.targetItem || !this.targetItem.meta.feeToken) {
          return;
        }
        this.isEnoughAllowance =
          allowance >= parseUnits(this.targetItem.meta.feeToken.amount, this.targetItem.meta.feeToken.token.decimals);
        this.isEnoughBalance =
          balance >= parseUnits(this.targetItem.meta.feeToken.amount, this.targetItem.meta.feeToken.token.decimals);
        this.logger.trace('this.isEnoughBalance', this.isEnoughBalance);
        this.accountBalance = balance;
        this.accountBalanceFormatted = Formatter.formatCurrency(
          +formatUnits(balance, this.targetItem.meta.feeToken.token.decimals),
        );
        this.changeDetectorRef.detectChanges();

        this.mediator.dispatch(new LoadingActions.PageLoaded(MAIN_ROUTES.FORGE));
      });
  }

  selectItem(item: ItemEntity) {
    this.selectedCatalyst = item;
    this.changeDetectorRef.detectChanges();
  }

  onItemSlotClick(item?: ItemEntity) {
    if (item && item.itemId !== 0) {
      this.dialogRef = this.dialog.open(ItemDescriptionDialogComponent, {
        panelClass: 'app-overlay-pane-full-width',
        data: { item, isShowButtons: false, chainId: this.chainId },
      });
    }
  }

  approveFeeToken() {
    if (!this.targetItem || !this.targetItem.meta.feeToken) {
      return;
    }
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();
    this.tokenService
      .approve$(
        this.account,
        this.targetItem.meta.feeToken.token.id,
        GET_CORE_ADDRESSES(this.chainId).controller,
        BigInt(NUMBERS.MAX_UINT),
      )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateFeeBalance();
      });
  }

  augment() {
    if (!this.targetItem) {
      return;
    }
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();
    let txSent = false;

    this.soundService.play({ key: 'forge' });

    this.itemControllerService
      .augment$(
        this.account,
        this.chainId,
        this.targetItem.meta.id,
        this.targetItem.itemId,
        this.selectedCatalyst?.itemId ?? 0,
      )
      .pipe(
        finalize(() => {
          // only if tx rejected, otherwise need to do it in the `openResult`
          if (!txSent) {
            this.transactionLoading = false;
            this.changeDetectorRef.detectChanges();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        txSent = true;
        this.openResult(this.targetItem);
      });
  }

  openResult(oldItem: ItemEntity | undefined) {
    this.subgraphService
      .item$(this.itemAdr, this.itemId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(item => {
        this.transactionLoading = false;
        this.init();
        this.selectedCatalyst = undefined;
        this.changeDetectorRef.detectChanges();

        this.exist = !item.burned;

        this.soundService.play({ key: this.exist ? 'forge_success' : 'forge_fail' });

        this.successDialogRef = this.dialog.open(ForgeSuccessDialogComponent, {
          panelClass: 'app-overlay-pane',
          data: { oldItem: oldItem, newItem: this.exist ? item : EMPTY_ITEM, isSuccess: this.exist },
        });

        this.successDialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.logger.trace('dialog res', data, this.exist);
          this.forgeDialogRef.close(true);
        });
      });
  }

  buyTokens() {
    if (!this.targetItem || !this.targetItem.meta.feeToken) {
      return;
    }
    this.transactionLoading = true;
    this.changeDetectorRef.detectChanges();

    this.soundService.play({ key: 'buy_coins' });

    const feeTokenData = this.targetItem.meta.feeToken;
    this.tokenService
      .buyTokens$(
        this.account,
        '',
        feeTokenData.token.id,
        parseUnits(feeTokenData.amount, feeTokenData.token.decimals),
        this.chainId,
      )
      .pipe(
        finalize(() => {
          this.transactionLoading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateFeeBalance();
      });
  }

  resetForge() {
    this.selectedCatalyst = undefined;
  }

  close(): void {
    this.forgeDialogRef.close(false);
  }
}
