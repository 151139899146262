@if (isShowBorder) {
  <img
    class="border"
    src="assets/images/ui/dividers/borders_v/border_v_red_down.png"
    alt="" />
}

@if (isShowBack) {
  <div
    class="btn-back g-flex__item-fixed g-flex g-flex--align-center g-flex--justify-center pointer"
    (click)="back.emit()">
    <img
      src="assets/images/ui/icons/other/left.png"
      alt="" />
  </div>
}

@if (canClose) {
  <button
    class="btn-close app-btn-cross-light"
    (click)="closeDialog.emit()"></button>
}

<div class="title app-black-paint-for-text g-flex-column g-flex--align-center">
  <div
    class="g-flex g-flex--align-center"
    [style.margin-left.px]="-iconWidth">
    @if (!!icon) {
      <img
        class="icon"
        [src]="icon"
        alt="" />
    }

    <div class="loader-container g-flex g-flex--align-center gap-24">
      <div
        class="title-a app-btn-huge_font-size"
        [ngStyle]="titleStyles">
        {{ title }}
      </div>

      @if (isShowLoader) {
        <app-loading-small
          class="title-loader"
          text="">
        </app-loading-small>
      }
    </div>

    @if (descText) {
      <img
        class="info"
        src="/assets/images/ui/icons/other/info-holder-question.png"
        alt=""
        (click)="onShowDescDialog()" />
    }
  </div>

  @if (!!subTitle) {
    <div class="title-a app-btn_font-size">
      {{ subTitle }}
    </div>
  }

  @if (hasTitleScratch) {
    <app-scratch
      class="title__scratch"
      backgroundType="middle">
    </app-scratch>
  }
</div>
