import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Faucet__factory, GameToken__factory, IETH__factory, Multicall, NftBase__factory } from '@data/abi';
import { environment } from '@environments/environment';
import { NftModel } from '@models/nft.model';
import { TransactionDataModel } from '@models/transaction-data.model';
import { ErrorService } from '@services/error.service';
import { FeesExtension, ON_CHAIN_CALL_DELAY, ON_CHAIN_CALL_RETRY } from '@services/onchain/FeesExtension';
import { RelayService } from '@services/onchain/relay.service';
import { ProviderService } from '@services/provider.service';
import { TransactionsService } from '@services/transactions.service';
import { SwapCoinsDialogComponent } from '@shared/components/swap-coins-dialog/swap-coins-dialog.component';
import {
  GET_CORE_ADDRESSES,
  getFaucetForToken,
  isSimpleTestNetwork,
} from '@shared/constants/addresses/addresses.constant';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import { adjustGasLimit } from '@shared/utils';
import { ContractTransaction, formatUnits } from 'ethers';
import { NGXLogger } from 'ngx-logger';
import { catchError, concatMap, filter, forkJoin, from, map, mergeMap, of, range, retry, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends FeesExtension {
  tokenBalanceCache = new Map<string, bigint>();

  constructor(
    private providerService: ProviderService,
    private transactionsService: TransactionsService,
    private dialog: Dialog,
    private logger: NGXLogger,
    private errorService: ErrorService,
    private relayService: RelayService,
  ) {
    super();
  }

  // --- FACTORIES ---

  createERC20(token: string) {
    return GameToken__factory.connect(token, this.providerService.getProviderForRead());
  }

  createFaucet(account: string, faucetAdr: string) {
    return Faucet__factory.connect(faucetAdr, this.providerService.getProviderForRead());
  }

  createERC721(token: string) {
    return NftBase__factory.connect(token, this.providerService.getProviderForRead());
  }

  createIETH(token: string) {
    return IETH__factory.connect(token, this.providerService.getProviderForRead());
  }

  // --- VIEWS ---

  balanceOf$(token: string, adr: string) {
    // this.logger.trace('balanceOf', token, adr);
    return from(this.createERC20(token).balanceOf(adr)).pipe(
      tap(b => {
        this.tokenBalanceCache.set(token, b);
      }),
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  balanceOf(token: string, adr: string): Multicall.CallStruct {
    return {
      target: token,
      callData: this.createERC20(token).interface.encodeFunctionData('balanceOf', [adr]),
    };
  }

  symbol(token: string): Multicall.CallStruct {
    return {
      target: token,
      callData: this.createERC20(token).interface.encodeFunctionData('symbol'),
    };
  }

  allowance$(token: string, owner: string, spender: string) {
    // this.logger.trace('allowance', token, owner, spender);
    return from(this.createERC20(token).allowance(owner, spender)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
      catchError((err, res) => {
        if (err) {
          this.logger.error(`Error get allowance for token: ${token} owner: ${owner} spender: ${spender}`, err);
          throw err;
        }
        return res;
      }),
    );
  }

  getApproved$(token: string, tokenId: number) {
    this.logger.trace('getApproved', token, tokenId);
    return from(this.createERC721(token).getApproved(tokenId)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  isApprovedForAll$(token: string, owner: string, spender: string) {
    this.logger.trace('isApprovedForAll', token, owner, spender);
    return from(this.createERC721(token).isApprovedForAll(owner, spender)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  ownerOf$(token: string, id: number) {
    this.logger.trace('ownerOf', token, id);
    return from(this.createERC721(token).ownerOf(id)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  tokenOfOwnerByIndex$(owner: string, token: string, id: number) {
    this.logger.trace('tokenOfOwnerByIndex', owner, token, id);
    return from(this.createERC721(token).tokenOfOwnerByIndex(owner, id)).pipe(
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  balanceOfNfts$(account: string, token: string) {
    this.logger.trace('balanceOfNfts', account, token);
    return this.balanceOf$(token, account).pipe(
      filter(b => b !== 0n),
      mergeMap(b => {
        this.logger.trace('item balance', b);
        return range(0, Number(b));
      }),
      mergeMap(i => {
        return this.tokenOfOwnerByIndex$(account, token, i);
      }),
      map(id => new NftModel(token, Number(id))),
      retry({ count: ON_CHAIN_CALL_RETRY, delay: ON_CHAIN_CALL_DELAY }),
      catchError(this.errorService.onCatchError),
    );
  }

  priceTokenToEth$(account: string, faucetToken: string, amountIn: bigint, chainId: number) {
    this.logger.trace('priceTokenToEth', account, faucetToken, amountIn, chainId);
    const faucet = this.createFaucet(account, getFaucetForToken(faucetToken, chainId));
    return from(faucet.priceTokenToEth(amountIn));
  }

  priceEthToToken$(account: string, faucetToken: string, amountOut: bigint, chainId: number) {
    this.logger.trace('priceEthToToken', account, faucetToken, amountOut, chainId);
    const faucet = this.createFaucet(account, getFaucetForToken(faucetToken, chainId));
    return from(faucet.priceEthToToken(amountOut));
  }

  // --- CALLS ---

  approve$(signer: string, token: string, spender: string, amount: bigint) {
    return from(this.createERC20(token).approve.estimateGas(spender, amount)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Approve',
            subgraphWaitUserData: false,
            isSponsoredRelayPossible: this.providerService.chainId !== CHAIN_IDS.SEPOLIA,
            txPopulated: this.createERC20(token).approve.populateTransaction(spender, amount),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  transferNft$(signer: string, nftAdr: string, nftId: number, recipient: string) {
    return from(this.createERC721(nftAdr).transferFrom.estimateGas(signer, recipient, nftId)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Transfer NFT',
            subgraphWaitUserData: false,
            isSponsoredRelayPossible: this.providerService.chainId !== CHAIN_IDS.SEPOLIA,
            txPopulated: this.createERC721(nftAdr).transferFrom.populateTransaction(signer, recipient, nftId),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: this.providerService.chainId !== CHAIN_IDS.SONIC,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  sendEth$(signer: string, recipient: string, amount: bigint, fromDelegator = false) {
    this.logger.trace('sendEth', recipient, amount, fromDelegator);
    return forkJoin([
      this.providerService.getSignerForWrite$(signer, fromDelegator),
      this.updateCurrentFees$(this.providerService, 1_000_000n),
    ]).pipe(
      concatMap(([p, gas]) => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Send ETH',
            subgraphWaitUserData: false,
            value: amount,
            txPopulated: p.populateTransaction({ to: recipient }) as Promise<ContractTransaction>,
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            useDelegatedPK: fromDelegator,
            isSponsoredRelayPossible: false,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  unwrap$(token: string, amount: bigint) {
    this.logger.trace(`unwrap`, token, amount);
    return from(this.createIETH(token).withdraw.estimateGas(amount)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Unwrap',
            isNeedUpdateHero: false,
            isNeedUpdateBalances: true,
            txPopulated: this.createIETH(token).withdraw.populateTransaction(amount),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  wrap$(token: string, amount: bigint) {
    this.logger.trace(`wrap`, token, amount);
    return from(this.createIETH(token).deposit.estimateGas()).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Wrap',
            isNeedUpdateHero: false,
            isNeedUpdateBalances: true,
            txPopulated: this.createIETH(token).deposit.populateTransaction(),
            gasLimit: adjustGasLimit(gas),
            value: amount,
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  approveAllNFT$(signer: string, token: string, spender: string) {
    return from(this.createERC721(token).setApprovalForAll.estimateGas(spender, true)).pipe(
      switchMap(gasEstimation => this.updateCurrentFees$(this.providerService, gasEstimation)),
      concatMap(gas => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Approve all NFT',
            subgraphWaitUserData: false,
            txPopulated: this.createERC721(token).setApprovalForAll.populateTransaction(spender, true),
            gasLimit: adjustGasLimit(gas),
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: this.providerService.chainId !== CHAIN_IDS.SONIC,
            relayService: this.relayService,
          }),
        );
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  buyTokens$(account: string, sellToken: string, buyToken: string, buyAmount: bigint, chainId: number) {
    this.logger.trace('buyTokens$', account, sellToken, buyToken, buyAmount, chainId);
    return of(null).pipe(
      switchMap(() => {
        const magicTokenAdr = GET_CORE_ADDRESSES(Number(environment['CHAIN_ID'])).magicToken;

        // todo add buy possibility in the game UI
        if (magicTokenAdr.toLowerCase() === buyToken.toLowerCase()) {
          this.openBuyTokenExternalLink(magicTokenAdr, chainId);
          return of(true);
        }

        const simpleNet = isSimpleTestNetwork(chainId);
        if (!simpleNet) {
          const dref = this.dialog.open(SwapCoinsDialogComponent, {
            panelClass: 'app-overlay-pane',
            data: {},
          });

          return dref.closed;
        } else {
          const faucet = this.createFaucet(account, getFaucetForToken(buyToken, chainId));

          return from(faucet.priceEthToToken((buyAmount * 11n) / 10n)).pipe(
            switchMap(price => {
              return forkJoin([faucet.getToken.estimateGas({ value: price }), of(price)]);
            }),
            switchMap(([gasEstimation, price]) =>
              forkJoin([this.updateCurrentFees$(this.providerService, gasEstimation), of(price)]),
            ),
            mergeMap(([gas, price]) => {
              console.log('Buy on ETH:', formatUnits(price));
              return this.providerService.onChainCall(
                new TransactionDataModel({
                  name: 'Get token via faucet',
                  isNeedUpdateBalances: true,
                  subgraphWaitUserData: false,
                  isSponsoredRelayPossible: false,
                  txPopulated: faucet.getToken.populateTransaction(),
                  value: price,
                  gasLimit: adjustGasLimit(gas),
                  maxFeePerGas: this.maxFeePerGas,
                  maxPriorityFeePerGas: this.maxPriorityFeePerGas,
                  gasPrice: this.gasPrice,
                  isDelegatedRelayPossible: false,
                }),
              );
            }),
          );
        }
      }),
      catchError(this.errorService.onCatchError),
    );
  }

  openBuyTokenExternalLink(token: string, chainId: number) {
    if (chainId === CHAIN_IDS.POLYGON) {
      if (token.toLowerCase() === '0x255707B70BF90aa112006E1b07B9AeA6De021424'.toLowerCase()) {
        window.open(`https://app.1inch.io/#/137/simple/swap/USDC/TETU`, '_blank');
      } else if (token.toLowerCase() === '0x0d397f4515007ae4822703b74b9922508837a04e'.toLowerCase()) {
        window.open(
          `https://v2.tetu.io/invest?tokenId=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&vaultId=0x0d397f4515007ae4822703b74b9922508837a04e`,
          '_blank',
        );
      } else {
        window.open(
          `https://app.uniswap.org/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=${token}`,
          '_blank',
        );
      }
    } else if (chainId === CHAIN_IDS.FANTOM) {
      if (token.toLowerCase() === '0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C'.toLowerCase()) {
        window.open(
          `https://app.paraswap.io/#/0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE-0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C/100/SELL?network=fantom`,
          '_blank',
        );
      }
      if (token.toLowerCase() === '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'.toLowerCase()) {
        window.open(
          `https://spooky.fi/#/swap?inputCurrency=ETH&outputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83`,
          '_blank',
        );
      }
    } else if (chainId === CHAIN_IDS.SONIC) {
      if (token.toLowerCase() === '0x7AD5935EA295c4E743e4f2f5B4CDA951f41223c2'.toLowerCase()) {
        // window.open(
        //   `https://app.paraswap.io/#/0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE-0xe4436821E403e78a6Dd62f7a9F5611f97a18f44C/100/SELL?network=fantom`,
        //   '_blank',
        // );
        // todo
      }
      if (token.toLowerCase() === '0x039e2fB66102314Ce7b64Ce5Ce3E5183bc94aD38'.toLowerCase()) {
        // window.open(
        //   `https://spooky.fi/#/swap?inputCurrency=ETH&outputCurrency=0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83`,
        //   '_blank',
        // );
        // todo
      }
    } else if (chainId === CHAIN_IDS.REAL) {
      window.open(`https://www.pearl.exchange/trade`, '_blank');
    } else {
      throw new Error('Not support buy token on chain:' + chainId);
    }
  }

  networkTokenToMockToken(account: string, faucetToken: string, ethAmountIn: bigint, chainId: number) {
    const faucet = this.createFaucet(account, getFaucetForToken(faucetToken, chainId));
    return this.updateCurrentFees$(this.providerService, 0n).pipe(
      mergeMap(() => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Get token via faucet',
            isNeedUpdateBalances: true,
            subgraphWaitUserData: false,
            isSponsoredRelayPossible: false,
            txPopulated: faucet.getToken.populateTransaction(),
            value: ethAmountIn,
            gasLimit: undefined,
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
    );
  }

  mockTokenToNetworkToken(account: string, faucetToken: string, tokenAmountIn: bigint, chainId: number) {
    const faucet = this.createFaucet(account, getFaucetForToken(faucetToken, chainId));
    return this.updateCurrentFees$(this.providerService, 0n).pipe(
      mergeMap(() => {
        return this.providerService.onChainCall(
          new TransactionDataModel({
            name: 'Get token via faucet',
            isNeedUpdateBalances: true,
            subgraphWaitUserData: false,
            isSponsoredRelayPossible: false,
            txPopulated: faucet.refuel.populateTransaction(tokenAmountIn),
            gasLimit: undefined,
            maxFeePerGas: this.maxFeePerGas,
            maxPriorityFeePerGas: this.maxPriorityFeePerGas,
            gasPrice: this.gasPrice,
            isDelegatedRelayPossible: false,
          }),
        );
      }),
    );
  }
}
