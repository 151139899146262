import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import { map, Observable, mergeMap } from 'rxjs';

export interface IStoryMeta {
  storyId: number;
  storyName: string;
  uri: string;
  biome: number;
}

const HEADERS = new HttpHeaders().set('Cache-Control', 'max-age=3600');
// .set('Cache-Control', 'no-cache, no-store, must-revalidate')
// .set('Pragma', 'no-cache')
// .set('Expires', '0');
@Injectable({
  providedIn: 'root',
})
export class LoadTextService {
  constructor(private httpClient: HttpClient) {}

  loadJson<T>(path: string): Observable<T> {
    return this.httpClient.get(path, { responseType: 'json', headers: HEADERS }).pipe(map(data => data as T));
  }

  loadText(path: string): Observable<string> {
    return this.httpClient.get(path, { responseType: 'text', headers: HEADERS });
  }

  loadAsBlob(path: string): Observable<Blob> {
    return this.httpClient.get(path, { responseType: 'blob', headers: HEADERS });
  }

  loadZipAndUnzip(path: string): Observable<string> {
    return this.httpClient.get(path, { responseType: 'blob', headers: HEADERS }).pipe(
      mergeMap(blob => {
        return this.unzipBlob(blob);
      }),
      map(data => {
        if (!data || data.length === 0) {
          throw new Error('No data found in zip file for path: ' + path);
        }
        return data[0];
      }),
    );
  }

  async unzipBlob(blob: Blob): Promise<string[]> {
    const zip = await JSZip.loadAsync(blob);

    const toExtract: Promise<string>[] = [];

    // Assuming you want to log the filenames for demonstration
    zip.forEach((relativePath, zipEntry) => {
      toExtract.push(zip.file(zipEntry.name)?.async('string') as Promise<string>);
    });

    return await Promise.all(toExtract);
  }
}
