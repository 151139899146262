/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  ShelterController,
  ShelterControllerInterface,
} from "../ShelterController";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "positionId",
        type: "uint256",
      },
    ],
    name: "AuctionBidOpened",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "positionId",
        type: "uint256",
      },
    ],
    name: "AuctionPositionOpened",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "biome",
        type: "uint256",
      },
    ],
    name: "ErrorIncorrectBiome",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ErrorNotDeployer",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorNotGuildController",
    type: "error",
  },
  {
    inputs: [],
    name: "ErrorPaused",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "FreeShelterItemsAreNotAllowed",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildAlreadyHasShelter",
    type: "error",
  },
  {
    inputs: [],
    name: "GuildHasNoShelter",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "shelterLevel",
        type: "uint8",
      },
    ],
    name: "IncorrectShelterLevel",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "LengthsMismatch",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numSoldItems",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "limit",
        type: "uint256",
      },
    ],
    name: "MaxNumberItemsSoldToday",
    type: "error",
  },
  {
    inputs: [],
    name: "NotGuildMember",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "NotShelterAuction",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "ShelterHasNotItem",
    type: "error",
  },
  {
    inputs: [],
    name: "ShelterIsBusy",
    type: "error",
  },
  {
    inputs: [],
    name: "ShelterIsNotOwnedByTheGuild",
    type: "error",
  },
  {
    inputs: [],
    name: "ShelterIsNotRegistered",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroValueNotAllowed",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guidlId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "BuyShelter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fromGuildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toGuildId",
        type: "uint256",
      },
    ],
    name: "ChangeShelterOwner",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "controller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ts",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "block",
        type: "uint256",
      },
    ],
    name: "ContractInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "LeaveShelter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "msgSender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "numSoldItems",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceInPvpPoints",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceInGameToken",
        type: "uint256",
      },
    ],
    name: "PurchaseShelterItem",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "sheleterId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "RegisterShelter",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "RevisionIncreased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint64[]",
        name: "pricesInPvpPoints",
        type: "uint64[]",
      },
      {
        indexed: false,
        internalType: "uint128[]",
        name: "pricesInGameTokens",
        type: "uint128[]",
      },
      {
        indexed: false,
        internalType: "uint16[]",
        name: "maxItemsPerDayThresholds",
        type: "uint16[]",
      },
    ],
    name: "SetShelterItems",
    type: "event",
  },
  {
    inputs: [],
    name: "CONTROLLABLE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "buyShelter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newOwnerGuildId",
        type: "uint256",
      },
    ],
    name: "changeShelterOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "clearShelter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "controller",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "created",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "createdBlock",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "uint32",
        name: "epochDay",
        type: "uint32",
      },
    ],
    name: "getCountPurchasedItems",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getShelterId",
    outputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "getShelterItemData",
    outputs: [
      {
        internalType: "uint64",
        name: "priceInPvpPoints",
        type: "uint64",
      },
      {
        internalType: "uint128",
        name: "priceInGameToken",
        type: "uint128",
      },
      {
        internalType: "uint16",
        name: "maxItemsPerDayLimit",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "getShelterItems",
    outputs: [
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "getShelterPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "biome",
        type: "uint8",
      },
    ],
    name: "getShelters",
    outputs: [
      {
        internalType: "uint256[]",
        name: "shelterIds",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    name: "guildToShelter",
    outputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "oldLogic",
        type: "address",
      },
    ],
    name: "increaseRevision",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "controller_",
        type: "address",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isController",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "value_",
        type: "address",
      },
    ],
    name: "isGovernance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "forwarder",
        type: "address",
      },
    ],
    name: "isTrustedForwarder",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "leaveShelter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "previousImplementation",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "item",
        type: "address",
      },
    ],
    name: "purchaseShelterItem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "revision",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "price",
        type: "uint256",
      },
    ],
    name: "setShelter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "items",
        type: "address[]",
      },
      {
        internalType: "uint64[]",
        name: "pricesInPvpPoints",
        type: "uint64[]",
      },
      {
        internalType: "uint128[]",
        name: "pricesInGameTokens",
        type: "uint128[]",
      },
      {
        internalType: "uint16[]",
        name: "maxItemsPerDayLimits",
        type: "uint16[]",
      },
    ],
    name: "setShelterItems",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "shelterId",
        type: "uint256",
      },
    ],
    name: "shelterToGuild",
    outputs: [
      {
        internalType: "uint256",
        name: "guildId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class ShelterController__factory {
  static readonly abi = _abi;
  static createInterface(): ShelterControllerInterface {
    return new Interface(_abi) as ShelterControllerInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): ShelterController {
    return new Contract(address, _abi, runner) as unknown as ShelterController;
  }
}
