<app-dialog-title
  [title]="'forge-page.title' | translate"
  [hasTitleScratch]="true"
  (closeDialog)="close()">
</app-dialog-title>

@if (loading) {
  <app-loading-small class="page-loader"></app-loading-small>
} @else {
  <div class="container g-flex-column__item g-scroll-y">
    <div class="items g-flex g-flex--align-center g-flex--justify-center">
      <div class="reagent g-flex-column">
        <div
          [class.app-text-warning]="targetItem?.itemId === 0"
          [class.app-text-warning--shadow]="targetItem?.itemId === 0">
          {{ 'forge-page.target' | translate }}
        </div>

        <app-item-slot
          class="item-slot g-flex"
          [slotItem]="targetItem"
          (clickEmit)="onItemSlotClick(targetItem)"
          slotSize="huge">
        </app-item-slot>
      </div>

      <div class="item-divider app-divider-group-of-bolts"></div>

      <div class="catalisator g-flex-column">
        <div
          [class.app-text-warning]="targetItem && targetItem.itemId > 0 && selectedCatalyst?.itemId === 0"
          [class.app-text-warning--shadow]="targetItem && targetItem.itemId > 0 && selectedCatalyst?.itemId === 0">
          {{ 'forge-page.catalyst' | translate }}
        </div>

        <app-item-slot
          class="item-slot g-flex"
          [slotItem]="selectedCatalyst"
          (clickEmit)="onItemSlotClick(selectedCatalyst)"
          slotSize="huge">
        </app-item-slot>
      </div>
    </div>

    <app-scratch
      class="scratch"
      backgroundType="huge">
    </app-scratch>

    <div class="desc2 app-btn-small_font-size color-caption">
      {{ 'forge-page.desc2' | translate }}
    </div>

    <app-scratch
      class="scratch"
      backgroundType="huge">
    </app-scratch>

    <div class="g-flex-column">
      @if (selectedCatalyst && selectedCatalyst.itemId > 0) {
        <div class="title g-flex g-flex--justify-center app-btn_font-size">
          <div class="title__name">
            {{ 'forge-page.chance' | translate }}
          </div>

          <div class="app-text-warning app-text-warning--shadow">70%</div>
        </div>

        <div class="title g-flex g-flex--justify-center app-btn_font-size">
          <div class="title__name">
            {{ 'forge-page.get' | translate }}
          </div>

          <div class="app-text-warning app-text-warning--shadow">20%</div>
        </div>

        <div class="buttons g-flex g-flex--align-center g-flex--justify-center gap-80">
          @if (!isEnoughAllowance) {
            <button
              class="btn-repair app-btn-red-3 g-flex g-flex--align-center g-flex--justify-center"
              appButtonClick
              [isDisabled]="transactionLoading"
              (hostClick)="approveFeeToken()">
              <div>
                {{ 'forge-page.buttons.approve' | translate }}
              </div>

              <img
                class="balance__icon shadow__icon"
                [src]="
                  'assets/images/ui/icons/tokens/' + targetItem?.meta?.feeToken?.token?.symbol?.toLowerCase() + '.png'
                "
                alt="" />
            </button>
          } @else {
            <button
              class="btn-repair app-btn-red-3"
              appButtonClick
              [isDisabled]="transactionLoading || !isEnoughBalance"
              (hostClick)="augment()">
              {{ 'forge-page.buttons.augment' | translate }}
            </button>
          }

          <button
            class="app-btn-blue-2 g-flex g-flex--align-center g-flex--justify-center"
            (click)="resetForge()">
            {{ 'forge-page.buttons.reset' | translate }}
          </button>
        </div>

        <div class="cost g-flex g-flex--align-center g-flex--justify-center">
          <span>{{ 'forge-page.cost' | translate }}</span>

          @if (targetItem && targetItem.meta.feeToken) {
            <app-balance
              class="cost__value"
              [isReverse]="false"
              [token]="targetItem.meta.feeToken.token.id"
              [balance]="targetItem.meta.feeToken.amount"
              [isShowBalanceUsd]="true">
            </app-balance>
          }

          <button
            class="btn-buy app-btn-silver-2"
            appButtonClick
            [isDisabled]="transactionLoading"
            (hostClick)="buyTokens()">
            {{ 'forge-page.buttons.buy-coins' | translate }}
          </button>
        </div>
      } @else {
        <div class="title g-flex g-flex--space-between">
          <div>{{ 'forge-page.catalyst' | translate }}</div>

          <div>
            <span class="app-text-warning app-text-warning--shadow">{{ catalystsItems.length }}</span>
            {{ 'forge-page.available' | translate }}
          </div>
        </div>

        <div class="g-flex-column g-scroll-y">
          @for (item of catalystsItems; track item) {
            <app-item-card
              class="item"
              [item]="item"
              (selectItem)="selectItem(item)"
              (selectItemImg)="selectItem(item)">
            </app-item-card>
          }
        </div>
      }
    </div>
  </div>
}
