import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { decrypt } from '@helpers/crypt';
import { getMainDomain, unpackStoryAnswerId } from '@helpers/data-helper';
import { StoryResult, StoryTextInfo } from '@models/story/story-text';
import { LoadTextService } from '@services/data/load-text.service';
import { StoryService } from '@services/onchain/story.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, forkJoin, of, map, mergeMap, tap } from 'rxjs';

const API_SERVER_URL = environment['API_SERVER_URL'] ?? 'https://sonict-server.' + getMainDomain();
const REFUEL_SERVER_URL = environment['REFUEL_SERVER_URL'] ?? 'https://faucet.' + getMainDomain();
const STORY_SHIFT = Number(environment['STORY_SHIFT'] ?? '599999999999');
const USE_STORY_API = (environment['USE_STORY_API'] ?? 'false') === 'true';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private loadTextService: LoadTextService,
    private storyService: StoryService,
    private logger: NGXLogger,
  ) {}

  getCurrentHeroPage(
    heroAdr: string,
    heroId: number,
    storyId: number,
    storyPage: number,
    chainId: number,
    checkAnswers = true,
  ): Observable<StoryResult> {
    if (USE_STORY_API) {
      const params = new HttpParams()
        .set('heroId', heroId)
        .set('heroAdr', heroAdr)
        .set('storyId', storyId)
        .set('storyPage', storyPage)
        .set('timestamp', String(new Date().getTime()));

      this.logger.trace('get CurrentHeroPage', API_SERVER_URL + 'stories', heroAdr, heroId);
      return this.http.get<StoryResult>(API_SERVER_URL + 'stories', {
        headers: {},
        params,
      });
    } else {
      // this.storyService.currentHeroAnswers$(storyId, heroAdr, heroId, chainId).subscribe(answ => {
      //   this.logger.trace('CUR ANSWERS', answ);
      // });

      return forkJoin([
        this.storyService.storyBuildHash$(storyId, chainId),
        this.storyService.currentHeroAnswers$(storyId, heroAdr, heroId, chainId),
      ]).pipe(
        mergeMap(([buildId, answers]) => {
          const pathCurrent = `assets/story-texts/story-texts-export/story_${storyId}.zip`;
          const pathOld = `assets/story-texts/story-texts-export-old/story_${storyId}.zip`;

          return this.loadText(pathCurrent, storyId, buildId, answers, storyPage, checkAnswers).pipe(
            mergeMap(info => {
              if (!info) {
                console.log('NO INFO from default texts for this build, try to use old texts');
                return this.loadText(pathOld, storyId, buildId, answers, storyPage, checkAnswers);
              } else {
                return of(info);
              }
            }),

            tap(info => {
              if (!info) {
                throw Error(
                  `We do not have correct info for this story(${storyId}/${storyPage}). Please try to reload this page or contact us in Discord.`,
                );
              }
            }),
            map(s => {
              return {
                storyId: s?.storyId,
                storyName: s?.storyName,
                uri: s?.uri,
                info: s?.pages?.find(p => p.pageId === storyPage),
              };
            }),
          );
        }),
      );
    }
  }

  refuel(account: string) {
    const params = new HttpParams().set('address', account);

    this.logger.trace('refuel', REFUEL_SERVER_URL + '/refuel', account);

    return this.http.get<{ status: string }>(REFUEL_SERVER_URL + '/refuel', {
      headers: {},
      params,
    });
  }

  loadText(
    textUri: string,
    storyId: number,
    buildId: bigint | undefined,
    expectedAnswers: string[],
    storyPage: number,
    checkAnswers = true,
  ) {
    expectedAnswers = expectedAnswers.map(s => s.toLowerCase());
    return this.loadTextService.loadZipAndUnzip(textUri).pipe(
      map(storyTexts => JSON.parse(decrypt(storyTexts, STORY_SHIFT)) as StoryTextInfo),
      // tap(texts => this.logger.trace(buildId, textUri, 'texts', texts)),
      map(storyTexts =>
        [storyTexts].find(s => {
          const isCorrectStory =
            s.storyId === storyId && (buildId === undefined || s.buildHash === buildId?.toString());
          if (!isCorrectStory) {
            return false;
          }
          const page = s.pages.find(p => p.pageId === storyPage);
          if (!page) {
            console.error('Story does not have expected page');
            return false;
          }

          const pageAnswers = page.answers.map(a => a.answerIdHash.toLowerCase());

          if (checkAnswers) {
            for (const a of expectedAnswers) {
              if (!pageAnswers.includes(a)) {
                console.error('Page does not have expected answer', a, unpackStoryAnswerId(a));
                return false;
              }
            }
          }
          return true;
        }),
      ),
    );
  }
}
