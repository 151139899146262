/* eslint-disable */
import { CoreAddressesModel } from '@models/core-addresses.model';
import {
  SONIC_CORE,
  SONIC_ITEMS,
  SONIC_POOLS,
  SONIC_TOKEN_INFO,
} from '@shared/constants/addresses/sonic.constants';
import { genObjectId } from '@shared/utils';
import { SONIC_AIRDROP_VALUES } from '@shared/constants/addresses/data/sonic-airdrop-values';
import { ALL_IMAGES } from '@shared/constants/images';
import { TetuGame } from '@shared/constants/addresses/TetuGame';
import {
  FANTOM_CORE,
  FANTOM_DEX,
  FANTOM_ITEMS,
  FANTOM_POOLS,
  FANTOM_TOKEN_INFO,
} from '@shared/constants/addresses/fantom.constants';
import { FANTOM_AIRDROP_VALUES } from '@shared/constants/addresses/data/fantom-airdrop-values';
import { PoolAddressesModel } from '@models/pool-addresses.model';
import {
  REAL_CORE,
  REAL_DEX,
  REAL_ITEMS,
  REAL_POOLS,
  REAL_TOKEN_INFO,
} from '@shared/constants/addresses/real.constants';
import { CHAIN_IDS } from '@shared/constants/chain-ids.constant';
import {
  getNebulaFaucetByToken,
  NEBULA_TESTNET_CORE,
  NEBULA_TESTNET_DEX, NEBULA_TESTNET_ITEMS,
  NEBULA_TESTNET_POOLS, NEBULA_TESTNET_TOKEN_INFO,
} from '@shared/constants/addresses/nebula-testnet.constants';

export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
export const NETWORK_COIN = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ZERO_ID = '0';

export type TokenInfo = {
  symbol: string;
  decimals: number;
};

const ADDRESSES_CORE_MAP = new Map<number, CoreAddressesModel>([
  [CHAIN_IDS.SONIC, SONIC_CORE],
  [CHAIN_IDS.FANTOM, FANTOM_CORE],
  [CHAIN_IDS.REAL, REAL_CORE],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_CORE],
]);

const ADDRESSES_POOL_MAP = new Map<number, PoolAddressesModel>([
  [CHAIN_IDS.SONIC, SONIC_POOLS],
  [CHAIN_IDS.FANTOM, FANTOM_POOLS],
  [CHAIN_IDS.REAL, REAL_POOLS],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_POOLS],
]);

const ADDRESSES_DEX_MAP = new Map<number, { [str: string]: string }>([
  [CHAIN_IDS.FANTOM, FANTOM_DEX],
  // [CHAIN_IDS.SONIC, SONIC_DEX],
  [CHAIN_IDS.REAL, REAL_DEX],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_DEX],
]);

const ADDRESSES_ITEMS_MAP = new Map<number, any>([
  [CHAIN_IDS.SONIC, SONIC_ITEMS],
  [CHAIN_IDS.FANTOM, FANTOM_ITEMS],
  [CHAIN_IDS.REAL, REAL_ITEMS],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_ITEMS],
]);
const AIRDROP_MAP = new Map<number, any>([
  [CHAIN_IDS.SONIC, SONIC_AIRDROP_VALUES],
  [CHAIN_IDS.FANTOM, FANTOM_AIRDROP_VALUES],
  [CHAIN_IDS.REAL, []],
  [CHAIN_IDS.NEBULA_TESTNET, []],
]);

const FAUCETS_MAP = new Map<number, (token: string) => string>([
  [CHAIN_IDS.NEBULA_TESTNET, getNebulaFaucetByToken],
]);

const TOKEN_INFO_MAP = new Map<number, Map<string, TokenInfo>>([
  [CHAIN_IDS.SONIC, SONIC_TOKEN_INFO],
  [CHAIN_IDS.FANTOM, FANTOM_TOKEN_INFO],
  [CHAIN_IDS.REAL, REAL_TOKEN_INFO],
  [CHAIN_IDS.NEBULA_TESTNET, NEBULA_TESTNET_TOKEN_INFO],
]);

const AVAILABLE_POOLS = new Map<number, boolean>([
  [CHAIN_IDS.SONIC, false],
  [CHAIN_IDS.FANTOM, true],
  [CHAIN_IDS.REAL, true],
  [CHAIN_IDS.NEBULA_TESTNET, false],
]);

const MAX_TRADE_BATCH = new Map<number, number>([
  [CHAIN_IDS.FANTOM, 30],
  [CHAIN_IDS.SONIC, 30],
  [CHAIN_IDS.REAL, 30],
  [CHAIN_IDS.NEBULA_TESTNET, 200],
]);

export function isSimpleTestNetwork(chainId: number) {
  return (
    chainId === CHAIN_IDS.SONIC || chainId === CHAIN_IDS.NEBULA_TESTNET
  );
}

export function getMaxGasLimit(chainId: number) {
  return 3_500_000n;
}

export function getMaxGasLimitExtended(chainId: number) {
  return 6_000_000n;
}

export const GET_CORE_ADDRESSES = (chainId: number) => {
  const core = ADDRESSES_CORE_MAP.get(chainId);

  if (!core) {
    throw Error('No config for ' + chainId);
  }

  return core;
};

export const GET_POOL_ADDRESSES = (chainId: number) => {
  const pool = ADDRESSES_POOL_MAP.get(chainId);

  if (!pool) {
    throw Error('No config for ' + chainId);
  }

  return pool;
};

export const GET_DEX_ADDRESSES = (chainId: number) => {
  const dex = ADDRESSES_DEX_MAP.get(chainId);

  if (!dex) {
    throw Error('No DEX config for ' + chainId);
  }

  return dex;
};

export const GET_ITEM_ADDRESSES = (chainId: number) => {
  const items = ADDRESSES_ITEMS_MAP.get(chainId);

  if (!items) {
    throw Error('No config for ' + chainId);
  }

  const map = new Map<string, string>();

  for (const key of Object.keys(items)) {
    map.set(key, items[key]);
  }

  return map;
};

export const getFaucetForToken = (token: string, chainId: number) => {
  const core = FAUCETS_MAP.get(chainId);

  if (!core) {
    throw Error('No config for ' + chainId);
  }

  return core(token);
};

export const IS_POOL_AVAILABLE_CHAIN = (chainId: number) => {
  return AVAILABLE_POOLS.get(chainId) ?? false;
};

export const GET_MAX_TRADE_BATCH = (chainId: number) => {
  return MAX_TRADE_BATCH.get(chainId) ?? 10;
};

export const GET_TOKEN_INFO = (chainId: number, tokenAdr: string) => {
  const core = TOKEN_INFO_MAP.get(chainId);

  if (!core) {
    throw Error('No config for ' + chainId);
  }

  return core.get(tokenAdr.toLowerCase()) ?? { symbol: 'UNKNOWN', decimals: 0 };
};

export const GET_AIRDROP_INFO = (chainId: number) => {
  const result = AIRDROP_MAP.get(chainId);

  if (!result) {
    throw Error('No airdrop info for ' + chainId);
  }

  return result;
};

export function monsterIdToName(id: number) {
  for (const key of Object.keys(TetuGame.MONSTERS)) {
    if (TetuGame.MONSTERS[key] === id) {
      return key;
    }
  }
  throw new Error('No monster found for id: ' + id);
}

export function storyIdToName(id: number) {
  for (const key of Object.keys(TetuGame.STORIES)) {
    if (TetuGame.STORIES[key] === id) {
      return key;
    }
  }
  throw new Error('No story found for id: ' + id);
}

export function shrineIdToName(id: number) {
  for (const key of Object.keys(TetuGame.SHRINES)) {
    if (TetuGame.SHRINES[key] === id) {
      return key;
    }
  }
  throw new Error('No story found for id: ' + id);
}

export function getDungeonNames() {
  const result: string[] = [];
  for (const key of Object.keys(TetuGame.DUNGEONS)) {
    result.push(key.toString());
  }
  return result;
}

export function getMonsterNames() {
  const result: string[] = [];
  for (const key of Object.keys(TetuGame.MONSTERS)) {
    result.push(key.toString());
  }
  return result;
}

export function getStoryNames() {
  const result: string[] = [];
  for (const key of Object.keys(TetuGame.STORIES)) {
    result.push(key.toString());
  }
  return result;
}

export function getShrineNames() {
  const result: string[] = [];
  for (const key of Object.keys(TetuGame.SHRINES)) {
    result.push(key.toString());
  }
  return result;
}

export function checkAllDungeonImages() {
  const names = getDungeonNames();
  for (const n of names) {
    geUriForName(getDungeonNameForUri(n));
  }
}

export function checkAllMonsterImages() {
  const names = getMonsterNames();
  for (const n of names) {
    geUriForName(getMonsterNameForUri(n));
  }
  for (const key of Object.keys(TetuGame.MONSTERS)) {
    const id = TetuGame.MONSTERS[key];
    geUriForName(getMonsterNameForUri(monsterIdToName(id)));
  }
}

export function checkAllStoryImages() {
  const names = getStoryNames();
  for (const n of names) {
    geUriForName(getStoryNameForUri(n));
  }
  for (const key of Object.keys(TetuGame.STORIES)) {
    const id = TetuGame.STORIES[key];
    geUriForName(getStoryNameForUri(storyIdToName(id)));
  }
}

export function checkAllShrineImages() {
  const names = getShrineNames();
  for (const n of names) {
    geUriForName(getShrineNameForUri(n));
  }
  for (const key of Object.keys(TetuGame.SHRINES)) {
    const id = TetuGame.SHRINES[key];
    geUriForName(getShrineNameForUri(shrineIdToName(id)));
  }
}

export function geUriForName(name: string) {
  let img = ALL_IMAGES[name];
  if (!img) {
    img = ALL_IMAGES[name + '_DRAFT'];
  }
  if (!img) {
    img = ALL_IMAGES[name + '_GEN'];
  }
  if (!img) {
    img = ALL_IMAGES[name + '_REUSE'];
  }
  if (!img) {
    throw new Error('No image found for: ' + name);
  }
  return img;
}

export function getDungeonNameForUri(name: string) {
  const base = name
    .replace('BIOME_1_', '')
    .replace('BIOME_2_', '')
    .replace('BIOME_3_', '')
    .replace('BIOME_4_', '')
    .replace('ALIVE_AGAIN_1_F2P', 'ALIVE_AGAIN_F2P')
    .replace('HAMMERGINA', 'GRIMLARNS_HOSPITALITY');
  const split = base.split('_');
  if (Number.isInteger(Number(split[split.length - 1]))) {
    return split.slice(0, -1).join('_');
  } else {
    return base;
  }
}

export function getMonsterNameForUri(name: string) {
  const base = name
    .replace('MOCK1', 'DRUNK_CROSSBOWMAN')
    .replace('MOCK2', 'DRUNK_CROSSBOWMAN')
    .replace('MOCK3', 'DRUNK_CROSSBOWMAN')
    .replace('BARBARIAN_BITCH', 'SAVAGE')
    .replace('RESTLESS_SPIRIT', 'GHOST')
    .replace('HANGMANS_PATROL_F2P', 'HANGMANS_PATROL')
    .replace('A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P', 'A_LITTLE_MORE_BANDITS_THAN_USUAL')
    .replace('REBELLIOUS_PEASANT_F2P', 'REBELLIOUS_PEASANT')
    .replace('DRUNK_CROSSBOWMAN_F2P', 'DRUNK_CROSSBOWMAN')
    .replace('SOMBER_HANGMAN_F2P', 'SOMBER_HANGMAN')
    .replace('FILTHY_WILLY_F2P', 'FILTHY_WILLY');
  const split = base.split('_');
  if (Number.isInteger(Number(split[split.length - 1]))) {
    return split.slice(0, -1).join('_');
  } else {
    return base;
  }
}

export function getStoryNameForUri(name: string) {
  const base = name
    .replace('STORY133_STRANGER_II', 'STORY102_STRANGER')
    .replace('STORY134_STRANGER_III', 'STORY102_STRANGER')
    .replace('STORY135_STRANGER_IV', 'STORY102_STRANGER')
    .replace('STORY136_POSTMAN_II', 'STORY131_POSTMAN')
    .replace('STORY439_POSTMAN_III', 'STORY131_POSTMAN')
    .replace('STORY233_IN_FROM_COLD_II', 'STORY232_IN_FROM_COLD')
    .replace('STORY433_BLOOD_AND_HONOR_II', 'STORY428_BLOOD_AND_HONOR')
    .replace('STORY434_BLOOD_AND_HONOR_III', 'STORY428_BLOOD_AND_HONOR')
    .replace('STORY438_FREEDOM_FIGHT_V', 'STORY427_FREEDOM_FIGHT');
  const split = base.split('_');
  if (Number.isInteger(Number(split[split.length - 1]))) {
    return split.slice(0, -1).join('_');
  } else {
    return base;
  }
}

export function getShrineNameForUri(name: string) {
  const base = name
    .replace('BIOME_3_HEAL_SHRINE', 'BIOME_2_HEAL_SHRINE')
    .replace('BIOME_3_FIGHT_SHRINE', 'BIOME_2_FIGHT_SHRINE')
    .replace('BIOME_3_LUCK_SHRINE', 'BIOME_2_LUCK_SHRINE')
    .replace('BIOME_3_MAGIC_SHRINE', 'BIOME_2_MAGIC_SHRINE')
    .replace('BIOME_3_RESIST_SHRINE', 'BIOME_2_RESIST_SHRINE')
    .replace('BIOME_4_HEAL_SHRINE', 'BIOME_2_HEAL_SHRINE')
    .replace('BIOME_4_FIGHT_SHRINE', 'BIOME_2_FIGHT_SHRINE')
    .replace('BIOME_4_LUCK_SHRINE', 'BIOME_2_LUCK_SHRINE')
    .replace('BIOME_4_MAGIC_SHRINE', 'BIOME_2_MAGIC_SHRINE')
    .replace('BIOME_4_RESIST_SHRINE', 'BIOME_2_RESIST_SHRINE')
    .replace('BIOME_4_LC_SHRINE', 'BIOME_3_LC_SHRINE');
  const split = base.split('_');
  if (Number.isInteger(Number(split[split.length - 1]))) {
    return split.slice(0, -1).join('_');
  } else {
    return base;
  }
}

export const MONSTER_OBJECTS = [
  genObjectId(1, 34, 1),
  genObjectId(1, 34, 2),
  genObjectId(1, 34, 3),
  genObjectId(1, 34, 4),
  genObjectId(1, 34, 5),
  genObjectId(1, 32, 6),
  genObjectId(1, 32, 7),
  genObjectId(1, 32, 8),
  genObjectId(1, 32, 9),
  genObjectId(1, 35, 10),
  genObjectId(1, 35, 11),
  genObjectId(1, 10, 12),
  genObjectId(1, 33, 13),
  genObjectId(1, 2, 14),
  genObjectId(1, 35, 15),
  genObjectId(1, 3, 16),
  genObjectId(1, 10, 17),
  genObjectId(1, 10, 18),
  genObjectId(1, 10, 19),
  genObjectId(1, 10, 20),
  genObjectId(1, 10, 21),
  genObjectId(1, 10, 22),
  genObjectId(1, 10, 23),
  genObjectId(1, 32, 24),
  genObjectId(1, 34, 25),
  genObjectId(1, 10, 26),
  genObjectId(1, 10, 27),
  genObjectId(1, 10, 28),
  genObjectId(1, 10, 29),
  genObjectId(1, 10, 30),
  genObjectId(1, 10, 31),
  genObjectId(1, 10, 32),
  genObjectId(1, 10, 33),
  genObjectId(1, 10, 34),
  genObjectId(1, 10, 35),
  genObjectId(1, 10, 36),
  genObjectId(1, 10, 37),

  genObjectId(2, 34, 1),
  genObjectId(2, 34, 2),
  genObjectId(2, 34, 3),
  genObjectId(2, 34, 4),
  genObjectId(2, 34, 5),
  genObjectId(2, 32, 6),
  genObjectId(2, 32, 7),
  genObjectId(2, 32, 8),
  genObjectId(2, 32, 9),
  genObjectId(2, 35, 10),
  genObjectId(2, 35, 11),
  genObjectId(2, 33, 12),
  genObjectId(2, 33, 13),
  genObjectId(2, 10, 14),
  genObjectId(2, 10, 15),
  genObjectId(2, 3, 16),
  genObjectId(2, 10, 17),

  genObjectId(3, 34, 1),
  genObjectId(3, 34, 2),
  genObjectId(3, 34, 3),
  genObjectId(3, 34, 4),
  genObjectId(3, 34, 5),
  genObjectId(3, 35, 6),
  genObjectId(3, 35, 7),
  genObjectId(3, 35, 8),
  genObjectId(3, 10, 9),
  genObjectId(3, 10, 10),
  genObjectId(3, 10, 11),
  genObjectId(3, 34, 12),
  genObjectId(3, 10, 13),
  genObjectId(3, 3, 14),
  genObjectId(3, 10, 15),

  genObjectId(4, 34, 1),
  genObjectId(4, 34, 2),
  genObjectId(4, 34, 3),
  genObjectId(4, 34, 4),
  genObjectId(4, 34, 5),
  genObjectId(4, 35, 6),
  genObjectId(4, 35, 7),
  genObjectId(4, 10, 8),
  genObjectId(4, 35, 9),
  genObjectId(4, 35, 10),
  genObjectId(4, 34, 11),
  genObjectId(4, 34, 12),
  genObjectId(4, 10, 13),
  genObjectId(4, 10, 14),
  genObjectId(4, 10, 15),
  genObjectId(4, 10, 16),
  genObjectId(4, 10, 17),
  genObjectId(4, 35, 18),
  genObjectId(4, 3, 19),
  genObjectId(4, 10, 20),
];
