import { genObjectId } from '@shared/utils';

import {
  DungeonsType,
  HeroesAddressesType,
  ItemAddressesType,
  MonstersAddressesType,
  SpecificChambers,
  StoriesType,
} from './AddressesTypes';

export const ITEM_PREFIX = 'SACRA_';
export const ITEM_POSTFIX = '_ITEM';
export const HERO_PREFIX = 'SACRA_';
export const HERO_POSTFIX = '_HERO';
export const MONSTER_PREFIX = 'SACRA_';
export const MONSTER_POSTFIX = '_MONSTER';
export const SHRINE_PREFIX = 'SACRA_';
export const SHRINE_POSTFIX = '_SHRINE';

export class TetuGame {
  public static DUNGEONS: DungeonsType = {
    BIOME_1_ALIVE_AGAIN_1_F2P: 100,
    BIOME_1_ALIVE_AGAIN_1: 101,
    BIOME_1_DEPTHS_OF_THE_FOREST_2: 102,
    BIOME_1_LOF_BLAID_3: 103,
    BIOME_1_OUTSKIRTS_OF_THE_CITY_4: 104,
    BIOME_1_DESPERATION_5: 105,
    BIOME_1_NIGHT_FOREST_6: 106,
    BIOME_1_ANOTHER_DAY_7: 107,
    BIOME_1_PILGRIMAGE_8: 108,
    BIOME_1_THIS_IS_RIDICULOUS_9: 109,
    BIOME_1_BLOOD_TEARS_10: 110,
    BIOME_1_DANGEROUS_TRAILS_11: 111,
    BIOME_1_GRIMLARNS_HOSPITALITY_12: 112,
    BIOME_1_FORGOTTEN_LAND_13: 113,
    BIOME_1_SACRA_HUNT_14: 114,
    BIOME_1_IN_THE_WILD_15: 115,
    BIOME_1_ABANDONED_TRACT_16: 116,
    BIOME_1_TERRITORY_OF_BANDITS_17: 117,
    BIOME_1_BURNED_VILLAGES_18: 118,
    BIOME_1_HAMMERGINA_119: 119,

    BIOME_2_DEPTHS_OF_THE_FOREST_2: 202,
    BIOME_2_LOF_BLAID_3: 203,
    BIOME_2_OUTSKIRTS_OF_THE_CITY_4: 204,
    BIOME_2_DESPERATION_5: 205,
    BIOME_2_NIGHT_FOREST_6: 206,
    BIOME_2_ANOTHER_DAY_7: 207,
    BIOME_2_PILGRIMAGE_8: 208,
    BIOME_2_THIS_IS_RIDICULOUS_9: 209,
    BIOME_2_BLOOD_TEARS_10: 210,
    BIOME_2_DANGEROUS_TRAILS_11: 211,
    BIOME_2_GRIMLARNS_HOSPITALITY_12: 212,
    BIOME_2_FORGOTTEN_LAND_13: 213,
    BIOME_2_SACRA_HUNT_14: 214,
    BIOME_2_ABANDONED_TRACT_16: 216,
    BIOME_2_TERRITORY_OF_BANDITS_17: 217,
    BIOME_2_BURNED_VILLAGES_18: 218,

    BIOME_3_WAYS_OF_THE_DROWNED: 301,
    BIOME_3_TRAPS_ARE_EVERYWHERE: 302,
    BIOME_3_FEED_THE_MOTHER: 303,
    BIOME_3_GREAT_EXODUS: 304,
    BIOME_3_LAST_SPARKS: 305,
    BIOME_3_GROVE_OF_GIANTS: 306,
    BIOME_3_CREEPY_PLACE: 307,
    BIOME_3_WHAT_DOES_THE_THICK_FOG_HIDE: 308,
    BIOME_3_ITS_GETTING_COLDER: 309,
    BIOME_3_FORGOTTEN_FAITH: 310,

    BIOME_4_LIFE_ROAD: 401,
    BIOME_4_THREAT: 402,
    BIOME_4_BONES: 403,
    BIOME_4_ATTRACTIONS_OF_THE_ONALPHOE: 404,
    BIOME_4_GLOW: 405,
    BIOME_4_A_DAY_FULL_OF_HOPE: 406,
    BIOME_4_CULT: 407,
    BIOME_4_FORMER_GREATNESS: 408,
    BIOME_4_SPIDER_SEASON: 409,
    BIOME_4_UNPLEASANT_BUZZING: 410,
  };

  public static HEROES: HeroesAddressesType = {
    THRALL: HERO_PREFIX + 'THRALL' + HERO_POSTFIX,
    SAVAGE: HERO_PREFIX + 'SAVAGE' + HERO_POSTFIX,
    MAGE: HERO_PREFIX + 'MAGE' + HERO_POSTFIX,
    ASSASSIN: HERO_PREFIX + 'ASSASSIN' + HERO_POSTFIX,
    GHOST: HERO_PREFIX + 'GHOST' + HERO_POSTFIX,
    HAMMERGINA: HERO_PREFIX + 'HAMMERGINA' + HERO_POSTFIX,
  };

  public static ITEMS: ItemAddressesType = {
    CONS_1: ITEM_PREFIX + 'CONS_1' + ITEM_POSTFIX,
    CONS_2: ITEM_PREFIX + 'CONS_2' + ITEM_POSTFIX,
    CONS_3: ITEM_PREFIX + 'CONS_3' + ITEM_POSTFIX,
    CONS_4: ITEM_PREFIX + 'CONS_4' + ITEM_POSTFIX,
    CONS_17: ITEM_PREFIX + 'CONS_17' + ITEM_POSTFIX,
    CONS_18: ITEM_PREFIX + 'CONS_18' + ITEM_POSTFIX,
    CONS_19: ITEM_PREFIX + 'CONS_19' + ITEM_POSTFIX,
    CONS_20: ITEM_PREFIX + 'CONS_20' + ITEM_POSTFIX,
    CONS_21: ITEM_PREFIX + 'CONS_21' + ITEM_POSTFIX,
    CONS_27: ITEM_PREFIX + 'CONS_27' + ITEM_POSTFIX,
    CONS_28: ITEM_PREFIX + 'CONS_28' + ITEM_POSTFIX,
    CONS_29: ITEM_PREFIX + 'CONS_29' + ITEM_POSTFIX,
    CONS_30: ITEM_PREFIX + 'CONS_30' + ITEM_POSTFIX,
    CONS_31: ITEM_PREFIX + 'CONS_31' + ITEM_POSTFIX,
    CONS_32: ITEM_PREFIX + 'CONS_32' + ITEM_POSTFIX,
    CONS_33: ITEM_PREFIX + 'CONS_33' + ITEM_POSTFIX,
    CONS_34: ITEM_PREFIX + 'CONS_34' + ITEM_POSTFIX,
    CONS_35: ITEM_PREFIX + 'CONS_35' + ITEM_POSTFIX,
    // CONS_36: ITEM_PREFIX + 'CONS_36' + ITEM_POSTFIX,
    // CONS_37: ITEM_PREFIX + 'CONS_37' + ITEM_POSTFIX,
    // CONS_38: ITEM_PREFIX + 'CONS_38' + ITEM_POSTFIX,
    // CONS_39: ITEM_PREFIX + 'CONS_39' + ITEM_POSTFIX,
    // CONS_40: ITEM_PREFIX + 'CONS_40' + ITEM_POSTFIX,
    // CONS_41: ITEM_PREFIX + 'CONS_41' + ITEM_POSTFIX,
    // CONS_42: ITEM_PREFIX + 'CONS_42' + ITEM_POSTFIX,
    // CONS_43: ITEM_PREFIX + 'CONS_43' + ITEM_POSTFIX,
    HELM_1: ITEM_PREFIX + 'HELM_1' + ITEM_POSTFIX,
    HELM_6: ITEM_PREFIX + 'HELM_6' + ITEM_POSTFIX,
    HELM_9: ITEM_PREFIX + 'HELM_9' + ITEM_POSTFIX,
    BODY_1: ITEM_PREFIX + 'BODY_1' + ITEM_POSTFIX,
    GLOVES_1: ITEM_PREFIX + 'GLOVES_1' + ITEM_POSTFIX,
    GLOVES_6: ITEM_PREFIX + 'GLOVES_6' + ITEM_POSTFIX,
    BELT_1: ITEM_PREFIX + 'BELT_1' + ITEM_POSTFIX,
    BELT_5: ITEM_PREFIX + 'BELT_5' + ITEM_POSTFIX,
    AMULET_1: ITEM_PREFIX + 'AMULET_1' + ITEM_POSTFIX,
    AMULET_2: ITEM_PREFIX + 'AMULET_2' + ITEM_POSTFIX,
    AMULET_12: ITEM_PREFIX + 'AMULET_12' + ITEM_POSTFIX,
    AMULET_13: ITEM_PREFIX + 'AMULET_13' + ITEM_POSTFIX,
    RING_1: ITEM_PREFIX + 'RING_1' + ITEM_POSTFIX,
    RING_2: ITEM_PREFIX + 'RING_2' + ITEM_POSTFIX,
    RING_9: ITEM_PREFIX + 'RING_9' + ITEM_POSTFIX,
    OFF_1: ITEM_PREFIX + 'OFF_1' + ITEM_POSTFIX,
    OFF_2: ITEM_PREFIX + 'OFF_2' + ITEM_POSTFIX,
    OFF_10: ITEM_PREFIX + 'OFF_10' + ITEM_POSTFIX,
    BOOTS_1: ITEM_PREFIX + 'BOOTS_1' + ITEM_POSTFIX,
    BOOTS_5: ITEM_PREFIX + 'BOOTS_5' + ITEM_POSTFIX,
    OHW_1: ITEM_PREFIX + 'OHW_1' + ITEM_POSTFIX,
    OHW_2: ITEM_PREFIX + 'OHW_2' + ITEM_POSTFIX,
    OHW_3: ITEM_PREFIX + 'OHW_3' + ITEM_POSTFIX,
    THW_1: ITEM_PREFIX + 'THW_1' + ITEM_POSTFIX,
    THW_2: ITEM_PREFIX + 'THW_2' + ITEM_POSTFIX,
    FEAR: ITEM_PREFIX + 'FEAR' + ITEM_POSTFIX,
    DEMON_SPEED: ITEM_PREFIX + 'DEMON_SPEED' + ITEM_POSTFIX,
    BRUTE_FORCE: ITEM_PREFIX + 'BRUTE_FORCE' + ITEM_POSTFIX,
    SAVAGE_CHARM: ITEM_PREFIX + 'SAVAGE_CHARM' + ITEM_POSTFIX,
    GOLDEN_EYE: ITEM_PREFIX + 'GOLDEN_EYE' + ITEM_POSTFIX,
    SABBAH_POISON: ITEM_PREFIX + 'SABBAH_POISON' + ITEM_POSTFIX,
    SPIT_IN_FACE: ITEM_PREFIX + 'SPIT_IN_FACE' + ITEM_POSTFIX,
    ONSLAUGHT: ITEM_PREFIX + 'ONSLAUGHT' + ITEM_POSTFIX,
    STRONG_BLOCK: ITEM_PREFIX + 'STRONG_BLOCK' + ITEM_POSTFIX,
    BEEF_PATTY: ITEM_PREFIX + 'BEEF_PATTY' + ITEM_POSTFIX,
    HEAT_STRIKE: ITEM_PREFIX + 'HEAT_STRIKE' + ITEM_POSTFIX,
    STATIC_DISCHARGE: ITEM_PREFIX + 'STATIC_DISCHARGE' + ITEM_POSTFIX,

    CONS_5: ITEM_PREFIX + 'CONS_5' + ITEM_POSTFIX,
    CONS_6: ITEM_PREFIX + 'CONS_6' + ITEM_POSTFIX,
    CONS_7: ITEM_PREFIX + 'CONS_7' + ITEM_POSTFIX,
    CONS_8: ITEM_PREFIX + 'CONS_8' + ITEM_POSTFIX,
    CONS_22: ITEM_PREFIX + 'CONS_22' + ITEM_POSTFIX,
    HELM_2: ITEM_PREFIX + 'HELM_2' + ITEM_POSTFIX,
    HELM_7: ITEM_PREFIX + 'HELM_7' + ITEM_POSTFIX,
    BODY_2: ITEM_PREFIX + 'BODY_2' + ITEM_POSTFIX,
    BODY_6: ITEM_PREFIX + 'BODY_6' + ITEM_POSTFIX,
    BODY_8: ITEM_PREFIX + 'BODY_8' + ITEM_POSTFIX,
    GLOVES_2: ITEM_PREFIX + 'GLOVES_2' + ITEM_POSTFIX,
    GLOVES_7: ITEM_PREFIX + 'GLOVES_7' + ITEM_POSTFIX,
    BELT_2: ITEM_PREFIX + 'BELT_2' + ITEM_POSTFIX,
    AMULET_3: ITEM_PREFIX + 'AMULET_3' + ITEM_POSTFIX,
    AMULET_4: ITEM_PREFIX + 'AMULET_4' + ITEM_POSTFIX,
    AMULET_9: ITEM_PREFIX + 'AMULET_9' + ITEM_POSTFIX,
    RING_3: ITEM_PREFIX + 'RING_3' + ITEM_POSTFIX,
    RING_4: ITEM_PREFIX + 'RING_4' + ITEM_POSTFIX,
    RING_10: ITEM_PREFIX + 'RING_10' + ITEM_POSTFIX,
    RING_11: ITEM_PREFIX + 'RING_11' + ITEM_POSTFIX,
    OFF_3: ITEM_PREFIX + 'OFF_3' + ITEM_POSTFIX,
    OFF_4: ITEM_PREFIX + 'OFF_4' + ITEM_POSTFIX,
    OFF_11: ITEM_PREFIX + 'OFF_11' + ITEM_POSTFIX,
    BOOTS_2: ITEM_PREFIX + 'BOOTS_2' + ITEM_POSTFIX,
    OHW_4: ITEM_PREFIX + 'OHW_4' + ITEM_POSTFIX,
    OHW_5: ITEM_PREFIX + 'OHW_5' + ITEM_POSTFIX,
    OHW_6: ITEM_PREFIX + 'OHW_6' + ITEM_POSTFIX,
    THW_3: ITEM_PREFIX + 'THW_3' + ITEM_POSTFIX,
    THW_4: ITEM_PREFIX + 'THW_4' + ITEM_POSTFIX,
    THW_5: ITEM_PREFIX + 'THW_5' + ITEM_POSTFIX,
    STEEL_SKIN: ITEM_PREFIX + 'STEEL_SKIN' + ITEM_POSTFIX,
    LIFEDRAIN: ITEM_PREFIX + 'LIFEDRAIN' + ITEM_POSTFIX,
    FROSTBITE: ITEM_PREFIX + 'FROSTBITE' + ITEM_POSTFIX,
    LIKE_A_PUSSY: ITEM_PREFIX + 'LIKE_A_PUSSY' + ITEM_POSTFIX,
    RAGE: ITEM_PREFIX + 'RAGE' + ITEM_POSTFIX,
    DEAD_STRIKE: ITEM_PREFIX + 'DEAD_STRIKE' + ITEM_POSTFIX,
    FIRM_HAND: ITEM_PREFIX + 'FIRM_HAND' + ITEM_POSTFIX,
    JUSTICE: ITEM_PREFIX + 'JUSTICE' + ITEM_POSTFIX,
    STEP_BACK: ITEM_PREFIX + 'STEP_BACK' + ITEM_POSTFIX,
    PATIENCE: ITEM_PREFIX + 'PATIENCE' + ITEM_POSTFIX,
    TIME_AND_EXPERIENCE: ITEM_PREFIX + 'TIME_AND_EXPERIENCE' + ITEM_POSTFIX,
    MELT: ITEM_PREFIX + 'MELT' + ITEM_POSTFIX,
    ICE_SHARD: ITEM_PREFIX + 'ICE_SHARD' + ITEM_POSTFIX,

    CONS_9: ITEM_PREFIX + 'CONS_9' + ITEM_POSTFIX,
    CONS_10: ITEM_PREFIX + 'CONS_10' + ITEM_POSTFIX,
    CONS_11: ITEM_PREFIX + 'CONS_11' + ITEM_POSTFIX,
    CONS_12: ITEM_PREFIX + 'CONS_12' + ITEM_POSTFIX,
    CONS_23: ITEM_PREFIX + 'CONS_23' + ITEM_POSTFIX,
    CONS_24: ITEM_PREFIX + 'CONS_24' + ITEM_POSTFIX,
    HELM_3: ITEM_PREFIX + 'HELM_3' + ITEM_POSTFIX,
    HELM_8: ITEM_PREFIX + 'HELM_8' + ITEM_POSTFIX,
    BODY_3: ITEM_PREFIX + 'BODY_3' + ITEM_POSTFIX,
    BODY_7: ITEM_PREFIX + 'BODY_7' + ITEM_POSTFIX,
    GLOVES_3: ITEM_PREFIX + 'GLOVES_3' + ITEM_POSTFIX,
    GLOVES_8: ITEM_PREFIX + 'GLOVES_8' + ITEM_POSTFIX,
    BELT_3: ITEM_PREFIX + 'BELT_3' + ITEM_POSTFIX,
    AMULET_5: ITEM_PREFIX + 'AMULET_5' + ITEM_POSTFIX,
    AMULET_6: ITEM_PREFIX + 'AMULET_6' + ITEM_POSTFIX,
    AMULET_10: ITEM_PREFIX + 'AMULET_10' + ITEM_POSTFIX,
    AMULET_11: ITEM_PREFIX + 'AMULET_11' + ITEM_POSTFIX,
    RING_5: ITEM_PREFIX + 'RING_5' + ITEM_POSTFIX,
    RING_6: ITEM_PREFIX + 'RING_6' + ITEM_POSTFIX,
    OFF_5: ITEM_PREFIX + 'OFF_5' + ITEM_POSTFIX,
    OFF_6: ITEM_PREFIX + 'OFF_6' + ITEM_POSTFIX,
    BOOTS_3: ITEM_PREFIX + 'BOOTS_3' + ITEM_POSTFIX,
    BOOTS_6: ITEM_PREFIX + 'BOOTS_6' + ITEM_POSTFIX,
    OHW_7: ITEM_PREFIX + 'OHW_7' + ITEM_POSTFIX,
    OHW_8: ITEM_PREFIX + 'OHW_8' + ITEM_POSTFIX,
    OHW_9: ITEM_PREFIX + 'OHW_9' + ITEM_POSTFIX,
    OHW_12: ITEM_PREFIX + 'OHW_12' + ITEM_POSTFIX,
    OHW_13: ITEM_PREFIX + 'OHW_13' + ITEM_POSTFIX,
    OHW_14: ITEM_PREFIX + 'OHW_14' + ITEM_POSTFIX,
    THW_6: ITEM_PREFIX + 'THW_6' + ITEM_POSTFIX,
    THW_7: ITEM_PREFIX + 'THW_7' + ITEM_POSTFIX,
    THW_10: ITEM_PREFIX + 'THW_10' + ITEM_POSTFIX,
    PARALYZE: ITEM_PREFIX + 'PARALYZE' + ITEM_POSTFIX,
    GUIDING_LIGHT: ITEM_PREFIX + 'GUIDING_LIGHT' + ITEM_POSTFIX,
    GUT_PUNCH: ITEM_PREFIX + 'GUT_PUNCH' + ITEM_POSTFIX,
    LAST_DANCE: ITEM_PREFIX + 'LAST_DANCE' + ITEM_POSTFIX,
    I_SURVIVE: ITEM_PREFIX + 'I_SURVIVE' + ITEM_POSTFIX,
    ARMOR_OF_FAITH: ITEM_PREFIX + 'ARMOR_OF_FAITH' + ITEM_POSTFIX,
    THUNDERBOLT: ITEM_PREFIX + 'THUNDERBOLT' + ITEM_POSTFIX,

    CONS_13: ITEM_PREFIX + 'CONS_13' + ITEM_POSTFIX,
    CONS_14: ITEM_PREFIX + 'CONS_14' + ITEM_POSTFIX,
    CONS_15: ITEM_PREFIX + 'CONS_15' + ITEM_POSTFIX,
    CONS_16: ITEM_PREFIX + 'CONS_16' + ITEM_POSTFIX,
    CONS_25: ITEM_PREFIX + 'CONS_25' + ITEM_POSTFIX,
    CONS_26: ITEM_PREFIX + 'CONS_26' + ITEM_POSTFIX,
    HELM_4: ITEM_PREFIX + 'HELM_4' + ITEM_POSTFIX,
    HELM_5: ITEM_PREFIX + 'HELM_5' + ITEM_POSTFIX,
    BODY_4: ITEM_PREFIX + 'BODY_4' + ITEM_POSTFIX,
    BODY_5: ITEM_PREFIX + 'BODY_5' + ITEM_POSTFIX,
    GLOVES_4: ITEM_PREFIX + 'GLOVES_4' + ITEM_POSTFIX,
    GLOVES_5: ITEM_PREFIX + 'GLOVES_5' + ITEM_POSTFIX,
    BELT_4: ITEM_PREFIX + 'BELT_4' + ITEM_POSTFIX,
    AMULET_7: ITEM_PREFIX + 'AMULET_7' + ITEM_POSTFIX,
    AMULET_8: ITEM_PREFIX + 'AMULET_8' + ITEM_POSTFIX,
    RING_7: ITEM_PREFIX + 'RING_7' + ITEM_POSTFIX,
    RING_8: ITEM_PREFIX + 'RING_8' + ITEM_POSTFIX,
    OFF_7: ITEM_PREFIX + 'OFF_7' + ITEM_POSTFIX,
    OFF_8: ITEM_PREFIX + 'OFF_8' + ITEM_POSTFIX,
    OFF_9: ITEM_PREFIX + 'OFF_9' + ITEM_POSTFIX,
    OFF_12: ITEM_PREFIX + 'OFF_12' + ITEM_POSTFIX,
    OFF_13: ITEM_PREFIX + 'OFF_13' + ITEM_POSTFIX,
    BOOTS_4: ITEM_PREFIX + 'BOOTS_4' + ITEM_POSTFIX,
    BOOTS_7: ITEM_PREFIX + 'BOOTS_7' + ITEM_POSTFIX,
    OHW_10: ITEM_PREFIX + 'OHW_10' + ITEM_POSTFIX,
    OHW_11: ITEM_PREFIX + 'OHW_11' + ITEM_POSTFIX,
    THW_8: ITEM_PREFIX + 'THW_8' + ITEM_POSTFIX,
    THW_9: ITEM_PREFIX + 'THW_9' + ITEM_POSTFIX,
    THW_11: ITEM_PREFIX + 'THW_11' + ITEM_POSTFIX,
    REFLECTION: ITEM_PREFIX + 'REFLECTION' + ITEM_POSTFIX,
    SCARLET_BRANCH_ADEPT: ITEM_PREFIX + 'SCARLET_BRANCH_ADEPT' + ITEM_POSTFIX,
    ANATHEMA: ITEM_PREFIX + 'ANATHEMA' + ITEM_POSTFIX,
    GIANTS_BLOOD: ITEM_PREFIX + 'GIANTS_BLOOD' + ITEM_POSTFIX,
    BACKSTAB: ITEM_PREFIX + 'BACKSTAB' + ITEM_POSTFIX,
    VETERAN_CONFIDENCE: ITEM_PREFIX + 'VETERAN_CONFIDENCE' + ITEM_POSTFIX,
    LIVE_FORTRESS: ITEM_PREFIX + 'LIVE_FORTRESS' + ITEM_POSTFIX,
    COLD_SHOULDER: ITEM_PREFIX + 'COLD_SHOULDER' + ITEM_POSTFIX,
    ERASE: ITEM_PREFIX + 'ERASE' + ITEM_POSTFIX,
    OTHER_1: ITEM_PREFIX + 'OTHER_1' + ITEM_POSTFIX,
    OTHER_2: ITEM_PREFIX + 'OTHER_2' + ITEM_POSTFIX,
    OTHER_3: ITEM_PREFIX + 'OTHER_3' + ITEM_POSTFIX,
    OTHER_4: ITEM_PREFIX + 'OTHER_4' + ITEM_POSTFIX,
    OTHER_5: ITEM_PREFIX + 'OTHER_5' + ITEM_POSTFIX,

    // HELM_10: ITEM_PREFIX + 'HELM_10' + ITEM_POSTFIX,
    // HELM_11: ITEM_PREFIX + 'HELM_11' + ITEM_POSTFIX,
    // BODY_9: ITEM_PREFIX + 'BODY_9' + ITEM_POSTFIX,
    // BODY_10: ITEM_PREFIX + 'BODY_10' + ITEM_POSTFIX,
    // BODY_11: ITEM_PREFIX + 'BODY_11' + ITEM_POSTFIX,
    // GLOVES_9: ITEM_PREFIX + 'GLOVES_9' + ITEM_POSTFIX,
    // GLOVES_10: ITEM_PREFIX + 'GLOVES_10' + ITEM_POSTFIX,
    // GLOVES_11: ITEM_PREFIX + 'GLOVES_11' + ITEM_POSTFIX,
    // BELT_6: ITEM_PREFIX + 'BELT_6' + ITEM_POSTFIX,
    // BELT_7: ITEM_PREFIX + 'BELT_7' + ITEM_POSTFIX,
    // AMULET_14: ITEM_PREFIX + 'AMULET_14' + ITEM_POSTFIX,
    // AMULET_15: ITEM_PREFIX + 'AMULET_15' + ITEM_POSTFIX,
    // AMULET_16: ITEM_PREFIX + 'AMULET_16' + ITEM_POSTFIX,
    // AMULET_17: ITEM_PREFIX + 'AMULET_17' + ITEM_POSTFIX,
    // RING_12: ITEM_PREFIX + 'RING_12' + ITEM_POSTFIX,
    // RING_13: ITEM_PREFIX + 'RING_13' + ITEM_POSTFIX,
    // RING_14: ITEM_PREFIX + 'RING_14' + ITEM_POSTFIX,
    // OFF_14: ITEM_PREFIX + 'OFF_14' + ITEM_POSTFIX,
    // OFF_15: ITEM_PREFIX + 'OFF_15' + ITEM_POSTFIX,
    // OFF_16: ITEM_PREFIX + 'OFF_16' + ITEM_POSTFIX,
    // BOOTS_8: ITEM_PREFIX + 'BOOTS_8' + ITEM_POSTFIX,
    // BOOTS_9: ITEM_PREFIX + 'BOOTS_9' + ITEM_POSTFIX,
    // BOOTS_10: ITEM_PREFIX + 'BOOTS_10' + ITEM_POSTFIX,
    // BOOTS_11: ITEM_PREFIX + 'BOOTS_11' + ITEM_POSTFIX,
    // OHW_15: ITEM_PREFIX + 'OHW_15' + ITEM_POSTFIX,
    // OHW_16: ITEM_PREFIX + 'OHW_16' + ITEM_POSTFIX,
    // OHW_17: ITEM_PREFIX + 'OHW_17' + ITEM_POSTFIX,
    // OHW_18: ITEM_PREFIX + 'OHW_18' + ITEM_POSTFIX,
    // OHW_19: ITEM_PREFIX + 'OHW_19' + ITEM_POSTFIX,
    // THW_12: ITEM_PREFIX + 'THW_12' + ITEM_POSTFIX,
    // THW_13: ITEM_PREFIX + 'THW_13' + ITEM_POSTFIX,
    // THW_14: ITEM_PREFIX + 'THW_14' + ITEM_POSTFIX,
    // THW_15: ITEM_PREFIX + 'THW_15' + ITEM_POSTFIX,
  };

  public static MONSTERS: MonstersAddressesType = {
    REBELLIOUS_PEASANT_34: genObjectId(1, 34, 1),
    TOUGH_DESERTER_34: genObjectId(1, 34, 2),
    BLOODTHIRSTY_MADMAN_34: genObjectId(1, 34, 3),
    FEROCIOUS_WOLFS_34: genObjectId(1, 34, 4),
    DRUNK_CROSSBOWMAN_34: genObjectId(1, 34, 5),
    REBELLIOUS_PEASANT_32: genObjectId(1, 32, 6),
    TOUGH_DESERTER_32: genObjectId(1, 32, 7),
    BLOODTHIRSTY_MADMAN_32: genObjectId(1, 32, 8),
    DRUNK_CROSSBOWMAN_32: genObjectId(1, 32, 9),
    VILE_SERPENT_35: genObjectId(1, 35, 10),
    THE_MAN_EATING_BEAR_35: genObjectId(1, 35, 11),
    BANDIT_CROWD_10: genObjectId(1, 10, 12),
    HANGMANS_PATROL_33: genObjectId(1, 33, 13),
    MIDNIGHTER_2: genObjectId(1, 2, 14),
    FOREST_CHORT_35: genObjectId(1, 35, 15),
    INFECTED_SPIRIT_OF_THE_FOREST_3: genObjectId(1, 3, 16),
    MOCK1: genObjectId(1, 10, 17),
    THRALL_10: genObjectId(1, 10, 18),
    SAVAGE_10: genObjectId(1, 10, 19),
    MAGE_10: genObjectId(1, 10, 20),
    ASSASSIN_10: genObjectId(1, 10, 21),
    BARBARIAN_BITCH_10: genObjectId(1, 10, 22),
    RESTLESS_SPIRIT_10: genObjectId(1, 10, 23),
    INFECTED_DEADMAN_JUNIOR_32: genObjectId(1, 32, 24),
    INFECTED_DEADMAN_JUNIOR_34: genObjectId(1, 34, 25),
    HANGMANS_PATROL_F2P: genObjectId(1, 10, 26),
    A_LITTLE_MORE_BANDITS_THAN_USUAL_F2P: genObjectId(1, 10, 27),
    REBELLIOUS_PEASANT_F2P: genObjectId(1, 10, 28),
    DRUNK_CROSSBOWMAN_F2P: genObjectId(1, 10, 29),
    SOMBER_HANGMAN_F2P: genObjectId(1, 10, 30),
    FILTHY_WILLY_F2P: genObjectId(1, 10, 31),
    BANISHED_PEASANT_10: genObjectId(1, 10, 32),
    STARVING_WOLFS_10: genObjectId(1, 10, 33),
    PEDOPHILE_HUNTER_10: genObjectId(1, 10, 34),
    FRESH_INFECTED_DEADMAN_10: genObjectId(1, 10, 35),
    STYLE_POLICE_10: genObjectId(1, 10, 36),
    BAD_BEAR_10: genObjectId(1, 10, 37),
    ORDINARY_GOOSE_32: genObjectId(1, 32, 38),
    ORDINARY_GOOSE_34: genObjectId(1, 34, 39),
    LOCAL_DUDE_32: genObjectId(1, 32, 40),
    LOCAL_DUDE_34: genObjectId(1, 34, 41),
    HUNGRY_DOG_32: genObjectId(1, 32, 42),
    HUNGRY_DOG_34: genObjectId(1, 34, 43),
    ROWDY_10: genObjectId(1, 10, 44),
    OLD_WHORE_10: genObjectId(1, 10, 45),
    TRAINING_BUDDY_33: genObjectId(1, 33, 46),
    FEROCIOUS_WIFE_33: genObjectId(1, 33, 47),
    STRABAN_KNIGHT_10: genObjectId(1, 10, 48),

    BLOODCURDLING_WOLFS_34: genObjectId(2, 34, 1),
    SOMBER_HANGMAN_34: genObjectId(2, 34, 2),
    THRALL_HUNTER_34: genObjectId(2, 34, 3),
    WILDED_ANCHORITE_34: genObjectId(2, 34, 4),
    CHEERFUL_HANGMAN_34: genObjectId(2, 34, 5),
    SOMBER_HANGMAN_32: genObjectId(2, 32, 6),
    THRALL_HUNTER_32: genObjectId(2, 32, 7),
    WILDED_ANCHORITE_32: genObjectId(2, 32, 8),
    CHEERFUL_HANGMAN_32: genObjectId(2, 32, 9),
    FOREST_SHAMAN_35: genObjectId(2, 35, 10),
    FILTHY_WILLY_35: genObjectId(2, 35, 11),
    FOREST_SHAMAN_33: genObjectId(2, 33, 12),
    FILTHY_WILLY_33: genObjectId(2, 33, 13),
    RUINS_KEEPER_10: genObjectId(2, 10, 14),
    STRABAN_DOUCHEBAGS_AVENGERS_10: genObjectId(2, 10, 15),
    FUNH_3: genObjectId(2, 3, 16),
    MOCK2: genObjectId(2, 10, 17),
    FUGITIVE_KUDJA_33: genObjectId(2, 33, 18),
    FUGITIVE_KUDJA_35: genObjectId(2, 35, 19),
    SMELLY_CANNIBAL_34: genObjectId(2, 34, 20),
    T_POSER_33: genObjectId(2, 33, 21),
    T_POSER_35: genObjectId(2, 35, 22),
    BAD_SEED_33: genObjectId(2, 33, 23),
    BAD_SEED_35: genObjectId(2, 35, 24),
    RIPE_MIDNIGHTER_2: genObjectId(2, 2, 25),
    WOUNDED_DEMOLDER_10: genObjectId(2, 10, 26),
    HANGOVER_WILLY_10: genObjectId(2, 10, 27),
    VENOMOUS_SPIDER_10: genObjectId(2, 10, 28),

    DEMOLDER_WARRIOR_34: genObjectId(3, 34, 1),
    DEMOLDER_SERGEANT_34: genObjectId(3, 34, 2),
    DEMOLDER_BOWMAN_34: genObjectId(3, 34, 3),
    RUNE_PRIEST_34: genObjectId(3, 34, 4),
    DEMOLDER_HEADBRINGER_34: genObjectId(3, 34, 5),
    ANCIENT_SERPENT_35: genObjectId(3, 35, 6),
    RUNE_KING_35: genObjectId(3, 35, 7),
    CHASER_35: genObjectId(3, 10, 8),
    A_LITTLE_MORE_BANDITS_THAN_USUAL_10: genObjectId(3, 10, 9),
    OGRE_BARON_10: genObjectId(3, 10, 10),
    ELITE_PRINCE_FELLOWS_10: genObjectId(3, 10, 11),
    UNFRIENDLY_HOUND_34: genObjectId(3, 34, 12),
    BROTHER_DAMOUR_10: genObjectId(3, 10, 13),
    HUNGRY_BROOD_3: genObjectId(3, 3, 14),
    MOCK3: genObjectId(3, 10, 15),
    JUICY_MIDNIGHTER_2: genObjectId(3, 2, 16),
    MERGULLIAN_HEADHUNTER_34: genObjectId(3, 34, 17),
    SMALL_SPIDER_34: genObjectId(3, 34, 18),
    YOUNG_TROLL_35: genObjectId(3, 35, 19),
    FACELESS_10: genObjectId(3, 10, 20),
    LONE_BARD_35: genObjectId(3, 35, 21),
    FOREST_WITCH_35: genObjectId(3, 35, 22),
    DEMOLDER_CHAMPION_SCALD_10: genObjectId(3, 10, 23),
    DEMOLDER_CHAMPION_THORN_10: genObjectId(3, 10, 24),
    DEMOLDER_CHAMPION_GEKK_10: genObjectId(3, 10, 25),
    MIGHTY_RUIN_KEEPER_10: genObjectId(3, 10, 26),

    GIANT_WASP_34: genObjectId(4, 34, 1),
    MOONHUNTER_34: genObjectId(4, 34, 2),
    GELDED_ONE_34: genObjectId(4, 34, 3),
    RUNEBEARER_34: genObjectId(4, 34, 4),
    DEMOLDER_BERSERK_34: genObjectId(4, 34, 5),
    SWAMP_TROLL_35: genObjectId(4, 35, 6),
    INFECTED_DEADMAN_35: genObjectId(4, 35, 7),
    LOST_BROTHER_DAMOUR_10: genObjectId(4, 10, 8),
    BARD_CHICK_35: genObjectId(4, 35, 9),
    WASP_QUEEN_35: genObjectId(4, 35, 10),
    CULTIST_BOY_OF_MOTHER_34: genObjectId(4, 34, 11),
    CULTIST_GIRL_OF_MOTHER_34: genObjectId(4, 34, 12),
    HAKKAMANDA_10: genObjectId(4, 10, 13),
    MELADZI_10: genObjectId(4, 10, 14),
    PUG_PANIN_10: genObjectId(4, 10, 15),
    LEGION_COMMANDER_DOGWAN_10: genObjectId(4, 10, 16),
    RUNE_KING_VISWANATHAN_10: genObjectId(4, 10, 17),
    GIRLS_AFTER_PARTY_35: genObjectId(4, 35, 18),
    MOTHER_MIOLANIA_3: genObjectId(4, 3, 19),
    POSTAL_HEADMISTRESS_10: genObjectId(4, 10, 20),
    DARK_ADVENTURERS_35: genObjectId(4, 35, 21),
    SPICY_MIDNIGHTER_2: genObjectId(4, 2, 22),
    DARK_ESSENCE_35: genObjectId(4, 35, 23),
    KNIGHT_HAMMERGINA_10: genObjectId(4, 10, 24),
    FULLMOON_DARK_ESSENCE_10: genObjectId(4, 10, 25),
    SUPERGOOSE_10: genObjectId(4, 10, 26),
  };

  public static SHRINES: SpecificChambers = {
    BIOME_1_HEAL_SHRINE: genObjectId(1, 4, 1),
    BIOME_2_HEAL_SHRINE: genObjectId(2, 4, 2),
    BIOME_2_FIGHT_SHRINE: genObjectId(2, 4, 3),
    BIOME_2_LUCK_SHRINE: genObjectId(2, 4, 4),
    BIOME_2_MAGIC_SHRINE: genObjectId(2, 4, 5),
    BIOME_2_RESIST_SHRINE: genObjectId(2, 4, 6),
    BIOME_3_HEAL_SHRINE: genObjectId(3, 4, 7),
    BIOME_3_FIGHT_SHRINE: genObjectId(3, 4, 8),
    BIOME_3_LUCK_SHRINE: genObjectId(3, 4, 9),
    BIOME_3_MAGIC_SHRINE: genObjectId(3, 4, 10),
    BIOME_3_RESIST_SHRINE: genObjectId(3, 4, 11),
    BIOME_3_LC_SHRINE: genObjectId(3, 4, 12),
    BIOME_4_HEAL_SHRINE: genObjectId(4, 4, 13),
    BIOME_4_FIGHT_SHRINE: genObjectId(4, 4, 14),
    BIOME_4_LUCK_SHRINE: genObjectId(4, 4, 15),
    BIOME_4_MAGIC_SHRINE: genObjectId(4, 4, 16),
    BIOME_4_RESIST_SHRINE: genObjectId(4, 4, 17),
    BIOME_4_LC_SHRINE: genObjectId(4, 4, 18),
  };

  public static STORIES: StoriesType = {
    STORY101_RITE_OF_PASSAGE: genObjectId(1, 29, 101),
    STORY102_STRANGER: genObjectId(1, 29, 102),
    STORY103_I_VE_SEEN_YOU_SOMEWHERE: genObjectId(1, 29, 103),
    STORY104_BURIED_ALIVE: genObjectId(1, 29, 104),
    STORY105_POISONED: genObjectId(1, 29, 105),
    STORY106_YOU_SHALL_NOT_PASS: genObjectId(1, 7, 106),
    STORY107_WEDDING_PLANNER: genObjectId(1, 28, 107),
    STORY108_GOOD_BAD_EVIL: genObjectId(1, 30, 108),
    STORY109_TUUNBAG: genObjectId(1, 28, 109),
    STORY110_FIGHT_CLUB: genObjectId(1, 28, 110),
    STORY111_BARD_RETARD: genObjectId(1, 28, 111),
    STORY112_DRUNK_DISPUTE: genObjectId(1, 28, 112),
    STORY113_FIND_A_WAY: genObjectId(1, 29, 113),
    STORY114_TIME_TO_REST: genObjectId(1, 29, 114),
    STORY115_BE_MY_GUEST: genObjectId(1, 29, 115),
    STORY116_FIND_FOOD: genObjectId(1, 29, 116),
    STORY117_SELF_TREATMENT: genObjectId(1, 29, 117),
    STORY118_WILD_ANIMAL: genObjectId(1, 29, 118),
    STORY119_FAIR_EXCHANGE: genObjectId(1, 29, 119),
    STORY120_NICE_WATER: genObjectId(1, 29, 120),
    STORY121_IMYAREK: genObjectId(1, 29, 121),
    STORY122_LETTERS_FROM_THE_DEAD: genObjectId(1, 31, 122),
    STORY123_MR_FISHKING: genObjectId(1, 31, 123),
    STORY124_OLD_RUINS: genObjectId(1, 31, 124),
    STORY125_SLEEPING_BEAUTY: genObjectId(1, 29, 125),
    STORY126_SHEPHERD_BOY: genObjectId(1, 29, 126),
    STORY127_COW_TERROR: genObjectId(1, 29, 127),
    STORY128_ROBBED_CARAVAN: genObjectId(1, 31, 128),
    STORY129_REST_IN_PEACE: genObjectId(1, 28, 129),
    STORY130_CURSED_OLD_HOUSE: genObjectId(1, 30, 130),
    STORY131_POSTMAN: genObjectId(1, 28, 131),
    STORY132_GUARDIAN_OF_THE_FOREST: genObjectId(1, 28, 132),
    STORY133_STRANGER_II: genObjectId(1, 29, 133),
    STORY134_STRANGER_III: genObjectId(1, 29, 134),
    STORY135_STRANGER_IV: genObjectId(1, 29, 135),
    STORY136_POSTMAN_II: genObjectId(1, 29, 136),
    STORY137_FAMILY_VALUES: genObjectId(1, 28, 137),
    STORY138_ON_A_BET: genObjectId(1, 29, 138),
    STORY139_ASSWING_GALE: genObjectId(1, 30, 139),
    STORY140_GUILT: genObjectId(1, 29, 140),
    STORY141_GUILT_II: genObjectId(1, 30, 141),
    STORY142_WALKING_BRAD: genObjectId(1, 30, 142),
    STORY143_YOU_RE_NOT_ALONE: genObjectId(1, 30, 143),
    STORY144_LIONHEART: genObjectId(1, 29, 144),
    STORY145_LIONHEART_II: genObjectId(1, 31, 145),
    STORY146_PAIN_AND_PLEASURE: genObjectId(1, 29, 146),
    STORY169_RESTLESS: genObjectId(1, 31, 169),
    STORY170_CATFIGHT: genObjectId(1, 7, 170),
    STORY171_CATFIGHT_II: genObjectId(1, 7, 171),

    STORY201_SECRETS_OF_THE_PAST: genObjectId(2, 29, 201),
    STORY202_COLD_BODIES: genObjectId(2, 29, 202),
    STORY203_PRINCE_AND_PAUPER: genObjectId(2, 28, 203),
    STORY204_LOF_BLAID_MASSACRE: genObjectId(2, 28, 204),
    STORY205_FAREWELL_LOF_BLAID: genObjectId(2, 28, 205),
    STORY206_EVERY_MAN_FOR_HIMSELF: genObjectId(2, 29, 206),
    STORY207_OLD_FRIEND: genObjectId(2, 28, 207),
    STORY208_VENDETTA: genObjectId(2, 28, 208),
    STORY209_FOXCATCHER: genObjectId(2, 30, 209),
    STORY210_YOU_IN_THE_ARMY_NOW: genObjectId(2, 28, 210),
    STORY211_PRISON_BREAK: genObjectId(2, 29, 211),
    STORY212_UNDER_SIEGE: genObjectId(2, 29, 212),
    STORY213_THRALL_HUNTERS: genObjectId(2, 29, 213),
    STORY214_FOUL_PLAY: genObjectId(2, 29, 214),
    STORY215_FIND_A_WAY: genObjectId(2, 29, 215),
    STORY216_TIME_TO_REST: genObjectId(2, 29, 216),
    STORY217_BE_MY_GUEST: genObjectId(2, 29, 217),
    STORY218_FIND_FOOD: genObjectId(2, 29, 218),
    STORY219_SELF_TREATMENT: genObjectId(2, 30, 219),
    STORY220_HELIX: genObjectId(2, 29, 220),
    STORY221_CAGE: genObjectId(2, 29, 221),
    STORY222_IMYAREK: genObjectId(2, 29, 222),
    STORY223_LESSER_EVIL: genObjectId(2, 31, 223),
    STORY224_OLD_RUINS: genObjectId(2, 31, 224),
    STORY225_BOAR_HUNTER: genObjectId(2, 28, 225),
    STORY226_DESPERATION: genObjectId(2, 29, 226),
    STORY227_ANCIENT_EVIL: genObjectId(2, 29, 227),
    STORY228_WILD_RIVER: genObjectId(2, 29, 228),
    STORY229_TWO_CAN_PLAY_THAT_GAME: genObjectId(2, 29, 229),
    STORY230_PLAGUE_TALE: genObjectId(2, 31, 230),
    STORY231_POSTMAN: genObjectId(2, 28, 231),
    STORY232_IN_FROM_COLD: genObjectId(2, 29, 232),
    STORY233_IN_FROM_COLD_II: genObjectId(2, 29, 233),
    STORY234_MOURNING_MARCH: genObjectId(2, 28, 234),
    STORY235_DRUNK_DISPUTE_II: genObjectId(2, 28, 235),
    STORY236_FOG_GATE: genObjectId(2, 28, 236),
    STORY237_HEY_STRANGER: genObjectId(2, 28, 237),
    STORY238_HELPING_HANDS: genObjectId(2, 28, 238),

    STORY301_SACRA_HUNT: genObjectId(3, 29, 301),
    STORY302_THE_GUTTERS: genObjectId(3, 21, 302),
    STORY303_WRONG_TURN: genObjectId(3, 29, 303),
    STORY304_LIFE_ROAD: genObjectId(3, 29, 304),
    STORY305_DEATHTRAP: genObjectId(3, 29, 305),
    STORY306_FEED_THE_MOTHER: genObjectId(3, 7, 306),
    STORY307_FACELESS: genObjectId(3, 31, 307),
    STORY308_VANDALISM: genObjectId(3, 29, 308),
    STORY309_FOREST_WITCH: genObjectId(3, 31, 309),
    STORY310_STUCK: genObjectId(3, 29, 310),
    STORY311_DINNER_PARTY: genObjectId(3, 31, 311),
    STORY312_FILTHY_REVENGE: genObjectId(3, 29, 312),
    STORY313_FOR_YOUR_PRINCE: genObjectId(3, 29, 313),
    STORY314_WATCHER: genObjectId(3, 29, 314),
    STORY315_PRETENDER: genObjectId(3, 31, 315),
    STORY316_OLD_RUINS: genObjectId(3, 31, 316),
    STORY317_SELF_TREATMENT: genObjectId(3, 29, 317),
    STORY318_FIND_FOOD: genObjectId(3, 29, 318),
    STORY319_BE_MY_GUEST: genObjectId(3, 29, 319),
    STORY320_WHITE_BRANCH: genObjectId(3, 31, 320),
    STORY321_ALL_LIVES_MATTERS: genObjectId(3, 29, 321),
    STORY322_SCUM_SOLO: genObjectId(3, 29, 322),
    STORY323_DARK_ARTS: genObjectId(3, 31, 323),
    STORY324_HOW_STEEL_FORGED: genObjectId(3, 29, 324),
    STORY325_GREAT_EVIL_DEED: genObjectId(3, 29, 325),
    STORY326_HUMAN_SACRIFICE: genObjectId(3, 29, 326),
    STORY327_FIELD_OF_MIRACLES: genObjectId(3, 31, 327),
    STORY328_DEMON_PARTY: genObjectId(3, 31, 328),
    STORY329_SHORT_MAN: genObjectId(3, 29, 329),
    STORY330_DARK_SOUL: genObjectId(3, 31, 330),
    STORY332_BROOD_NEED_FOOD: genObjectId(3, 29, 332),

    STORY401_WAY_OUT: genObjectId(4, 29, 401),
    STORY402_YOU_AGAIN: genObjectId(4, 31, 402),
    STORY403_LAMENT_CONFIGURATION: genObjectId(4, 31, 403),
    STORY404_WRONG_HONEY: genObjectId(4, 29, 404),
    STORY405_WALL_OF_FIRE: genObjectId(4, 29, 405),
    STORY406_MORE_WASPS: genObjectId(4, 29, 406),
    STORY407_KEY_TO_NOTHING: genObjectId(4, 31, 407),
    STORY408_THE_GUTTERS: genObjectId(4, 21, 408),
    STORY409_EMASCULATION: genObjectId(4, 31, 409),
    STORY410_WHINERS_WAY: genObjectId(4, 31, 410),
    STORY411_A_MATTER_OF_FAITH: genObjectId(4, 31, 411),
    STORY412_ADVENTURERS_CLUB: genObjectId(4, 29, 412),
    STORY413_FIND_A_WAY: genObjectId(4, 29, 413),
    STORY414_TIME_TO_REST: genObjectId(4, 29, 414),
    STORY415_FIND_FOOD: genObjectId(4, 29, 415),
    STORY416_SELF_TREATMENT: genObjectId(4, 29, 416),
    STORY417_KINDNESS: genObjectId(4, 29, 417),
    STORY418_GREAT_INVESTOR: genObjectId(4, 29, 418),
    STORY419_SECTA: genObjectId(4, 29, 419),
    STORY420_CULLS_PARADE: genObjectId(4, 29, 420),
    STORY421_ARIADNES_THREAD: genObjectId(4, 31, 421),
    STORY422_COUPLE_OF_SIPS: genObjectId(4, 29, 422),
    STORY423_TURTLING: genObjectId(4, 29, 423),
    STORY424_RUNEBREAKERS: genObjectId(4, 29, 424),
    STORY425_ENEMY_OF_MY_ENEMY: genObjectId(4, 29, 425),
    STORY426_ASSEMBLAGE: genObjectId(4, 29, 426),
    STORY427_FREEDOM_FIGHT: genObjectId(4, 29, 427),
    STORY428_BLOOD_AND_HONOR: genObjectId(4, 29, 428),
    STORY429_FALSE_GOD: genObjectId(4, 29, 429),
    STORY430_PILLARS_OF_POWER: genObjectId(4, 29, 430),
    STORY431_POSTMAN: genObjectId(4, 29, 431),
    STORY432_APEX_PREDATOR: genObjectId(4, 29, 432),
    STORY433_BLOOD_AND_HONOR_II: genObjectId(4, 7, 433),
    STORY434_BLOOD_AND_HONOR_III: genObjectId(4, 29, 434),
    STORY435_FREEDOM_FIGHT_II: genObjectId(4, 7, 435),
    STORY436_FREEDOM_FIGHT_III: genObjectId(4, 7, 436),
    STORY437_FREEDOM_FIGHT_IV: genObjectId(4, 7, 437),
    STORY438_FREEDOM_FIGHT_V: genObjectId(4, 7, 438),
    STORY439_POSTMAN_III: genObjectId(4, 29, 439),
  };
}
