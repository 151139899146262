export const ALL_IMAGES = {
  ABANDONED_TRACT: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/ABANDONED_TRACT.avif',
  ALIVE_AGAIN: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/ALIVE_AGAIN.avif',
  ALIVE_AGAIN_F2P: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/ALIVE_AGAIN_F2P.avif',
  ANOTHER_DAY: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/ANOTHER_DAY.avif',
  BLOOD_TEARS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/BLOOD_TEARS.avif',
  BURNED_VILLAGES: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/BURNED_VILLAGES.avif',
  DANGEROUS_TRAILS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/DANGEROUS_TRAILS.avif',
  DEPTHS_OF_THE_FOREST:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/DEPTHS_OF_THE_FOREST.avif',
  DESPERATION: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/DESPERATION.avif',
  FORGOTTEN_LAND: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/FORGOTTEN_LAND.avif',
  GRIMLARNS_HOSPITALITY:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/GRIMLARNS_HOSPITALITY.avif',
  IN_THE_WILD: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/IN_THE_WILD.avif',
  LOF_BLAID: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/LOF_BLAID.avif',
  NIGHT_FOREST: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/NIGHT_FOREST.avif',
  OUTSKIRTS_OF_THE_CITY:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/OUTSKIRTS_OF_THE_CITY.avif',
  PILGRIMAGE: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/PILGRIMAGE.avif',
  SACRA_HUNT: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/SACRA_HUNT.avif',
  TERRITORY_OF_BANDITS:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/TERRITORY_OF_BANDITS.avif',
  THIS_IS_RIDICULOUS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome1/THIS_IS_RIDICULOUS.avif',
  CREEPY_PLACE: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/CREEPY_PLACE.avif',
  FEED_THE_MOTHER: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/FEED_THE_MOTHER.avif',
  FORGOTTEN_FAITH: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/FORGOTTEN_FAITH.avif',
  GREAT_EXODUS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/GREAT_EXODUS.avif',
  GROVE_OF_GIANTS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/GROVE_OF_GIANTS.avif',
  ITS_GETTING_COLDER: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/ITS_GETTING_COLDER.avif',
  LAST_SPARKS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/LAST_SPARKS.avif',
  TRAPS_ARE_EVERYWHERE:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/TRAPS_ARE_EVERYWHERE.avif',
  WAYS_OF_THE_DROWNED: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/WAYS_OF_THE_DROWNED.avif',
  WHAT_DOES_THE_THICK_FOG_HIDE:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome3/WHAT_DOES_THE_THICK_FOG_HIDE.avif',
  ATTRACTIONS_OF_THE_ONALPHOE:
    'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/ATTRACTIONS_OF_THE_ONALPHOE.avif',
  A_DAY_FULL_OF_HOPE: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/A_DAY_FULL_OF_HOPE.avif',
  BONES: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/BONES.avif',
  CULT: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/CULT.avif',
  FORMER_GREATNESS: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/FORMER_GREATNESS.avif',
  GLOW: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/GLOW.avif',
  LIFE_ROAD: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/LIFE_ROAD.avif',
  SPIDER_SEASON: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/SPIDER_SEASON.avif',
  THREAT: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/THREAT.avif',
  UNPLEASANT_BUZZING: 'https://res.cloudinary.com/sacra/image/upload/images/dungeons/biome4/UNPLEASANT_BUZZING.avif',
  ASSASSIN: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/ASSASSIN.avif',
  GHOST: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/GHOST.avif',
  HAMMERGINA: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/HAMMERGINA.avif',
  MAGE: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/MAGE.avif',
  SAVAGE: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/SAVAGE.avif',
  THRALL: 'https://res.cloudinary.com/sacra/image/upload/images/heroes/THRALL.avif',
  AMULET_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/AMULET_1.avif',
  AMULET_12: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/AMULET_12.avif',
  AMULET_13: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/AMULET_13.avif',
  AMULET_14: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/AMULET_14.avif',
  AMULET_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/AMULET_2.avif',
  BEEF_PATTY: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BEEF_PATTY.avif',
  BELT_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BELT_1.avif',
  BELT_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BELT_5.avif',
  BODY_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BODY_1.avif',
  BOOTS_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BOOTS_1.avif',
  BOOTS_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BOOTS_5.avif',
  BRUTE_FORCE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/BRUTE_FORCE.avif',
  CONS_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_1.avif',
  CONS_17: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_17.avif',
  CONS_18: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_18.avif',
  CONS_19: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_19.avif',
  CONS_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_2.avif',
  CONS_20: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_20.avif',
  CONS_21: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_21.avif',
  CONS_27: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_27.avif',
  CONS_28: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_28.avif',
  CONS_29: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_29.avif',
  CONS_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_3.avif',
  CONS_30: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_30.avif',
  CONS_31: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_31.avif',
  CONS_32: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_32.avif',
  CONS_33: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_33.avif',
  CONS_34: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_34.avif',
  CONS_35: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_35.avif',
  CONS_42: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_42.avif',
  OTHER_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_33.avif',
  OTHER_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OTHER_2.avif',
  OTHER_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OTHER_3.avif',
  OTHER_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OTHER_4.avif',
  OTHER_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OTHER_5.avif',
  CONS_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/CONS_4.avif',
  DEMON_SPEED: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/DEMON_SPEED.avif',
  FEAR: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/FEAR.avif',
  GLOVES_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/GLOVES_1.avif',
  GLOVES_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/GLOVES_6.avif',
  GOLDEN_EYE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/GOLDEN_EYE.avif',
  HEAT_STRIKE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/HEAT_STRIKE.avif',
  HELM_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/HELM_1.avif',
  HELM_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/HELM_6.avif',
  HELM_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/HELM_9.avif',
  OFF_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OFF_1.avif',
  OFF_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OFF_10.avif',
  OFF_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OFF_2.avif',
  OHW_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OHW_1.avif',
  OHW_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OHW_2.avif',
  OHW_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/OHW_3.avif',
  ONSLAUGHT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/ONSLAUGHT.avif',
  RING_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/RING_1.avif',
  RING_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/RING_2.avif',
  RING_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/RING_9.avif',
  SABBAH_POISON: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/SABBAH_POISON.avif',
  SAVAGE_CHARM: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/SAVAGE_CHARM.avif',
  SPIT_IN_FACE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/SPIT_IN_FACE.avif',
  STATIC_DISCHARGE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/STATIC_DISCHARGE.avif',
  STRONG_BLOCK: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/STRONG_BLOCK.avif',
  THW_1: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/THW_1.avif',
  THW_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome1/THW_2.avif',
  AMULET_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/AMULET_3.avif',
  AMULET_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/AMULET_4.avif',
  AMULET_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/AMULET_9.avif',
  BELT_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/BELT_2.avif',
  BODY_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/BODY_2.avif',
  BODY_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/BODY_6.avif',
  BODY_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/BODY_8.avif',
  BOOTS_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/BOOTS_2.avif',
  CONS_22: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/CONS_22.avif',
  CONS_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/CONS_5.avif',
  CONS_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/CONS_6.avif',
  CONS_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/CONS_7.avif',
  CONS_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/CONS_8.avif',
  DEAD_STRIKE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/DEAD_STRIKE.avif',
  FIRM_HAND: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/FIRM_HAND.avif',
  FROSTBITE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/FROSTBITE.avif',
  GLOVES_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/GLOVES_2.avif',
  GLOVES_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/GLOVES_7.avif',
  HELM_2: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/HELM_2.avif',
  HELM_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/HELM_7.avif',
  ICE_SHARD: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/ICE_SHARD.avif',
  JUSTICE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/JUSTICE.avif',
  LIFEDRAIN: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/LIFEDRAIN.avif',
  LIKE_A_PUSSY: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/LIKE_A_PUSSY.avif',
  MELT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/MELT.avif',
  OFF_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OFF_11.avif',
  OFF_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OFF_3.avif',
  OFF_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OFF_4.avif',
  OHW_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OHW_4.avif',
  OHW_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OHW_5.avif',
  OHW_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/OHW_6.avif',
  PATIENCE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/PATIENCE.avif',
  RAGE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/RAGE.avif',
  RING_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/RING_10.avif',
  RING_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/RING_11.avif',
  RING_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/RING_3.avif',
  RING_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/RING_4.avif',
  STEEL_SKIN: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/STEEL_SKIN.avif',
  STEP_BACK: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/STEP_BACK.avif',
  THW_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/THW_3.avif',
  THW_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/THW_4.avif',
  THW_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/THW_5.avif',
  TIME_AND_EXPERIENCE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome2/TIME_AND_EXPERIENCE.avif',
  AMULET_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/AMULET_10.avif',
  AMULET_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/AMULET_11.avif',
  AMULET_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/AMULET_5.avif',
  AMULET_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/AMULET_6.avif',
  ARMOR_OF_FAITH: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/ARMOR_OF_FAITH.avif',
  BELT_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/BELT_3.avif',
  BODY_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/BODY_3.avif',
  BODY_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/BODY_7.avif',
  BOOTS_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/BOOTS_3.avif',
  BOOTS_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/BOOTS_6.avif',
  CONS_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_10.avif',
  CONS_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_11.avif',
  CONS_12: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_12.avif',
  CONS_23: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_23.avif',
  CONS_24: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_24.avif',
  CONS_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/CONS_9.avif',
  GLOVES_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/GLOVES_3.avif',
  GLOVES_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/GLOVES_8.avif',
  GUIDING_LIGHT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/GUIDING_LIGHT.avif',
  GUT_PUNCH: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/GUT_PUNCH.avif',
  HELM_3: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/HELM_3.avif',
  HELM_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/HELM_8.avif',
  I_SURVIVE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/I_SURVIVE.avif',
  LAST_DANCE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/LAST_DANCE.avif',
  OFF_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OFF_5.avif',
  OFF_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OFF_6.avif',
  'OHW 12 middle': 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW%2012%20middle.avif',
  OHW_12: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_12.avif',
  OHW_13: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_13.avif',
  OHW_14: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_14.avif',
  OHW_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_7.avif',
  OHW_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_8.avif',
  OHW_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/OHW_9.avif',
  PARALYZE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/PARALYZE.avif',
  RING_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/RING_5.avif',
  RING_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/RING_6.avif',
  THUNDERBOLT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/THUNDERBOLT.avif',
  THW_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/THW_10.avif',
  THW_6: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/THW_6.avif',
  THW_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome3/THW_7.avif',
  AMULET_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/AMULET_7.avif',
  AMULET_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/AMULET_8.avif',
  ANATHEMA: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/ANATHEMA.avif',
  BACKSTAB: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BACKSTAB.avif',
  BELT_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BELT_4.avif',
  BODY_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BODY_4.avif',
  BODY_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BODY_5.avif',
  BOOTS_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BOOTS_4.avif',
  BOOTS_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/BOOTS_7.avif',
  COLD_SHOULDER: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/COLD_SHOULDER.avif',
  CONS_13: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_13.avif',
  CONS_14: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_14.avif',
  CONS_15: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_15.avif',
  CONS_16: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_16.avif',
  CONS_25: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_25.avif',
  CONS_26: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/CONS_26.avif',
  'ERASE 2': 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/ERASE%202.avif',
  ERASE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/ERASE.avif',
  GIANTS_BLOOD: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/GIANTS_BLOOD.avif',
  GLOVES_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/GLOVES_4.avif',
  GLOVES_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/GLOVES_5.avif',
  HELM_4: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/HELM_4.avif',
  HELM_5: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/HELM_5.avif',
  LIVE_FORTRESS: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/LIVE_FORTRESS.avif',
  OFF_12: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OFF_12.avif',
  OFF_13: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OFF_13.avif',
  OFF_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OFF_7.avif',
  OFF_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OFF_8.avif',
  OFF_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OFF_9.avif',
  OHW_10: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OHW_10.avif',
  OHW_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/OHW_11.avif',
  REFLECTION: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/REFLECTION.avif',
  RING_7: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/RING_7.avif',
  RING_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/RING_8.avif',
  SCARLET_BRANCH_ADEPT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/SCARLET_BRANCH_ADEPT.avif',
  THW_11: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/THW_11.avif',
  THW_11_DRAFT: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/THW_11_DRAFT.avif',
  THW_8: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/THW_8.avif',
  THW_9: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/THW_9.avif',
  VETERAN_CONFIDENCE: 'https://res.cloudinary.com/sacra/image/upload/images/items/biome4/VETERAN_CONFIDENCE.avif',
  BAD_BEAR: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/BAD_BEAR.avif',
  BANDIT_CROWD: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/BANDIT_CROWD.avif',
  BANISHED_PEASANT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/BANISHED_PEASANT.avif',
  BLOODTHIRSTY_MADMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/BLOODTHIRSTY_MADMAN.avif',
  DRUNK_CROSSBOWMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/DRUNK_CROSSBOWMAN.avif',
  FEROCIOUS_WIFE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/FEROCIOUS_WIFE.avif',
  FEROCIOUS_WOLFS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/FEROCIOUS_WOLFS.avif',
  FOREST_CHORT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/FOREST_CHORT.avif',
  FRESH_INFECTED_DEADMAN:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/FRESH_INFECTED_DEADMAN.avif',
  HANGMANS_PATROL: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/HANGMANS_PATROL.avif',
  HUNGRY_DOG: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/HUNGRY_DOG.avif',
  INFECTED_DEADMAN_JUNIOR:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/INFECTED_DEADMAN_JUNIOR.avif',
  INFECTED_SPIRIT_OF_THE_FOREST:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/INFECTED_SPIRIT_OF_THE_FOREST.avif',
  LOCAL_DUDE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/LOCAL_DUDE.avif',
  MIDNIGHTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/MIDNIGHTER.avif',
  OLD_WHORE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/OLD_WHORE.avif',
  ORDINARY_GOOSE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/ORDINARY_GOOSE.avif',
  PEDOPHILE_HUNTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/PEDOPHILE_HUNTER.avif',
  REBELLIOUS_PEASANT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/REBELLIOUS_PEASANT.avif',
  ROWDY: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/ROWDY.avif',
  STARVING_WOLFS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/STARVING_WOLFS.avif',
  STRABAN_KNIGHT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/STRABAN_KNIGHT.avif',
  STYLE_POLICE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/STYLE_POLICE.avif',
  THE_MAN_EATING_BEAR: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/THE_MAN_EATING_BEAR.avif',
  TOUGH_DESERTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/TOUGH_DESERTER.avif',
  TRAINING_BUDDY: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/TRAINING_BUDDY.avif',
  VILE_SERPENT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome1/VILE_SERPENT.avif',
  BAD_SEED: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/BAD_SEED.avif',
  BLOODCURDLING_WOLFS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/BLOODCURDLING_WOLFS.avif',
  CHEERFUL_HANGMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/CHEERFUL_HANGMAN.avif',
  FILTHY_WILLY: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/FILTHY_WILLY.avif',
  FOREST_SHAMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/FOREST_SHAMAN.avif',
  FUGITIVE_KUDJA: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/FUGITIVE_KUDJA.avif',
  FUNH: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/FUNH.avif',
  HANGOVER_WILLY: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/HANGOVER_WILLY.avif',
  RIPE_MIDNIGHTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/RIPE_MIDNIGHTER.avif',
  RUINS_KEEPER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/RUINS_KEEPER.avif',
  SMELLY_CANNIBAL: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/SMELLY_CANNIBAL.avif',
  SOMBER_HANGMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/SOMBER_HANGMAN.avif',
  STRABAN_DOUCHEBAGS_AVENGERS:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/STRABAN_DOUCHEBAGS_AVENGERS.avif',
  THRALL_HUNTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/THRALL_HUNTER.avif',
  T_POSER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/T_POSER.avif',
  VENOMOUS_SPIDER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/VENOMOUS_SPIDER.avif',
  WILDED_ANCHORITE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/WILDED_ANCHORITE.avif',
  WOUNDED_DEMOLDER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome2/WOUNDED_DEMOLDER.avif',
  ANCIENT_SERPENT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/ANCIENT_SERPENT.avif',
  A_LITTLE_MORE_BANDITS_THAN_USUAL:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/A_LITTLE_MORE_BANDITS_THAN_USUAL.avif',
  BROTHER_DAMOUR: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/BROTHER_DAMOUR.avif',
  CHASER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/CHASER.avif',
  DEMOLDER_BOWMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_BOWMAN.avif',
  DEMOLDER_CHAMPION_GEKK:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_CHAMPION_GEKK.avif',
  DEMOLDER_CHAMPION_SCALD:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_CHAMPION_SCALD.avif',
  DEMOLDER_CHAMPION_THORN:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_CHAMPION_THORN.avif',
  DEMOLDER_HEADBRINGER:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_HEADBRINGER.avif',
  DEMOLDER_SERGEANT: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_SERGEANT.avif',
  DEMOLDER_WARRIOR: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/DEMOLDER_WARRIOR.avif',
  ELITE_PRINCE_FELLOWS:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/ELITE_PRINCE_FELLOWS.avif',
  FACELESS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/FACELESS.avif',
  FOREST_WITCH: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/FOREST_WITCH.avif',
  HUNGRY_BROOD: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/HUNGRY_BROOD.avif',
  JUICY_MIDNIGHTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/JUICY_MIDNIGHTER.avif',
  LONE_BARD: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/LONE_BARD.avif',
  MERGULLIAN_HEADHUNTER_DRAFT:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/MERGULLIAN_HEADHUNTER_DRAFT.avif',
  MIGHTY_RUIN_KEEPER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/MIGHTY_RUIN_KEEPER.avif',
  OGRE_BARON: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/OGRE_BARON.avif',
  RUNE_KING: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/RUNE_KING.avif',
  RUNE_PRIEST: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/RUNE_PRIEST.avif',
  SMALL_SPIDER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/SMALL_SPIDER.avif',
  UNFRIENDLY_HOUND: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/UNFRIENDLY_HOUND.avif',
  YOUNG_TROLL: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome3/YOUNG_TROLL.avif',
  BARD_CHICK: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/BARD_CHICK.avif',
  CULTIST_BOY_OF_MOTHER:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/CULTIST_BOY_OF_MOTHER.avif',
  CULTIST_BOY_OF_MOTHER_I:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/CULTIST_BOY_OF_MOTHER_I.avif',
  CULTIST_GIRL_OF_MOTHER:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/CULTIST_GIRL_OF_MOTHER.avif',
  CULTIST_GIRL_OF_MOTHER_II:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/CULTIST_GIRL_OF_MOTHER_II.avif',
  DARK_ADVENTURERS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/DARK_ADVENTURERS.avif',
  DARK_ESSENCE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/DARK_ESSENCE.avif',
  DEMOLDER_BERSERK: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/DEMOLDER_BERSERK.avif',
  FULLMOON_DARK_ESSENCE:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/FULLMOON_DARK_ESSENCE.avif',
  GELDED_ONE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/GELDED_ONE.avif',
  GIANT_WASP: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/GIANT_WASP.avif',
  GIRLS_AFTER_PARTY_DRAFT:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/GIRLS_AFTER_PARTY_DRAFT.avif',
  HAKKAMANDA: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/HAKKAMANDA.avif',
  INFECTED_DEADMAN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/INFECTED_DEADMAN.avif',
  KNIGHT_HAMMERGINA: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/KNIGHT_HAMMERGINA.avif',
  LEGION_COMMANDER_DOGWAN:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/LEGION_COMMANDER_DOGWAN.avif',
  LOST_BROTHER_DAMOUR: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/LOST_BROTHER_DAMOUR.avif',
  MELADZI: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/MELADZI.avif',
  MOONHUNTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/MOONHUNTER.avif',
  MOTHER_MIOLANIA: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/MOTHER_MIOLANIA.avif',
  POSTAL_HEADMISTRESS: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/POSTAL_HEADMISTRESS.avif',
  PUG_PANIN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/PUG_PANIN.avif',
  RUNEBEARER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/RUNEBEARER.avif',
  RUNE_KING_VISWANATHAN:
    'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/RUNE_KING_VISWANATHAN.avif',
  SPICY_MIDNIGHTER: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/SPICY_MIDNIGHTER.avif',
  SUPERGOOSE: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/SUPERGOOSE.avif',
  SWAMP_TROLL: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/SWAMP_TROLL.avif',
  WASP_QUEEN: 'https://res.cloudinary.com/sacra/image/upload/images/monsters/biome4/WASP_QUEEN.avif',
  BIOME_1_HEAL_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome1/BIOME_1_HEAL_SHRINE.avif',
  BIOME_2_FIGHT_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome2/BIOME_2_FIGHT_SHRINE.avif',
  BIOME_2_HEAL_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome2/BIOME_2_HEAL_SHRINE.avif',
  BIOME_2_LUCK_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome2/BIOME_2_LUCK_SHRINE.avif',
  BIOME_2_MAGIC_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome2/BIOME_2_MAGIC_SHRINE.avif',
  BIOME_2_RESIST_SHRINE:
    'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome2/BIOME_2_RESIST_SHRINE.avif',
  BIOME_3_LC_SHRINE: 'https://res.cloudinary.com/sacra/image/upload/images/shrines/biome3/BIOME_3_LC_SHRINE.avif',
  STORY101_RITE_OF_PASSAGE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY101_RITE_OF_PASSAGE.avif',
  STORY102_STRANGER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY102_STRANGER.avif',
  STORY103_I_VE_SEEN_YOU_SOMEWHERE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY103_I_VE_SEEN_YOU_SOMEWHERE.avif',
  STORY104_BURIED_ALIVE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY104_BURIED_ALIVE.avif',
  STORY105_POISONED: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY105_POISONED.avif',
  STORY106_YOU_SHALL_NOT_PASS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY106_YOU_SHALL_NOT_PASS.avif',
  STORY107_WEDDING_PLANNER:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY107_WEDDING_PLANNER.avif',
  STORY108_GOOD_BAD_EVIL:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY108_GOOD_BAD_EVIL.avif',
  STORY109_TUUNBAG: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY109_TUUNBAG.avif',
  STORY110_FIGHT_CLUB: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY110_FIGHT_CLUB.avif',
  STORY111_BARD_RETARD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY111_BARD_RETARD.avif',
  STORY112_DRUNK_DISPUTE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY112_DRUNK_DISPUTE.avif',
  STORY113_FIND_A_WAY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY113_FIND_A_WAY.avif',
  STORY114_TIME_TO_REST:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY114_TIME_TO_REST.avif',
  STORY115_BE_MY_GUEST: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY115_BE_MY_GUEST.avif',
  STORY116_FIND_FOOD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY116_FIND_FOOD.avif',
  STORY117_SELF_TREATMENT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY117_SELF_TREATMENT.avif',
  STORY118_WILD_ANIMAL: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY118_WILD_ANIMAL.avif',
  STORY119_FAIR_EXCHANGE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY119_FAIR_EXCHANGE.avif',
  STORY120_NICE_WATER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY120_NICE_WATER.avif',
  STORY121_IMYAREK: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY121_IMYAREK.avif',
  STORY122_LETTERS_FROM_THE_DEAD:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY122_LETTERS_FROM_THE_DEAD.avif',
  STORY123_MR_FISHKING: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY123_MR_FISHKING.avif',
  STORY124_OLD_RUINS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY124_OLD_RUINS.avif',
  STORY125_SLEEPING_BEAUTY:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY125_SLEEPING_BEAUTY.avif',
  STORY126_SHEPHERD_BOY:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY126_SHEPHERD_BOY.avif',
  STORY127_COW_TERROR: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY127_COW_TERROR.avif',
  STORY128_ROBBED_CARAVAN:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY128_ROBBED_CARAVAN.avif',
  STORY129_REST_IN_PEACE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY129_REST_IN_PEACE.avif',
  STORY130_CURSED_OLD_HOUSE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY130_CURSED_OLD_HOUSE.avif',
  STORY131_POSTMAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY131_POSTMAN.avif',
  STORY132_GUARDIAN_OF_THE_FOREST:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY132_GUARDIAN_OF_THE_FOREST.avif',
  STORY137_FAMILY_VALUES:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY137_FAMILY_VALUES.avif',
  STORY138_ON_A_BET: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY138_ON_A_BET.avif',
  STORY139_ASSWING_GALE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY139_ASSWING_GALE.avif',
  STORY140_GUILT: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY140_GUILT.avif',
  STORY141_GUILT_II: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY141_GUILT_II.avif',
  STORY142_WALKING_BRAD:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY142_WALKING_BRAD.avif',
  STORY143_YOU_RE_NOT_ALONE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY143_YOU_RE_NOT_ALONE.avif',
  STORY144_LIONHEART: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY144_LIONHEART.avif',
  STORY145_LIONHEART_II:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY145_LIONHEART_II.avif',
  STORY146_PAIN_AND_PLEASURE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY146_PAIN_AND_PLEASURE.avif',
  STORY147_DIRTY_MONEY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY147_DIRTY_MONEY.avif',
  STORY148_FATHERS_LESSON:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY148_FATHERS_LESSON.avif',
  STORY149_LUCKY_CHARM: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY149_LUCKY_CHARM.avif',
  STORY150_SLUTHOUSE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY150_SLUTHOUSE.avif',
  STORY151_HOMECOMING: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY151_HOMECOMING.avif',
  STORY152_RED_DWARF: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY152_RED_DWARF.avif',
  STORY153_ITCHING: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY153_ITCHING.avif',
  STORY154_DELIRIUM: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY154_DELIRIUM.avif',
  STORY155_CARAVAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY155_CARAVAN.avif',
  STORY156_NINTH_CIRCLE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY156_NINTH_CIRCLE.avif',
  STORY157_PINK_WINE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY157_PINK_WINE.avif',
  STORY161_ROWDY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY161_ROWDY.avif',
  STORY165_TRADITIONAL_DISH:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY165_TRADITIONAL_DISH.avif',
  STORY166_HEADACHE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY166_HEADACHE.avif',
  STORY169_RESTLESS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY169_RESTLESS.avif',
  STORY170_CATFIGHT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY170_CATFIGHT_I/STORY170_CATFIGHT.avif',
  STORY170_CATFIGHT_I_2:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY170_CATFIGHT_I/STORY170_CATFIGHT_I_2.avif',
  STORY171_CATFIGHT_II:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II.avif',
  'STORY171_CATFIGHT_II_2-3-4':
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_2-3-4.avif',
  'STORY171_CATFIGHT_II_28-30-32-33-34-36':
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_28-30-32-33-34-36.avif',
  'STORY171_CATFIGHT_II_5-21':
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_5-21.avif',
  'STORY171_CATFIGHT_II_6-12-15-16-17-18-19-20-22-23-24-25-26-27-29-31-35-37-38':
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_6-12-15-16-17-18-19-20-22-23-24-25-26-27-29-31-35-37-38.avif',
  'STORY171_CATFIGHT_II_7-8-10-11-13-14':
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_7-8-10-11-13-14.avif',
  STORY171_CATFIGHT_II_9:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_9.avif',
  STORY171_CATFIGHT_II_END:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome1/STORY171_CATFIGHT_II/STORY171_CATFIGHT_II_END.avif',
  STORY201_SECRETS_OF_THE_PAST:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY201_SECRETS_OF_THE_PAST.avif',
  STORY202_COLD_BODIES: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY202_COLD_BODIES.avif',
  STORY203_PRINCE_AND_PAUPER:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY203_PRINCE_AND_PAUPER.avif',
  STORY204_LOF_BLAID_MASSACRE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY204_LOF_BLAID_MASSACRE.avif',
  STORY205_FAREWELL_LOF_BLAID:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY205_FAREWELL_LOF_BLAID.avif',
  STORY206_EVERY_MAN_FOR_HIMSELF:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY206_EVERY_MAN_FOR_HIMSELF.avif',
  STORY207_OLD_FRIEND: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY207_OLD_FRIEND.avif',
  STORY208_VENDETTA: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY208_VENDETTA.avif',
  STORY209_FOXCATCHER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY209_FOXCATCHER.avif',
  STORY210_YOU_IN_THE_ARMY_NOW:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY210_YOU_IN_THE_ARMY_NOW.avif',
  STORY211_PRISON_BREAK:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY211_PRISON_BREAK.avif',
  STORY212_UNDER_SIEGE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY212_UNDER_SIEGE.avif',
  STORY213_THRALL_HUNTERS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY213_THRALL_HUNTERS.avif',
  STORY214_FOUL_PLAY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY214_FOUL_PLAY.avif',
  STORY215_FIND_A_WAY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY215_FIND_A_WAY.avif',
  STORY216_TIME_TO_REST:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY216_TIME_TO_REST.avif',
  STORY217_BE_MY_GUEST: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY217_BE_MY_GUEST.avif',
  STORY218_FIND_FOOD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY218_FIND_FOOD.avif',
  STORY219_SELF_TREATMENT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY219_SELF_TREATMENT.avif',
  STORY220_HELIX: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY220_HELIX.avif',
  STORY221_CAGE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY221_CAGE.avif',
  STORY222_IMYAREK: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY222_IMYAREK.avif',
  STORY223_LESSER_EVIL: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY223_LESSER_EVIL.avif',
  STORY224_OLD_RUINS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY224_OLD_RUINS.avif',
  STORY225_BOAR_HUNTER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY225_BOAR_HUNTER.avif',
  STORY226_DESPERATION: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY226_DESPERATION.avif',
  STORY227_ANCIENT_EVIL:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY227_ANCIENT_EVIL.avif',
  STORY228_WILD_RIVER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY228_WILD_RIVER.avif',
  STORY229_TWO_CAN_PLAY_THAT_GAME:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY229_TWO_CAN_PLAY_THAT_GAME.avif',
  STORY230_PLAGUE_TALE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY230_PLAGUE_TALE.avif',
  STORY231_POSTMAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY231_POSTMAN.avif',
  STORY232_IN_FROM_COLD:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY232_IN_FROM_COLD.avif',
  STORY234_MOURNING_MARCH:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY234_MOURNING_MARCH.avif',
  STORY235_DRUNK_DISPUTE_II:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY235_DRUNK_DISPUTE_II.avif',
  STORY236_FOG_GATE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY236_FOG_GATE.avif',
  STORY237_HEY_STRANGER:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY237_HEY_STRANGER.avif',
  STORY238_HELPING_HANDS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome2/STORY238_HELPING_HANDS.avif',
  STORY301_SACRA_HUNT: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY301_SACRA_HUNT.avif',
  STORY302_THE_GUTTERS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY302_THE_GUTTERS.avif',
  STORY303_WRONG_TURN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY303_WRONG_TURN.avif',
  STORY304_LIFE_ROAD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY304_LIFE_ROAD.avif',
  STORY305_DEATHTRAP: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY305_DEATHTRAP.avif',
  STORY306_FEED_THE_MOTHER:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY306_FEED_THE_MOTHER.avif',
  STORY307_FACELESS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY307_FACELESS.avif',
  STORY308_VANDALISM: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY308_VANDALISM.avif',
  STORY309_FOREST_WITCH:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY309_FOREST_WITCH.avif',
  STORY310_STUCK: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY310_STUCK.avif',
  STORY311_DINNER_PARTY:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY311_DINNER_PARTY.avif',
  STORY312_FILTHY_REVENGE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY312_FILTHY_REVENGE.avif',
  STORY313_FOR_YOUR_PRINCE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY313_FOR_YOUR_PRINCE.avif',
  STORY314_WATCHER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY314_WATCHER.avif',
  STORY315_PRETENDER: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY315_PRETENDER.avif',
  STORY316_OLD_RUINS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY316_OLD_RUINS.avif',
  STORY317_SELF_TREATMENT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY317_SELF_TREATMENT.avif',
  STORY318_FIND_FOOD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY318_FIND_FOOD.avif',
  STORY319_BE_MY_GUEST: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY319_BE_MY_GUEST.avif',
  STORY320_WHITE_BRANCH:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY320_WHITE_BRANCH.avif',
  STORY321_ALL_LIVES_MATTERS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY321_ALL_LIVES_MATTERS.avif',
  STORY322_SCUM_SOLO: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY322_SCUM_SOLO.avif',
  STORY323_DARK_ARTS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY323_DARK_ARTS.avif',
  STORY324_HOW_STEEL_FORGED:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY324_HOW_STEEL_FORGED.avif',
  STORY325_GREAT_EVIL_DEED:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY325_GREAT_EVIL_DEED.avif',
  STORY326_HUMAN_SACRIFICE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY326_HUMAN_SACRIFICE.avif',
  STORY327_FIELD_OF_MIRACLES:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY327_FIELD_OF_MIRACLES.avif',
  STORY328_DEMON_PARTY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY328_DEMON_PARTY.avif',
  STORY329_SHORT_MAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY329_SHORT_MAN.avif',
  STORY330_DARK_SOUL: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY330_DARK_SOUL.avif',
  STORY331_POSTMAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY331_POSTMAN.avif',
  STORY332_BROOD_NEED_FOOD:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY332_BROOD_NEED_FOOD.avif',
  STORY333_GREAT_EVIL_DEED_II:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome3/STORY333_GREAT_EVIL_DEED_II.avif',
  STORY401_WAY_OUT: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY401_WAY_OUT.avif',
  STORY402_YOU_AGAIN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY402_YOU_AGAIN.avif',
  STORY403_LAMENT_CONFIGURATION:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY403_LAMENT_CONFIGURATION.avif',
  STORY404_WRONG_HONEY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY404_WRONG_HONEY.avif',
  STORY405_WALL_OF_FIRE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY405_WALL_OF_FIRE.avif',
  STORY406_MORE_WASPS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY406_MORE_WASPS.avif',
  STORY407_KEY_TO_NOTHING:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY407_KEY_TO_NOTHING.avif',
  STORY408_THE_GUTTERS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY408_THE_GUTTERS.avif',
  STORY409_EMASCULATION:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY409_EMASCULATION.avif',
  STORY410_WHINERS_WAY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY410_WHINERS_WAY.avif',
  STORY411_A_MATTER_OF_FAITH:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY411_A_MATTER_OF_FAITH.avif',
  STORY412_ADVENTURERS_CLUB:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY412_ADVENTURERS_CLUB.avif',
  STORY413_FIND_A_WAY: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY413_FIND_A_WAY.avif',
  STORY414_TIME_TO_REST:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY414_TIME_TO_REST.avif',
  STORY415_FIND_FOOD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY415_FIND_FOOD.avif',
  STORY416_SELF_TREATMENT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY416_SELF_TREATMENT.avif',
  STORY417_KINDNESS: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY417_KINDNESS.avif',
  STORY418_GREAT_INVESTOR:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY418_GREAT_INVESTOR.avif',
  STORY419_SECTA: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY419_SECTA.avif',
  STORY420_CULLS_PARADE:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY420_CULLS_PARADE.avif',
  STORY421_ARIADNES_THREAD:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY421_ARIADNES_THREAD.avif',
  STORY422_COUPLE_OF_SIPS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY422_COUPLE_OF_SIPS.avif',
  STORY423_TURTLING: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY423_TURTLING.avif',
  STORY424_RUNEBREAKERS:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY424_RUNEBREAKERS.avif',
  STORY425_ENEMY_OF_MY_ENEMY:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY425_ENEMY_OF_MY_ENEMY.avif',
  STORY426_ASSEMBLAGE: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY426_ASSEMBLAGE.avif',
  STORY427_FREEDOM_FIGHT:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY427_FREEDOM_FIGHT.avif',
  STORY428_BLOOD_AND_HONOR:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY428_BLOOD_AND_HONOR.avif',
  STORY429_FALSE_GOD: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY429_FALSE_GOD.avif',
  STORY430_PILLARS_OF_POWER:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY430_PILLARS_OF_POWER.avif',
  STORY431_POSTMAN: 'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY431_POSTMAN.avif',
  STORY432_APEX_PREDATOR:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY432_APEX_PREDATOR.avif',
  STORY435_FREEDOM_FIGHT_II:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY435_FREEDOM_FIGHT_II.avif',
  STORY436_FREEDOM_FIGHT_III:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY436_FREEDOM_FIGHT_III.avif',
  STORY437_FREEDOM_FIGHT_IV:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY437_FREEDOM_FIGHT_IV.avif',
  STORY438_FREEDOM_FIGHT_V:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY438_FREEDOM_FIGHT_V.avif',
  STORY438_FREEDOM_FIGHT_V_1:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY438_FREEDOM_FIGHT_V_1.avif',
  STORY438_FREEDOM_FIGHT_V_2:
    'https://res.cloudinary.com/sacra/image/upload/images/stories/biome4/STORY438_FREEDOM_FIGHT_V_2.avif',
};
