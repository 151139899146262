import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ProviderService } from '@services/provider.service';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkGuard {
  account?: string;

  constructor(
    protected readonly router: Router,
    protected providerService: ProviderService,
  ) {}

  canMatch(): Observable<boolean | UrlTree> {
    return of(this.providerService.account).pipe(
      map(() => {
        // if (account && account !== '' && account !== '0' && account !== 'NO_ACCOUNT') {
        //   return true;
        // }
        //
        // this.router.navigate([MAIN_ROUTES.MAIN]);
        //
        // return false;
        // todo
        return true;
      }),
    );
  }
}
