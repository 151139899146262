import { ItemEntity, ItemMetaEntity } from '@generated/gql';

import { ItemSlotBgName } from '../types/item-slot-bg-name.type';
import { ItemSlotSize } from '../types/item-slot-size.type';

export enum ITEM_SLOT {
  UNKNOWN,
  HEAD,
  BODY,
  GLOVES,
  BELT,
  AMULET,
  BOOTS,
  RIGHT_RING,
  LEFT_RING,
  RIGHT_HAND,
  LEFT_HAND,
  TWO_HAND,
  SKILL_1,
  SKILL_2,
  SKILL_3,
  END_SLOT,
}

export const ITEM_SLOT_NAMES = new Map<ITEM_SLOT, string>([
  [ITEM_SLOT.UNKNOWN, 'unknown'],
  [ITEM_SLOT.HEAD, 'Head'],
  [ITEM_SLOT.BODY, 'Body'],
  [ITEM_SLOT.GLOVES, 'Gloves'],
  [ITEM_SLOT.BELT, 'Belt'],
  [ITEM_SLOT.AMULET, 'Amulet'],
  [ITEM_SLOT.RIGHT_RING, 'Right ring'],
  [ITEM_SLOT.LEFT_RING, 'Left ring'],
  [ITEM_SLOT.BOOTS, 'Boots'],
  [ITEM_SLOT.RIGHT_HAND, 'Right hand'],
  [ITEM_SLOT.LEFT_HAND, 'Left hand'],
  [ITEM_SLOT.TWO_HAND, 'Two hand'],
  [ITEM_SLOT.SKILL_1, 'Skill 1'],
  [ITEM_SLOT.SKILL_2, 'Skill 2'],
  [ITEM_SLOT.SKILL_3, 'Skill 3'],
]);

export enum ITEM_TYPE {
  NO_SLOT,
  HEAD,
  BODY,
  GLOVES,
  BELT,
  AMULET,
  RING,
  OFF_HAND,
  BOOTS,
  ONE_HAND,
  TWO_HAND,
  SKILL,
  OTHER,
}

export enum RECEIPT_TYPE {
  ALL,
  RE_ROLL,
  OTHER,
}

export const ITEM_TYPE_NAMES = new Map<ITEM_TYPE, string>([
  [ITEM_TYPE.NO_SLOT, 'item-type-names.no_slot'],
  [ITEM_TYPE.HEAD, 'item-type-names.head'],
  [ITEM_TYPE.BODY, 'item-type-names.body'],
  [ITEM_TYPE.GLOVES, 'item-type-names.gloves'],
  [ITEM_TYPE.BELT, 'item-type-names.belt'],
  [ITEM_TYPE.AMULET, 'item-type-names.amulet'],
  [ITEM_TYPE.RING, 'item-type-names.ring'],
  [ITEM_TYPE.OFF_HAND, 'item-type-names.off_hand'],
  [ITEM_TYPE.BOOTS, 'item-type-names.boots'],
  [ITEM_TYPE.ONE_HAND, 'item-type-names.one_hand'],
  [ITEM_TYPE.TWO_HAND, 'item-type-names.two_hand'],
  [ITEM_TYPE.SKILL, 'item-type-names.skill'],
  [ITEM_TYPE.OTHER, 'item-type-names.other'],
]);

export const RECEIPT_TYPE_NAMES = new Map<RECEIPT_TYPE, string>([
  [RECEIPT_TYPE.ALL, 'receipt-type-names.all'],
  [RECEIPT_TYPE.RE_ROLL, 'receipt-type-names.re_roll'],
  [RECEIPT_TYPE.OTHER, 'receipt-type-names.other'],
]);

export const ITEM_TYPE_ICONS = new Map<ITEM_TYPE, string>([
  [ITEM_TYPE.NO_SLOT, 'poition'],
  [ITEM_TYPE.HEAD, 'armor'],
  [ITEM_TYPE.BODY, 'armor'],
  [ITEM_TYPE.GLOVES, 'armor'],
  [ITEM_TYPE.BELT, 'armor'],
  [ITEM_TYPE.AMULET, 'jewerly'],
  [ITEM_TYPE.RING, 'ring'],
  [ITEM_TYPE.OFF_HAND, 'second-hand'],
  [ITEM_TYPE.BOOTS, 'armor'],
  [ITEM_TYPE.ONE_HAND, 'one-handed'],
  [ITEM_TYPE.TWO_HAND, 'two-handed'],
  [ITEM_TYPE.SKILL, 'skill'],
  [ITEM_TYPE.OTHER, 'other'],
]);

export const ITEM_TYPE_IS_COMMON = new Map<ITEM_TYPE, boolean>([
  [ITEM_TYPE.NO_SLOT, false],
  [ITEM_TYPE.HEAD, true],
  [ITEM_TYPE.BODY, true],
  [ITEM_TYPE.GLOVES, true],
  [ITEM_TYPE.BELT, true],
  [ITEM_TYPE.AMULET, true],
  [ITEM_TYPE.RING, true],
  [ITEM_TYPE.OFF_HAND, true],
  [ITEM_TYPE.BOOTS, true],
  [ITEM_TYPE.ONE_HAND, true],
  [ITEM_TYPE.TWO_HAND, true],
  [ITEM_TYPE.SKILL, false],
]);

export const ITEM_TYPE_TO_ITEM_SLOTS_MAPPING = new Map<ITEM_TYPE, ITEM_SLOT[]>([
  [ITEM_TYPE.NO_SLOT, [ITEM_SLOT.UNKNOWN]],
  [ITEM_TYPE.HEAD, [ITEM_SLOT.HEAD]],
  [ITEM_TYPE.BODY, [ITEM_SLOT.BODY]],
  [ITEM_TYPE.GLOVES, [ITEM_SLOT.GLOVES]],
  [ITEM_TYPE.BELT, [ITEM_SLOT.BELT]],
  [ITEM_TYPE.AMULET, [ITEM_SLOT.AMULET]],

  [ITEM_TYPE.RING, [ITEM_SLOT.LEFT_RING, ITEM_SLOT.RIGHT_RING]],
  [ITEM_TYPE.OFF_HAND, [ITEM_SLOT.LEFT_HAND]],
  [ITEM_TYPE.BOOTS, [ITEM_SLOT.BOOTS]],
  [ITEM_TYPE.ONE_HAND, [ITEM_SLOT.RIGHT_HAND]],
  [ITEM_TYPE.TWO_HAND, [ITEM_SLOT.TWO_HAND, ITEM_SLOT.RIGHT_HAND]],
  [ITEM_TYPE.SKILL, [ITEM_SLOT.SKILL_1, ITEM_SLOT.SKILL_2, ITEM_SLOT.SKILL_3]],
]);

export const ITEM_SLOTS_TO_ITEM_TYPE_MAPPING: Map<ITEM_SLOT, ITEM_TYPE> = [
  ...ITEM_TYPE_TO_ITEM_SLOTS_MAPPING.entries(),
].reduce((map: Map<ITEM_SLOT, ITEM_TYPE>, [itemType, itemSlots]: [ITEM_TYPE, ITEM_SLOT[]]) => {
  return itemSlots.reduce((map1: Map<ITEM_SLOT, ITEM_TYPE>, itemSlot: ITEM_SLOT) => {
    return map1.set(itemSlot, itemType);
  }, map);
}, new Map<ITEM_SLOT, ITEM_TYPE>());

export enum ITEM_RARITY {
  UNKNOWN,
  NORMAL,
  MAGIC,
  RARE,
  SET,
  UNIQUE,
}

export const ITEM_RARITY_TO_COLORS: Map<ITEM_RARITY, ItemSlotBgName> = new Map([
  [ITEM_RARITY.UNKNOWN, 'gray'],
  [ITEM_RARITY.NORMAL, 'gray'],
  [ITEM_RARITY.MAGIC, 'blue'],
  [ITEM_RARITY.RARE, 'gold'],
  [ITEM_RARITY.SET, 'green'],
  [ITEM_RARITY.UNIQUE, 'purple'],
]);

export const ITEM_RARITY_TO_TEXT_COLORS: Map<ITEM_RARITY, string> = new Map([
  [ITEM_RARITY.UNKNOWN, '#929292'],
  [ITEM_RARITY.NORMAL, '#929292'],
  [ITEM_RARITY.MAGIC, '#4d588d'],
  [ITEM_RARITY.RARE, '#aa703c'],
  [ITEM_RARITY.SET, '#4d8d53'],
  [ITEM_RARITY.UNIQUE, '#7a4d8d'],
]);

export const ITEM_RARITY_TO_TEXT_SHADOW_COLORS: Map<ITEM_RARITY, string> = new Map([
  [ITEM_RARITY.UNKNOWN, 'rgba(213, 255, 252, 0.4)'],
  [ITEM_RARITY.NORMAL, 'rgba(213, 255, 252, 0.4)'],
  [ITEM_RARITY.MAGIC, 'rgba(89, 118, 255, 0.45)'],
  [ITEM_RARITY.RARE, 'rgba(255, 131, 20, 0.65)'],
  [ITEM_RARITY.SET, 'rgba(14, 255, 76, 0.45)'],
  [ITEM_RARITY.UNIQUE, 'rgba(178, 14, 255, 0.6)'],
]);

export const ITEM_RARITY_TO_NAME: Map<ITEM_RARITY, string> = new Map([
  [ITEM_RARITY.NORMAL, 'item-rarity-name.normal'],
  [ITEM_RARITY.MAGIC, 'item-rarity-name.magic'],
  [ITEM_RARITY.RARE, 'item-rarity-name.rare'],
  [ITEM_RARITY.SET, 'item-rarity-name.set'],
  [ITEM_RARITY.UNIQUE, 'item-rarity-name.unique'],
]);

export function getBackgroundColorName(item: ItemEntity | null): ItemSlotBgName {
  if (!item) {
    return 'gray';
  }
  return item.meta.isBuffItem ? 'aquamarine' : (ITEM_RARITY_TO_COLORS.get(item.rarity) ?? 'gray');
}

export function getBackgroundColorNameForMeta(item: ItemMetaEntity | null): ItemSlotBgName {
  if (!item) {
    return 'gray';
  }
  return item.isBuffItem ? 'aquamarine' : 'gray';
}

export function getBackgroundColorNameFromMeta(meta: ItemMetaEntity | null): ItemSlotBgName {
  if (!meta) {
    return 'gray';
  }
  return meta.isBuffItem ? 'aquamarine' : 'gray';
}

export function getTextColorName(item: ItemEntity): string {
  if (!item) {
    return '#929292';
  }
  return item.meta.isBuffItem ? '#3caa8f' : (ITEM_RARITY_TO_TEXT_COLORS.get(item.rarity) ?? '#929292');
}

export function getTextColorNameForMeta(item: ItemMetaEntity): string {
  if (!item) {
    return '#929292';
  }
  return item.isBuffItem ? '#3caa8f' : '#929292';
}

export function getTextColorNameMeta(): string {
  return '#929292';
}

export function getTextShadowColorName(item: ItemEntity): string {
  if (!item) {
    return 'rgba(213, 255, 252, 0.4)';
  }
  return item.meta.isBuffItem
    ? '#3caa8f'
    : (ITEM_RARITY_TO_TEXT_SHADOW_COLORS.get(item.rarity) ?? 'rgba(213, 255, 252, 0.4)');
}

export const ITEM_SLOT_BACKGROUND: Map<ItemSlotSize, Map<ItemSlotBgName, string>> = new Map([
  [
    'small',
    new Map([
      ['active', 'app-item-slots-small_active'],
      ['active-plus', 'app-item-slots-small_active-plus'],
      ['aquamarine', 'app-item-slots-small_aquamarine'],
      ['blue', 'app-item-slots-small_blue'],
      ['empty', 'app-item-slots-small_empty'],
      ['gold', 'app-item-slots-small_gold'],
      ['gray', 'app-item-slots-small_gray'],
      ['green', 'app-item-slots-small_green'],
      ['purple', 'app-item-slots-small_purple'],
      ['unable', 'app-item-slots-small_unable'],
      ['unable-skill', 'app-item-slots-small_unable-skill'],
    ]),
  ],
  [
    'middle-small',
    new Map([
      ['active', 'app-item-slots-middle-small_active'],
      ['active-plus', 'app-item-slots-middle-small_active-plus'],
      ['aquamarine', 'app-item-slots-middle-small_aquamarine'],
      ['blue', 'app-item-slots-middle-small_blue'],
      ['empty', 'app-item-slots-middle-small_empty'],
      ['gold', 'app-item-slots-middle-small_gold'],
      ['gray', 'app-item-slots-middle-small_gray'],
      ['green', 'app-item-slots-middle-small_green'],
      ['purple', 'app-item-slots-middle-small_purple'],
      ['unable', 'app-item-slots-middle-small_unable'],
      ['unable-skill', 'app-item-slots-middle-small_unable-skill'],
    ]),
  ],
  [
    'middle',
    new Map([
      ['active', 'app-item-slots-middle_active'],
      ['active-plus', 'app-item-slots-middle_active-plus'],
      ['aquamarine', 'app-item-slots-middle_aquamarine'],
      ['blue', 'app-item-slots-middle_blue'],
      ['empty', 'app-item-slots-middle_empty'],
      ['gold', 'app-item-slots-middle_gold'],
      ['gray', 'app-item-slots-middle_gray'],
      ['green', 'app-item-slots-middle_green'],
      ['purple', 'app-item-slots-middle_purple'],
      ['unable', 'app-item-slots-middle_unable'],
      ['unable-skill', 'app-item-slots-middle_unable-skill'],
    ]),
  ],
  [
    'huge',
    new Map([
      ['active', 'app-item-slots-huge_active'],
      ['active-plus', 'app-item-slots-huge_active-plus'],
      ['aquamarine', 'app-item-slots-huge_aquamarine'],
      ['blue', 'app-item-slots-huge_blue'],
      ['empty', 'app-item-slots-huge_empty'],
      ['gold', 'app-item-slots-huge_gold'],
      ['gray', 'app-item-slots-huge_gray'],
      ['green', 'app-item-slots-huge_green'],
      ['purple', 'app-item-slots-huge_purple'],
      ['unable', 'app-item-slots-huge_unable'],
      ['unable-skill', 'app-item-slots-huge_unable-skill'],
    ]),
  ],
]);

export enum ITEM_CLASS {
  ALL = 0,
  SKILLS = 1,
  ATTACK = 2,
  CONSUMABLE = 3,
}
