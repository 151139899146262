import { Dialog, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeroEntity, ItemEntity } from '@generated/gql';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@services/destroy.service';
import { SubgraphService } from '@services/graph/subgraph.service';
import { ButtonClickDirective } from '@shared/button-click/button-click.directive';
import { DROPDOWN_SIZE } from '@shared/components/dropdown/constants/dropdown-sizes.constant';
import { ForgeDialogComponent } from '@shared/components/forge-dialog/forge-dialog.component';
import { ItemCardComponent } from '@shared/components/item-card/item-card.component';
import { ItemDescriptionDialogComponent } from '@shared/components/item-description-dialog/item-description-dialog.component';
import { ItemSlotComponent } from '@shared/components/item-slot/item-slot.component';
import { filterXShrinkAnimation } from '@shared/constants/animation/filter-x-shrink-animation';
import { openCloseByWidth } from '@shared/constants/animation/open-close-by-width';
import { CHECKBOX_STATE } from '@shared/constants/checkbox-states.constant';
import { getItemNameBySymbol } from '@shared/constants/game.constant';
import { ItemActionType } from '@shared/constants/inventory.constants';
import { HERO_ADR, HERO_ID, MAIN_ROUTES } from '@shared/constants/routes.constant';
import { filter, takeUntil } from 'rxjs';

import { VIEW_MODE } from '../inventory/inventory.component';

@Component({
  selector: 'app-inventory-all',
  standalone: true,
  templateUrl: './inventory-all.component.html',
  styleUrls: ['./inventory-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'g-flex-column',
  },
  animations: [filterXShrinkAnimation, openCloseByWidth],
  imports: [ItemSlotComponent, ItemCardComponent, ButtonClickDirective, TranslateModule],
})
export class InventoryAllComponent implements OnChanges, OnDestroy {
  @Input()
  set itemModels(items: ItemEntity[]) {
    this.items = items;
    this.applyAllFilters();
  }

  @Input()
  set inputHero(hero: HeroEntity) {
    this.hero = hero;
  }

  @Input()
  set inputChainId(id: number) {
    this.chainId = id;
  }

  hero?: HeroEntity;
  chainId: number;

  @Input()
  set inventoryItemTypeFilter(itemType: number) {
    this.currentFilterItemClass = itemType;
    this.applyAllFilters();
  }

  @Input() currentItemsSort: { query: string };
  @Input() currentFilterQuery: { query: string | null } = { query: null };
  @Input() viewMode: { query: VIEW_MODE } = { query: VIEW_MODE.ICON };
  @Input() onlySuitableItems: CHECKBOX_STATE = CHECKBOX_STATE.NONE;

  @Output() useEmit = new EventEmitter<ItemActionType>();
  @Output() updateInventory: EventEmitter<void> = new EventEmitter();

  @Output() openPage = new EventEmitter<any>();
  // .app-bg-all-hero-stats-back-new {
  account: string;
  loading = true;
  items: ItemEntity[] = [];
  itemsFiltered: ItemEntity[] = [];
  itemsFilteredAndSorted: ItemEntity[] = [];
  itemsFilteredAndSortedTrigger = -1;

  currentFilterItemClass = -1;
  VIEW_MODE = VIEW_MODE;

  private heroToken: string;
  private heroTokenId: number;
  private dialogRef: DialogRef<ItemActionType, ItemDescriptionDialogComponent> | undefined;

  constructor(
    private dialog: Dialog,
    private destroy$: DestroyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private subgraphService: SubgraphService,
  ) {
    this.heroToken = this.activatedRoute.snapshot.paramMap.get(HERO_ADR) ?? '';
    this.heroTokenId = parseInt(this.activatedRoute.snapshot.paramMap.get(HERO_ID) ?? '0');
  }

  ngOnChanges({ currentFilterQuery, currentItemsSort, onlySuitableItems }: SimpleChanges) {
    if (
      currentFilterQuery &&
      currentFilterQuery.currentValue !== currentFilterQuery.previousValue &&
      this.currentFilterQuery.query
    ) {
      this.applyAllFilters();
    }

    if (
      currentItemsSort &&
      currentItemsSort.currentValue !== currentItemsSort.previousValue &&
      this.currentItemsSort.query
    ) {
      this.applyItemSorting();
    }

    if (
      onlySuitableItems &&
      onlySuitableItems.currentValue !== onlySuitableItems.previousValue &&
      this.onlySuitableItems
    ) {
      this.applyItemSorting();
    }
  }

  ngOnDestroy(): void {
    this.dialogRef?.close();
  }

  openItemDescriptionDialog(item: ItemEntity): void {
    this.dialogRef = this.dialog.open(ItemDescriptionDialogComponent, {
      panelClass: 'app-overlay-pane-full-width',
      data: { item, hero: this.hero, isShowButtons: true, chainId: this.chainId },
    });

    this.dialogRef.closed.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data?.isNeedToUpdate) {
        this.updateInventory.emit();
      }

      if (data?.page) {
        this.router.navigate([
          MAIN_ROUTES.MAIN,
          data?.page,
          this.heroToken,
          this.heroTokenId,
          item.meta.id,
          item.itemId,
        ]);
      } else if (data?.useItem) {
        this.useEmit.emit(data);
      }
    });
  }

  onForge(event, item) {
    event.stopPropagation();
    event.preventDefault();

    const dialog = this.dialog.open(ForgeDialogComponent, {
      panelClass: 'app-overlay-pane',
      data: {
        heroToken: this.heroToken,
        heroId: this.heroTokenId,
        itemMetaId: item.meta.id,
        itemId: item.itemId,
      },
    });

    dialog.closed
      .pipe(
        filter(isNeedToUpdate => isNeedToUpdate === true),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.updateInventory.emit();
      });
  }

  onUse(item, event) {
    event.stopPropagation();
    event.preventDefault();

    this.useEmit.emit({ useItem: item });
  }

  private applyItemSorting(): void {
    const itemProp = this.currentItemsSort?.query;

    if (!!itemProp) {
      this.itemsFilteredAndSorted = [...this.itemsFiltered].sort((item1: ItemEntity, item2: ItemEntity) => {
        return (item2[itemProp] as number) - (item1[itemProp] as number);
      });
    } else {
      this.itemsFilteredAndSorted = [...this.itemsFiltered];
    }

    if (this.onlySuitableItems === CHECKBOX_STATE.CHECKED) {
      this.itemsFilteredAndSorted = this.itemsFilteredAndSorted.filter(item => {
        return (
          (item.meta.requirements.strength <= this.hero!.core.strength &&
            item.meta.requirements.dexterity <= this.hero!.core.dexterity &&
            item.meta.requirements.energy <= this.hero!.core.energy &&
            item.meta.requirements.vitality <= this.hero!.core.vitality) ||
          item.meta.isConsumableItem
        );
      });
    }

    this.itemsFilteredAndSortedTrigger += 1;

    this.changeDetectorRef.detectChanges();
  }

  private applyAllFilters(): void {
    const items1 = this.applyItemClassFilter(this.items, this.currentFilterItemClass);
    this.itemsFiltered = this.applyItemQueryFilter(items1, this.currentFilterQuery?.query || '');
    this.applyItemSorting();
  }

  private applyItemClassFilter(items: ItemEntity[], itemClass: number): ItemEntity[] {
    return items.filter(it => {
      return itemClass === -1 || it.meta.itemType === itemClass;
    });
  }

  private applyItemQueryFilter(items: ItemEntity[], query: string = ''): ItemEntity[] {
    if (!query) {
      return items;
    }

    const lowerCaseQuery = query.toLowerCase();

    return items.filter(item => getItemNameBySymbol(item.meta.symbol).toLowerCase().indexOf(lowerCaseQuery) >= 0);
  }

  protected readonly DROPDOWN_SIZE = DROPDOWN_SIZE;
}
